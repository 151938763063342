/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
1.	Fonts
2.	Reset
3.	Global Settings
4.	Scroll To Top style
5.	Main Header style / Header Style One
6.	Sticky Header
7.	Search Popup
8.	Mobile Menu
9.	Lazy Load Settings
10.	Banner Section
11.	Section Title
12.	Services Section
13.	About Section
14.	Live Section
15.	We DO Section
16.	Gallery Section
17.	Fun Facts Section
18.	We DO Section
19.	Team Section
20.	Parallax Section
21.	Sponsors Section
22.	Agency Section
23.	News Section
24.	Call Section
25.	Main Footer
26.	Services Section
27.	We DO Section
28.	Gallery Section
29.	Why Us Section
30.	Testimonials Section
31.	Parallax Section
32.	We DO Section
33.	Call Section
34.	Call Section
35.	Services Section
36.	Discover Section
37.	Parallax Section
38.	We Work Section
39.	Testimonials Section
40.	We DO Section
41.	Get Quote
42.	Map Section
43.	Sponsors Section
44.	Page Banner
45.	Faqs Section
46.	Get Quote
47.	Error Section
48.	Contact Section
49.	Sidebar Page Container
50.	Comment Area
51.	Leave Comments Form
52.	Service Details
53.	Service Details
54. FadeIn
--------------------------------------------------------------*/
/*
  font-family: 'Teko', sans-serif;
  font-family: 'Barlow', sans-serif;
  font-family: 'Rubik', sans-serif;
*/
/***

====================================================================
2.	Reset
====================================================================

 ***/

* {
  margin: 0px;
  padding: 0px;
  border: none;
  outline: none;
  font-size: 100%;
  line-height: inherit;
  box-sizing: border-box;
}

/***

====================================================================
3.	Global Settings
====================================================================

 ***/
:root {
  --thm-font: "Teko", sans-serif;
  --thm-b-font: "Rubik", sans-serif;
  --thm-base: #ffaa17;
  --thm-base-rgb: 255, 170, 23;
  --thm-black: #222429;
  --thm-text: #686a6f;
}

.container {
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  --bs-gutter-x: 30px;
  --bs-gutter-y: 0px;
}

body {
  font-size: 16px;
  font-family: var(--thm-b-font);
  color: var(--thm-text);
  line-height: 2.125em;
  font-weight: 400;
  background: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  top: 0 !important;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: var(--thm-base);
}

.theme_color {
  color: var(--thm-base);
}

a:hover,
a:focus,
a:visited,
a:active,
button:active {
  text-decoration: none;
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  position: relative;
  font-weight: 400;
  margin: 0px 0px 20px;
  background: none;
  line-height: 1.25em;
  font-family: var(--thm-font);
  color: var(--thm-black);
}

h1 {
  font-size: 140px;
}

h2 {
  font-size: 70px;
}

h3 {
  font-size: 60px;
}

h4 {
  font-size: 40px;
}

h5 {
  font-size: 30px;
}

h6 {
  font-size: 26px;
}

input,
select,
button {
  font-family: var(--thm-font);
}

textarea {
  overflow: hidden;
  resize: none;
}

button {
  outline: none !important;
  cursor: pointer;
}

p {
  line-height: 2.125em;
  margin: 0 0 20px;
}

.text {
  line-height: 2.125em;
  margin: 10px 0px;
}

::-webkit-input-placeholder {
  color: inherit;
}

::-moz-input-placeholder {
  color: inherit;
}

::-ms-input-placeholder {
  color: inherit;
}

.page-wrapper {
  position: relative;
  margin: 0 auto;
  width: 100%;
  min-width: 300px;
  z-index: 9;
  overflow: hidden;
}

.page-wrapper__dark {
  background-color: #1c1e22;
  background-image: url(../assets/images/background/home-portfolio-bg-1-1.png);
}

.page-wrapper__gradient {
  background: #432ff6;
  background: linear-gradient(100deg, #432ff6 0%, #f174e3 100%);
}

body.boxed-wrapper {
  background-color: #f4f5f8;
}

.page-wrapper.boxed-wrapper {
  max-width: 1410px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  -webkit-box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 100px 0px rgba(0, 0, 0, 0.08);
}

.page-wrapper__dark.boxed-wrapper {
  background-color: #1c1e22;
}

@media (max-width: 1440px) {
  .page-wrapper.boxed-wrapper {
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .page-wrapper.boxed-wrapper {
    max-width: 95%;
  }
}

.auto-container {
  position: static;
  max-width: 1200px;
  padding: 0px 15px;
  margin: 0 auto;
}

ul,
li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

figure {
  margin-bottom: 0;
}

.theme-btn {
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.theme-btn i {
  position: relative;
  display: inline-block;
  font-size: 14px;
  margin-left: 3px;
}

.centered {
  text-align: center !important;
}

/*Btn Style One*/
.btn-style-one {
  position: relative;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  background: var(--thm-base);
  color: var(--thm-black);
  border-radius: 5px;
  overflow: hidden;
  font-family: var(--thm-font);
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.btn-style-one .btn-title {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.1em;
  padding: 19px 50px 16px;
  z-index: 1;
}

.btn-style-one:hover {
  background: var(--thm-base);
  color: #ffffff;
}

.btn-style-one .btn-curve {
  position: absolute;
  right: -15px;
  top: 0;
  width: 26px;
  height: 100%;
  background: var(--thm-black);
  opacity: 0.2;
  z-index: 0;
  -webkit-transform: skewX(-22deg);
  transform: skewX(-22deg);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.btn-style-one:hover .btn-curve {
  opacity: 1;
  right: 0;
  width: 100%;
  -webkit-transform: skewX(0deg);
  transform: skewX(0deg);
}

/*Btn Style Two*/
.btn-style-two {
  position: relative;
  display: inline-block;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  background: var(--thm-black);
  color: #ffffff;
  border-radius: 5px;
  overflow: hidden;
  font-family: var(--thm-font);
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.btn-style-two .btn-title {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 30px;
  font-weight: 400;
  padding: 19px 50px 16px;
  z-index: 1;
  letter-spacing: 0.1em;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.btn-style-two:hover {
  color: var(--thm-black);
}

.btn-style-two .btn-curve {
  position: absolute;
  right: -15px;
  top: 0;
  width: 26px;
  height: 100%;
  background: #ffffff;
  opacity: 0.2;
  z-index: 0;
  -webkit-transform: skewX(-22deg);
  transform: skewX(-22deg);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.btn-style-two:hover .btn-curve {
  opacity: 1;
  right: 0;
  width: 100%;
  -webkit-transform: skewX(0deg);
  transform: skewX(0deg);
}

.btn-style-three {
  background-color: var(--thm-base);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  padding: 22.5px 40px;
  font-family: var(--thm-font);
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
}

.btn-style-three:hover {
  background-color: var(--thm-black);
  color: #fff;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.preloader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: white;
  background-color: #1c1e22;
}

.preloader__gradient {
  background: #432ff6;
  background: linear-gradient(100deg, #432ff6 0%, #f174e3 100%);
}

.preloader .icon {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0);
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  -webkit-animation-name: rotateMe;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotateMe;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: rotateMe;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation-name: rotateMe;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.page-loaded .preloader .icon {
  opacity: 0;
}

img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.dropdown-toggle::after {
  display: none;
}

.fa {
  line-height: inherit;
}

/***

====================================================================
4.	Scroll To Top style
====================================================================

***/
.scroll-to-top {
  position: fixed;
  right: 20px;
  bottom: 20px;
  width: 44px;
  height: 44px;
  color: var(--thm-black);
  font-size: 14px;
  line-height: 44px;
  border-radius: 50%;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  background: var(--thm-base);
  display: none;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.scroll-to-top:hover {
  background: var(--thm-black);
  color: #ffffff;
}

/***

====================================================================
5.	Main Header style / Header Style One
====================================================================

***/
.main-header {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  z-index: 9999;
  background: none;
}

/*=== Header Main Box ===*/
.main-header .header-upper {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  background: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  z-index: 1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.boxed-wrapper .main-header .header-upper {
  max-width: 1410px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

.fixed-header .header-upper {
  background: #ffaa17;
  -ms-box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.08);
}

.fixed-header .main-menu .navigation>li>a:before {
  border-bottom: 2px solid #181818;
  ;
}

.header-style-two.fixed-header .header-upper {
  background: var(--thm-black);
  -ms-box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.08);
  -o-box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.08);
}

.main-header .header-upper .inner-container {
  position: relative;
  display: block;
  padding: 0px 60px;
}

.main-header .header-upper .logo-box {
  position: relative;
  float: left;
  margin-right: 50px;
  z-index: 7;
}

.main-header .header-upper .logo-box .logo {
  position: relative;
  display: block;
}

.main-header .header-upper .logo-box .logo img {
  position: relative;
  display: inline-block;
  max-width: 100%;
  max-height: 140px;
  z-index: 1;
}

.main-header .other-links {
  position: relative;
  float: right;
  z-index: 7;
}

.main-header .other-links .link-box {
  position: relative;
  float: left;
  padding: 34px 0px;
}

.main-header .other-links .link-box .link {
  position: relative;
  display: block;
  padding-top: 4px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  padding-left: 60px;
  color: #ffffff;
}

.header-style-two.fixed-header .other-links .link-box .link {
  color: #ffffff;
}

.main-header .other-links .link-box .link .icon {
  position: absolute;
  left: 0;
  top: 0;
  width: 44px;
  height: 42px;
  background: url(../assets/images/icons/chat-icon.png) left center no-repeat;
}

.main-header .other-links .link-box .sub-text {
  position: relative;
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  font-family: var(--thm-font);
  letter-spacing: 0.03em;
}

.main-header .other-links .link-box .number {
  position: relative;
  display: block;
  letter-spacing: 0.03em;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
  font-family: var(--thm-font);
}

.main-header .other-links .link-box .number:hover {
  text-decoration: underline;
}

.main-header .other-links .call-us {
  position: relative;
}

.main-header .other-links .search-btn {
  position: relative;
  float: left;
  padding: 40px 0px;
  padding-right: 50px;
  margin-right: 50px;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.main-header .other-links .search-btn button {
  position: relative;
  display: block;
  padding: 0;
  margin: 0;
  background: none;
  line-height: 32px;
  color: #ffffff;
  cursor: pointer;
}

.main-header .other-links .search-btn button span {
  vertical-align: middle;
}

.main-header .nav-outer {
  position: absolute;
  left: 0;
  top: 0;
  float: left;
  text-align: center;
  width: 100%;
  z-index: 5;
}

.main-header .nav-outer .main-menu {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

@media (min-width: 992px) {
  .boxed-wrapper .main-header .nav-outer .main-menu {
    margin-right: 70px;
  }
}

.main-menu .navbar-collapse {
  padding: 0px;
  display: block !important;
}

.main-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  margin: 0px;
  font-family: var(--thm-font);
}

.main-menu .navigation>li {
  position: relative;
  float: left;
  padding: 20px 0px;
  margin-right: 40px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation>li:last-child {
  margin-right: 0px;
}

.main-menu .navigation>li:last-child:after {
  display: none;
}

.main-menu .navigation>li>a {
  position: relative;
  display: block;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: #ffffff;
  padding: 12px 0px;
  opacity: 1;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-menu .navigation li>a {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.main-menu .navigation li>a>span {
  background-color: var(--thm-base);
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 40px;
  top: -3px;
  position: relative;
}

.main-menu .navigation>li:hover>a,
.main-menu .navigation>li.current>a {
  color: #ffffff;
}

.header-style-two .main-menu .navigation>li>a,
.header-style-two .main-menu .navigation>li:hover>a {
  color: var(--thm-black);
}

.header-style-two .main-menu .navigation>li.current>a,
.header-style-two.fixed-header .main-menu .navigation>li>a,
.header-style-two.fixed-header .main-menu .navigation>li>a:hover {
  color: #fff;
}

.header-style-two.fixed-header .main-menu .navigation>li.current>a {
  color: var(--thm-base);
}

.main-menu .navigation>li>a:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 7px;
  width: 100%;
  border-bottom: 2px solid var(--thm-base);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.header-style-two .main-menu .navigation>li>a:before {
  border-bottom-color: #fff;
}

.header-style-two.fixed-header .main-menu .navigation>li>a:before {
  border-bottom-color: var(--thm-base);
}

.main-menu .navigation>li:hover>a:before,
.main-menu .navigation>li.current>a:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.sticky-header .main-menu .navigation>li.dropdown {
  padding-right: 0px;
}

.main-menu .navigation>li>ul {
  position: absolute;
  left: 0px;
  top: 100%;
  width: 220px;
  z-index: 100;
  display: none;
  background: #ffffff;
  padding: 20px 0px 15px;
  border-radius: 5px;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation>li>ul.from-right {
  left: auto;
  right: 0px;
}

.main-menu .navigation>li>ul>li {
  position: relative;
  padding: 0px 25px;
  width: 100%;
  margin-bottom: 5px;
}

.main-menu .navigation>li>ul>li>a {
  position: relative;
  display: block;
  padding: 5px 0px;
  line-height: 24px;
  font-weight: 400;
  font-size: 20px;
  color: #171b27;
  letter-spacing: 0.03em;
  text-align: left;
  text-transform: uppercase;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.main-menu .navigation>li>ul>li:hover>a {
  color: var(--thm-base);
}

.main-menu .navigation>li>ul>li>ul {
  position: absolute;
  left: 100%;
  top: 0;
  width: 220px;
  z-index: 100;
  display: none;
  background: #ffffff;
  padding: 20px 0px 15px;
  border-radius: 5px;
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -ms-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -o-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.05),
    -2px 0px 5px 1px rgba(0, 0, 0, 0.05);
}

.main-menu .navigation>li>ul>li>ul>li {
  position: relative;
  padding: 0px 25px;
  width: 100%;
  margin-bottom: 5px;
}

.main-menu .navigation>li>ul>li>ul>li>a {
  position: relative;
  display: block;
  padding: 5px 0px;
  line-height: 24px;
  font-weight: 400;
  font-size: 20px;
  color: #171b27;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  text-align: left;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.main-menu .navigation>li>ul>li>ul>li>a:hover {
  color: var(--thm-base);
}

.main-menu .navigation>li>ul>li.dropdown>a:after {
  font-family: "Font Awesome 5 Free";
  content: "\f105";
  position: absolute;
  right: 0px;
  top: 7px;
  display: block;
  line-height: 24px;
  font-size: 16px;
  font-weight: 900;
  z-index: 5;
  display: none;
}

.main-menu .navigation>li.dropdown:hover>ul {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.main-menu .navigation li>ul>li.dropdown:hover>ul {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.main-menu .navigation li.dropdown .dropdown-btn {
  position: absolute;
  right: 10px;
  top: 8px;
  width: 34px;
  height: 30px;
  border: 1px solid #ffffff;
  text-align: center;
  font-size: 16px;
  line-height: 26px;
  color: #ffffff;
  cursor: pointer;
  z-index: 5;
  display: none;
}

/* header style two */
.header-style-two.fixed-header .header-upper .logo-box .logo .stricked-logo,
.header-style-two .header-upper .logo-box .logo .main-logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.header-style-two.fixed-header .header-upper .logo-box .logo .main-logo,
.header-style-two .header-upper .logo-box .logo .stricked-logo {
  display: none;
}

/* header style four */
.header-style-four .header-upper {
  background-color: #fff;
}

.header-style-four .other-links .search-btn {
  border-color: #e9ebee;
}

.header-style-four .main-header .header-upper {
  border-bottom: 0;
}

.header-style-four .main-menu .navigation>li>a {
  color: #727479;
}

.header-style-four .other-links .link-box .number,
.header-style-four .main-menu .navigation>li:hover>a,
.header-style-four .main-menu .navigation>li.current>a {
  color: var(--thm-black);
}

.header-style-four .other-links .search-btn button {
  color: var(--thm-black);
}

.header-style-four .other-links .link-box .sub-text {
  color: #727479;
}

/* header style five */
.header-style-five .header-upper {
  background-color: rgba(0, 0, 0, 0);
}

.fixed-header.header-style-five .header-upper {
  background-color: #fff;
}

.header-style-five .header-upper .inner-container {
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}

.header-style-five .nav-outer {
  text-align: right;
}

/* header style six */
.header-style-six .header-upper {
  background-color: rgba(0, 0, 0, 0);
}

.fixed-header.header-style-six .header-upper {
  background-color: var(--thm-black);
}

.header-style-six .nav-outer {
  display: none;
}

.header-style-six .header-upper .inner-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-style-six .header-upper .right-menu {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-style-six .header-upper .right-menu .search-btn button {
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
  width: auto;
  font-size: 22px;
  color: #ffffff;
}

.header-style-six .header-upper .right-menu .mobile-nav-toggler {
  cursor: pointer;
  margin-left: 30px;
}

.header-style-six .header-upper .right-menu .mobile-nav-toggler:hover span {
  width: 28px !important;
}

.header-style-six .header-upper .right-menu .mobile-nav-toggler .bar {
  width: 28px;
  height: 1px;
  background-color: #fff;
  display: block;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.header-style-six .header-upper .right-menu .mobile-nav-toggler .bar:nth-child(2) {
  width: 23px;
}

.header-style-six .header-upper .right-menu .mobile-nav-toggler .bar:nth-child(3) {
  width: 18px;
}

.header-style-six .header-upper .right-menu .mobile-nav-toggler .bar+.bar {
  margin-top: 7px;
}

.header-style-six-no-border .header-upper {
  border-bottom: 0;
}

/* header style seven */
.header-style-seven {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100%;
}

.header-style-seven .header-upper {
  margin-top: 44px;
  border-color: rgba(255, 255, 255, 0.06);
}

.header-style-seven .header-upper .auto-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-style-seven.fixed-header .header-upper {
  margin-top: 0;
}

.header-style-seven .nav-outer {
  position: relative;
  top: auto;
  left: auto;
  text-align: right;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.header-style-seven .main-menu .navigation>li>a {
  font-size: 32px;
  text-transform: capitalize;
  font-weight: 400;
  color: #fff;
  letter-spacing: 0;
}

.header-style-seven .main-menu .navigation>li>ul>li>a {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0;
}

/* topbar */
.header-style-seven .topbar-four {
  background-color: #222429;
  padding-top: 4.5px;
  padding-bottom: 4.5px;
}

.header-style-seven .topbar-four .auto-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.header-style-seven .topbar-four .right-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-style-seven .topbar-four p {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #959ca6;
}

.header-style-seven .topbar-four .phone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 40px;
}

.header-style-seven .topbar-four .phone span {
  font-size: 14px;
  color: var(--thm-base);
  margin-right: 10px;
}

.header-style-seven .topbar-four .phone a {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}

.header-style-seven .social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-style-seven .social-links li+li {
  margin-left: 30px;
}

.header-style-seven .social-links a {
  font-size: 14px;
  color: #959ca6;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.header-style-seven .social-links a:hover {
  color: var(--thm-base);
}

.header-style-seven .main-menu .navigation li>a>span {
  top: 0;
}

/* cart btn */
.main-header .other-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-header .cart-btn {
  font-size: 18px;
  color: #fff;
  margin-right: 30px;
}

.main-header .cart-btn a {
  color: inherit;
}

.header-style-eight .header-upper {
  border-bottom: 1px solid #e9ebee;
}

.header-style-eight .main-menu .navigation>li>a {
  font-size: 20px;
  font-weight: 500;
  color: var(--thm-text);
  text-transform: lowercase;
}

.header-style-eight .nav-outer {
  position: relative;
  left: auto;
  top: auto;
  float: none;
  text-align: right;
}

.header-style-eight .main-menu .navigation>li>a::before {
  bottom: -32px;
}

.header-style-eight .main-menu .navigation>li {
  padding-top: 33px;
  padding-bottom: 33px;
}

.header-style-eight .main-menu .navigation>li>ul {
  border-radius: 0;
}

.header-style-eight .main-menu .navigation>li>ul>li>a {
  text-transform: capitalize;
  font-size: 16px;
  font-weight: 500;
  color: var(--thm-text);
}

.header-style-eight .main-menu .navigation>li>ul>li>a:hover {
  color: var(--thm-black);
}

.header-style-eight .main-menu .navigation li>a>span {
  font-size: 12px;
  padding-left: 8px;
  padding-right: 8px;
}

.header-style-eight .auto-container {
  width: 100%;
}

.header-style-eight .header-upper .logo-box {
  padding: 20px 0;
}

@media (min-width: 992px) {
  .header-style-eight .header-upper .logo-box {
    padding: 40px 0;
  }
}

.header-style-eight .nav-outer .mobile-nav-toggler {
  margin-top: 18px;
}

@media (min-width: 992px) {
  .header-style-eight .nav-outer .mobile-nav-toggler {
    margin-top: 40px;
  }
}

.fixed-header.header-style-eight .header-upper {
  background-color: #fff;
  border-bottom: 0;
}

.header-style-nine {
  z-index: 991;
  position: relative;
  top: auto;
  left: auto;
  display: block;
  width: 100%;
}

.header-style-nine__inner {
  padding-top: 32px;
  padding-bottom: 32px;
  padding-left: 30px;
  padding-right: 30px;
  background-color: var(--thm-gray);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 992px) {
  .header-style-nine {
    width: 100px;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
  }

  .header-style-nine__inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    height: 100%;
    overflow-y: auto;
    padding-left: 0;
    padding-right: 0;
    padding-top: 40px;
    padding-bottom: 60px;
  }
}

.header-style-nine .mobile-nav-toggler {
  width: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  display: block !important;
}

.header-style-nine .nav-outer .main-menu {
  display: none;
}

.header-style-nine .mobile-nav-toggler span {
  background-color: var(--thm-black);
  height: 1px;
  width: 28px;
  display: block;
}

.header-style-nine .mobile-nav-toggler span:nth-child(2) {
  width: 23px;
  margin-top: 7px;
  margin-bottom: 7px;
}

.header-style-nine .mobile-nav-toggler span:nth-child(3) {
  width: 18px;
}

.header-style-nine .nav-outer {
  position: relative;
  left: auto;
  top: auto;
  float: none;
  text-align: left;
  width: auto;
  z-index: 5;
}

.header-style-nine .nav-outer .mobile-nav-toggler {
  position: relative;
  float: none;
  font-size: 30px;
  line-height: 40px;
  cursor: pointer;
  margin-left: 0px;
  color: var(--thm-base);
  margin: 0px 0px 0px;
  margin-left: 30px;
}

@media (min-width: 992px) {
  .header-style-nine .nav-outer {
    margin-top: auto;
  }

  .header-style-nine .nav-outer .mobile-nav-toggler {
    margin-left: 0;
  }
}

.header-style-nine__search-btn__outer,
.header-style-nine .search-btn {
  cursor: pointer;
  margin-left: auto;
  margin-right: 0px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-style-nine__search-btn__outer {
  display: none;
}

@media (min-width: 576px) {
  .header-style-nine .search-btn {
    margin-right: 30px;
  }
}

@media (min-width: 992px) {
  .header-style-nine .search-btn {
    display: none;
  }

  .header-style-nine__search-btn__outer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
  }
}

.header-style-nine .social-icons {
  display: none;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 576px) {
  .header-style-nine .social-icons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 992px) {
  .header-style-nine .social-icons {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: auto;
  }
}

.header-style-nine .social-icons a {
  font-size: 16px;
  color: var(--thm-text);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.header-style-nine .social-icons a:hover {
  color: var(--thm-base);
}

.header-style-nine .social-icons a+a {
  margin-left: 20px;
}

@media (min-width: 992px) {
  .header-style-nine .social-icons a+a {
    margin-left: 0;
    margin-top: 15px;
  }
}

.header-style-nine__search-btn__outer button,
.header-style-nine .search-btn button {
  background-color: transparent;
}

.header-style-nine__search-btn__outer button span,
.header-style-nine .search-btn button span {
  font-size: 22px;
  color: var(--thm-black);
  position: relative;
  display: block;
  margin-right: 20px;
}

.header-style-nine__search-btn__outer .text,
.header-style-nine .search-btn .text {
  font-size: 16px;
  color: var(--thm-text);
}

.header-style-nine__search-btn__outer {
  padding: 33px 70px;
  z-index: 991;
  background-color: var(--thm-gray);
}

/***

====================================================================
6.				Sticky Header
====================================================================

***/
.sticky-header {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  left: 0px;
  top: 0px;
  width: 100%;
  padding: 0px 0px;
  z-index: -1;
  background: #ffffff;
  border-bottom: 1px solid #f0f0f0;
  -ms-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  -webkit-transition: top 300ms ease;
  transition: top 300ms ease;
}

.fixed-header .sticky-header {
  opacity: 1;
  z-index: 99901;
  visibility: visible;
}

.fixed-header .sticky-header .logo {
  padding: 10px 0px;
}

.sticky-header .main-menu .navigation>li {
  margin-left: 40px !important;
  padding: 0;
  margin-right: 0;
  background-color: transparent;
}

.sticky-header .main-menu .navigation>li.dropdown>a:after,
.sticky-header .main-menu .navigation>li:before {
  display: none;
}

.sticky-header .main-menu .navigation>li>a {
  padding: 21px 0px !important;
  line-height: 30px;
  font-size: 16px;
  font-weight: 600;
  color: #25283a;
  text-transform: capitalize;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.sticky-header .main-menu .navigation>li>ul>li>ul {
  left: auto;
  right: 100%;
}

.sticky-header .main-menu .navigation>li:hover>a,
.sticky-header .main-menu .navigation>li.current>a,
.sticky-header .main-menu .navigation>li.current-menu-item>a {
  opacity: 1;
}

/***

====================================================================
7.	Search Popup
====================================================================

***/
.search-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transition: -webkit-transform 0.7s ease;
  transition: -webkit-transform 0.7s ease;
  transition: transform 0.7s ease;
  transition: transform 0.7s ease, -webkit-transform 0.7s ease;
}

.search-popup.active {
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.search-popup__overlay {
  width: 100%;
  height: 100%;
  background-color: #000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  opacity: 0.7;
  cursor: none;
}

.search-popup__inner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-popup__form {
  position: relative;
  z-index: 9991;
  width: 100%;
  padding: 15px;
  max-width: 600px;
  position: relative;
}

.search-popup__form input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
  height: 60px;
  line-height: 30px;
  padding: 15px 30px;
  color: #272727;
  font-size: 14px;
  background-color: #ffffff;
  font-family: var(--thm-b-font);
  padding-left: 30px;
}

.search-popup__form button[type="submit"] {
  border: none;
  outline: none;
  width: 60px;
  height: 60px;
  line-height: 60px;
  color: #fff;
  background-color: var(--thm-base);
  cursor: pointer;
  position: absolute;
  top: 50%;
  right: 15px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.search-popup__form button[type="submit"]:hover {
  background-color: var(--thm-black);
  color: #ffffff;
}

.cursor {
  position: absolute;
  background-color: #fff;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  z-index: 1;
  -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  transition: 0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.3s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: 999999;
  -webkit-transform: scale(1);
  transform: scale(1);
  visibility: hidden;
}

.cursor {
  visibility: visible;
}

.cursor.active {
  opacity: 0.5;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.cursor.hovered {
  opacity: 0.08;
}

.cursor-follower {
  position: absolute;
  background-color: RGBA(255, 255, 255, 0.3);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  z-index: 1;
  -webkit-transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity;
  transition: 0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) transform,
    0.2s cubic-bezier(0.75, -0.27, 0.3, 1.33) opacity,
    0.6s cubic-bezier(0.75, -1.27, 0.3, 2.33) -webkit-transform;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  z-index: 999999;
  visibility: hidden;
}

.cursor-follower {
  visibility: visible;
}

.cursor-follower.active {
  opacity: 0.7;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.cursor-follower.hovered {
  opacity: 0.08;
}

.cursor-follower.close-cursor:before {
  position: absolute;
  content: "";
  height: 25px;
  width: 2px;
  background: #fff;
  left: 48%;
  top: 12px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  display: inline-block;
}

.cursor-follower.close-cursor:after {
  position: absolute;
  content: "";
  height: 25px;
  width: 2px;
  background: #fff;
  right: 48%;
  top: 12px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

/***

====================================================================
8.			Mobile Menu
====================================================================

***/
.fixed-header .nav-outer .mobile-nav-toggler {
  color: #ffffff;
}

/* .fixed-header .logo-img {
  content: url('src/assets/images/logo-dark.png');
} */

.nav-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 30px;
  line-height: 40px;
  cursor: pointer;
  margin-left: 0px;
  color: var(--thm-base);
  margin: 40px 0px 0px;
  display: none;
}

.mobile-nav-toggler .txt {
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 500;
  display: none;
}

/***

====================================================================
9.		Lazy Load Settings
====================================================================

***/
img.lazy-image {
  background: url(../assets/images/icons/image-bg.svg) center center no-repeat;
}

img.lazy-image.loaded {
  background-image: none;
}

.owl-theme img.lazy-image.loaded {
  height: auto;
  opacity: 1;
}

/* Default Form Style */
.default-form {
  position: relative;
}

.default-form .row {
  margin: 0 -15px;
}

.default-form .form-group {
  position: relative;
  margin-bottom: 30px;
}

.default-form .field-inner {
  position: relative;
  display: block;
}

.default-form .field-inner .alt-icon {
  position: absolute;
  left: 20px;
  top: 50%;
  margin-top: -15px;
  line-height: 30px;
  font-size: 20px;
  color: #9b9fa6;
  z-index: 1;
  pointer-events: none;
}

.default-form .form-group .field-label {
  position: relative;
  display: block;
  color: #0f172b;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 10px;
}

.default-form .form-group .e-label {
  position: relative;
}

.default-form .form-group input[type="text"],
.default-form .form-group input[type="email"],
.default-form .form-group input[type="password"],
.default-form .form-group input[type="tel"],
.default-form .form-group input[type="url"],
.default-form .form-group input[type="file"],
.default-form .form-group input[type="number"],
.default-form .form-group textarea,
.default-form .form-group select {
  position: relative;
  display: block;
  height: 70px;
  width: 100%;
  font-size: 14px;
  color: #686a6f;
  line-height: 40px;
  font-weight: 400;
  padding: 14px 30px;
  background-color: #f4f5f8;
  border: 1px solid #f4f5f8;
  border-radius: 7px;
  font-family: var(--thm-b-font);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.default-form .form-group ::-webkit-input-placeholder {
  opacity: 1;
  color: inherit;
}

.default-form .form-group :-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}

.default-form .form-group ::-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}

.default-form .form-group ::placeholder {
  opacity: 1;
  color: inherit;
}

.default-form .form-group textarea {
  height: 240px;
  resize: none;
}

.contact-form .form-group input[type="submit"],
.contact-form .form-group button {
  display: inline-block;
}

.default-form .form-group input[type="text"]:focus,
.default-form .form-group input[type="email"]:focus,
.default-form .form-group input[type="password"]:focus,
.default-form .form-group input[type="tel"]:focus,
.default-form .form-group input[type="url"]:focus,
.default-form .form-group input[type="file"]:focus,
.default-form .form-group input[type="number"]:focus,
.default-form .form-group textarea:focus,
.default-form .form-group select:focus,
.form-group .ui-selectmenu-button.ui-button:focus,
.form-group .ui-selectmenu-button.ui-button:active {
  border-color: var(--thm-base);
}

.default-form ::-webkit-input-placeholder {
  color: #aaaaaa;
}

.default-form ::-moz-input-placeholder {
  color: #aaaaaa;
}

.default-form ::-ms-input-placeholder {
  color: #aaaaaa;
}

.default-form label.error {
  color: #ff0000;
  font-size: 14px;
  text-transform: capitalize;
  text-align: left;
  display: block;
  letter-spacing: 1px;
  padding-top: 7px;
  line-height: 24px;
}

/*Custom Select*/
.form-group .ui-selectmenu-button.ui-button {
  width: 100%;
  font-size: 14px;
  font-style: normal;
  height: 70px;
  padding: 14px 30px;
  line-height: 40px;
  font-family: var(--thm-b-font);
  color: var(--thm-text);
  border-radius: 7px;
  background-color: #f4f5f8;
  border: 1px solid #f4f5f8;
}

.form-group .ui-button .ui-icon {
  background: none;
  position: relative;
  top: 10px;
  text-indent: 0px;
  color: var(--thm-black);
}

.form-group .ui-button .ui-icon:before {
  font-family: "Font Awesome 5 Free";
  content: "\f107";
  position: absolute;
  right: 0px;
  top: 0px !important;
  width: 15px;
  height: 30px;
  display: block;
  color: var(--thm-black);
  line-height: 20px;
  font-size: 16px;
  font-weight: 900;
  text-align: center;
  z-index: 5;
}

.ui-widget.ui-widget-content {
  background: #f4f5f8;
  border: 1px solid #e9ebee;
  border-top: none;
  font-family: var(--thm-font);
  border-radius: 0;
  padding: 0;
}

.ui-menu .ui-menu-item {
  font-size: 20px;
  border-bottom: 1px solid #e9ebee;
}

.ui-menu .ui-menu-item:last-child {
  border: none;
}

.ui-menu .ui-menu-item-wrapper {
  position: relative;
  display: block;
  padding: 14px 30px !important;
  font-size: 20px;
  letter-spacing: 0.02em;
  line-height: 30px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.ui-menu .ui-menu-item-wrapper:hover,
.ui-menu .ui-menu-item-wrapper.ui-state-active {
  background: var(--thm-base);
  margin: 0;
}

/***

====================================================================
10.		Banner Section
====================================================================

***/
.banner-section {
  position: relative;
  z-index: 1;
}

.banner-section .bg-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-position: center top;
  background-size: cover;
  z-index: 1;
}

.banner-section .left-based-text {
  position: absolute;
  left: 50px;
  top: 50px;
  bottom: 50px;
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: 0.1em;
  font-weight: 500;
  z-index: 10;
  font-family: var(--thm-font);
}

.banner-section .left-based-text .base-inner {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 730px;
  height: 24px;
  -webkit-transform-origin: left bottom;
  transform-origin: left bottom;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.banner-section .left-based-text .social-links {
  position: absolute;
  left: 0;
  top: 0;
}

.banner-section .left-based-text .social-links ul li {
  float: left;
  margin-right: 35px;
}

.banner-section .left-based-text .social-links ul li a {
  position: relative;
  display: block;
  color: #ffffff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.banner-section .left-based-text .social-links ul li a:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  border-bottom: 2px solid var(--thm-base);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.banner-section .left-based-text .social-links ul li a:hover:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.banner-section .left-based-text .hours {
  position: absolute;
  right: 0;
  top: 0;
}

.banner-section .left-based-text .hours ul li {
  float: left;
  margin-left: 35px;
}

.banner-three .left-based-text .social-links ul li a,
.banner-three .left-based-text .hours ul li {
  color: var(--thm-black);
}

.banner-section .left-based-text .social-links ul li a:before {
  border-bottom-color: var(--thm-black);
}

.banner-carousel {
  position: relative;
  z-index: 1;
}

.banner-carousel .slide-item {
  position: relative;
  display: block;
  background-color: var(--thm-black);
  color: #ffffff;
  overflow: hidden;
}

.banner-three .banner-carousel .slide-item {
  background: none;
}

.banner-carousel .slide-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: var(--thm-black);
  opacity: 0.6;
  z-index: 1;
}

.banner-two .banner-carousel .slide-item::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url(../assets/images/main-slider/slide-2-pattern-1.png);
  z-index: 10;
}

.banner-two .banner-carousel .slide-item:before {
  opacity: 0.8;
}

.banner-three .banner-carousel .slide-item:before {
  display: none;
}

.banner-carousel .slide-item .left-top-line {
  position: absolute;
  left: 0;
  top: 0;
  width: 350px;
  max-width: 100%;
  height: 100%;
  background: url(../assets/images/main-slider/left-curve-line.png) left top no-repeat;
  z-index: 2;
  opacity: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.banner-carousel .tns-slide-active .left-top-line {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 200ms;
  transition-delay: 200ms;
}

.banner-carousel .slide-item .right-bottom-curve {
  position: absolute;
  right: 0;
  top: 0;
  width: 655px;
  max-width: 100%;
  height: 100%;
  background: url(../assets/images/main-slider/right-curved-bg.png) right bottom no-repeat;
  z-index: 2;
  opacity: 0;
  -webkit-transform: translateX(700px);
  transform: translateX(700px);
}

.banner-carousel .tns-slide-active .right-bottom-curve {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 1300ms;
  transition-delay: 1300ms;
}

.banner-carousel .slide-item .right-top-curve {
  position: absolute;
  right: 0;
  top: 0;
  width: 164px;
  max-width: 100%;
  height: 100%;
  background: url(../assets/images/main-slider/right-white-curve.png) right top no-repeat;
  z-index: 2;
  opacity: 0;
  -webkit-transform: translateX(200px);
  transform: translateX(200px);
}

.banner-carousel .tns-slide-active .right-top-curve {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 1800ms;
  transition-delay: 1800ms;
}

.banner-carousel .slide-item .round-shape-1 {
  position: absolute;
  left: -500px;
  bottom: -500px;
  width: 1000px;
  height: 1000px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  z-index: 2;
  opacity: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.banner-carousel .tns-slide-active .round-shape-1 {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 1500ms;
  transition-delay: 1500ms;
}

.banner-carousel .slide-item .shape-1 {
  position: absolute;
  left: 0;
  top: 0;
  width: 570px;
  max-width: 100%;
  height: 810px;
  background: url(../assets/images/main-slider/b-2-shape-1.png) left top no-repeat;
  z-index: 2;
  opacity: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
}

.banner-carousel .tns-slide-active .shape-1 {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 200ms;
  transition-delay: 200ms;
}

.banner-carousel .slide-item .shape-2 {
  position: absolute;
  left: 0;
  bottom: 55px;
  width: 333px;
  max-width: 100%;
  height: 366px;
  background: url(../assets/images/main-slider/b-2-shape-2.png) left bottom no-repeat;
  z-index: 2;
  opacity: 0;
  -webkit-transform: translateX(-500px);
  transform: translateX(-500px);
}

.banner-carousel .tns-slide-active .shape-2 {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 500ms;
  transition-delay: 500ms;
}

.banner-carousel .slide-item .shape-3 {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 824px;
  max-width: 100%;
  height: 424px;
  background: url(../assets/images/main-slider/b-2-shape-3.png) right bottom no-repeat;
  z-index: 2;
  opacity: 0;
  -webkit-transform: translateX(900px);
  transform: translateX(900px);
}

.banner-carousel .tns-slide-active .shape-3 {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 1800ms;
  transition-delay: 1800ms;
}

.banner-carousel .slide-item .shape-4 {
  position: absolute;
  right: 0;
  top: 0;
  width: 218px;
  max-width: 100%;
  height: 571px;
  background: url(../assets/images/main-slider/b-2-shape-4.png) right top no-repeat;
  z-index: 2;
  opacity: 0;
  -webkit-transform: translateX(200px);
  transform: translateX(200px);
}

.banner-carousel .tns-slide-active .shape-4 {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 2500ms;
  transition-delay: 2500ms;
}

.banner-carousel .slide-item .shape-5 {
  position: absolute;
  right: 0;
  top: 0;
  width: 306px;
  max-width: 100%;
  height: 904px;
  background: url(../assets/images/main-slider/b-2-shape-5.png) right top no-repeat;
  z-index: 2;
  opacity: 0;
  -webkit-transform: translateY(-1000px);
  transform: translateY(-1000px);
}

.banner-carousel .tns-slide-active .shape-5 {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 1500ms ease;
  transition: all 1500ms ease;
  -webkit-transition-delay: 2700ms;
  transition-delay: 2700ms;
}

.banner-carousel .slide-item .shape-6 {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  background: url(../assets/images/main-slider/b-2-shape-6.png) center center no-repeat;
  z-index: 3;
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}

.banner-carousel .tns-slide-active .shape-6 {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 1500ms ease;
  transition: all 1500ms ease;
  -webkit-transition-delay: 2700ms;
  transition-delay: 2700ms;
}

.banner-carousel .slide-item .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.banner-carousel .tns-slide-active .slide-item .image-layer {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
  -webkit-transition: all 7000ms linear;
  transition: all 7000ms linear;
}

.banner-carousel .content-box {
  position: relative;
  display: table;
  vertical-align: middle;
  width: 100%;
  height: 950px;
  padding: 0 0px;
  z-index: 11;
}

.banner-carousel .content-box .content {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  padding: 150px 0px 50px;
}

.banner-carousel .content-box .inner {
  position: relative;
  display: block;
  max-width: 800px;
}

.banner-two .banner-carousel .content-box .inner {
  max-width: 820px;
  margin: 0 auto;
  text-align: center;
}

.banner-three .banner-carousel .content-box .inner {
  max-width: 550px;
}

.banner-carousel .content-box .sub-title {
  display: block;
  font-size: 24px;
  line-height: 1.5em;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 0.05em;
  margin-bottom: 20px;
  opacity: 0;
  -webkit-transform: translateY(80px);
  transform: translateY(80px);
  font-family: var(--thm-font);
}

.banner-carousel .tns-slide-active .content-box .sub-title {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 700ms ease;
  transition: all 700ms ease;
  -webkit-transition-delay: 800ms;
  transition-delay: 800ms;
}

.banner-carousel .content-box h1 {
  display: block;
  font-size: 140px;
  line-height: 0.85em;
  color: #ffffff;
  font-weight: 600;
  opacity: 0;
  text-transform: uppercase;
  letter-spacing: 0;
  margin: 0;
  -webkit-transform: translateY(80px);
  transform: translateY(80px);
}

.banner-two .banner-carousel .content-box h1 {
  text-transform: capitalize;
}

.banner-three .banner-carousel .content-box h1 {
  font-size: 120px;
  color: var(--thm-black);
  font-weight: 500;
}

.banner-carousel .tns-slide-active .content-box h1 {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 1300ms;
  transition-delay: 1300ms;
}

.banner-three .banner-carousel .tns-slide-active .content-box h1 {
  -webkit-transition-delay: 700ms;
  transition-delay: 700ms;
}

.banner-carousel .content-box .text {
  position: relative;
  color: var(--thm-black);
  margin: 5px 0 0;
  opacity: 0;
  -webkit-transform: translateY(80px);
  transform: translateY(80px);
}

.banner-carousel .tns-slide-active .content-box .text {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 700ms ease;
  transition: all 700ms ease;
  -webkit-transition-delay: 1200ms;
  transition-delay: 1200ms;
}

.banner-carousel .content-box .link-box {
  position: relative;
  display: block;
  padding-top: 50px;
  opacity: 0;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
}

.banner-three .banner-carousel .content-box .link-box {
  padding-top: 45px;
}

.banner-carousel .tns-slide-active .content-box .link-box {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 700ms ease;
  transition: all 700ms ease;
  -webkit-transition-delay: 1800ms;
  transition-delay: 1800ms;
}

.banner-carousel .slide-item .round-image {
  position: absolute;
  right: -200px;
  top: -100px;
  width: 850px;
  height: 850px;
  border-radius: 50%;
  z-index: 2;
}

.banner-carousel .slide-item .round-image .image {
  position: absolute;
  left: 0;
  top: 0;
  width: 850px;
  height: 850px;
  background-size: cover;
  background-position: left top;
  border-radius: 50%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  z-index: 2;
  opacity: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  overflow: hidden;
}

.banner-carousel .slide-item .round-image .image::before {
  content: "";
  position: absolute;
  opacity: 0.5;
  background-color: var(--thm-black);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
}

.banner-carousel .tns-slide-active .round-image .image {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 200ms;
  transition-delay: 200ms;
}

.banner-carousel .slide-item .round-image:before {
  content: "";
  position: absolute;
  left: -100px;
  bottom: 120px;
  width: 100%;
  height: 100%;
  background: var(--thm-black);
  border-radius: 50%;
  z-index: 1;
  opacity: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.banner-carousel .tns-slide-active .round-image:before {
  opacity: 0.1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 700ms;
  transition-delay: 700ms;
}

.banner-carousel .slide-item .round-image:after {
  content: "";
  position: absolute;
  left: -100px;
  bottom: -50px;
  width: 330px;
  height: 330px;
  background: url(../assets/images/main-slider/b-3-shape.png) center center no-repeat;
  z-index: 3;
  opacity: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.banner-carousel .tns-slide-active .round-image:after {
  opacity: 1;
  -webkit-transform: translate(0);
  transform: translate(0);
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
}

.banner-carousel .tns-controls {
  position: absolute;
  left: 50%;
  margin-left: -600px;
  top: 50%;
  width: 1200px;
  height: 110px;
  text-align: right;
}

.banner-carousel .tns-controls .tns-next,
.banner-carousel .tns-controls .tns-prev {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background: #ffffff !important;
  padding: 0;
  margin: 0;
  line-height: 50px;
  text-align: center;
  opacity: 0.2;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.banner-carousel .tns-controls .tns-next {
  top: auto;
  bottom: 0;
}

.banner-carousel .tns-controls .tns-next span,
.banner-carousel .tns-controls .tns-prev span {
  vertical-align: middle;
}

.banner-carousel .tns-controls .tns-next:hover,
.banner-carousel .tns-controls .tns-prev:hover {
  opacity: 1;
}

.banner-section .tns-nav {
  position: absolute;
  left: 0;
  bottom: 50px;
  width: 100%;
  text-align: center;
  display: none;
}

.banner-two .tns-controls,
.banner-three .tns-controls {
  display: none;
}

.banner-three {
  background-color: var(--thm-base);
}

.banner-three .bg-image {
  opacity: 0.03;
}

.banner-two .tns-nav {
  display: block;
}

.banner-section .tns-nav button {
  display: inline-block;
  margin: 0px 3px;
  position: relative;
  width: 10px;
  height: 10px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  color: inherit;
  padding: 0 !important;
  font: inherit;
  background-color: transparent;
}

.banner-section .tns-nav button.tns-nav-active {
  border-color: var(--thm-base);
}

/* banner one page  */
.banner-one-page {
  margin-top: 0px;
}

.banner-one-page .banner-carousel .content-box .inner {
  max-width: 100%;
}

.banner-one-page .banner-carousel .content-box {
  height: 600px;
}

@media (min-width: 992px) {
  .banner-one-page .banner-carousel .content-box {
    height: 860px;
  }
}

.banner-one-page .banner-carousel .content-box h1 {
  font-size: 46px;
  color: var(--thm-base);
}

.banner-one-page .banner-carousel .content-box h1 span {
  color: #fff;
  font-weight: 300;
}

@media (min-width: 600px) {
  .banner-one-page .banner-carousel .content-box h1 {
    font-size: 60px;
  }
}

@media (min-width: 768px) {
  .banner-one-page .banner-carousel .content-box h1 {
    font-size: 80px;
  }
}

@media (min-width: 992px) {
  .banner-one-page .banner-carousel .content-box h1 {
    font-size: 100px;
  }
}

@media (min-width: 1200px) {
  .banner-one-page .banner-carousel .content-box h1 {
    font-size: 120px;
  }
}

.banner-one-page .banner-carousel .content-box .link-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.banner-one-page .banner-carousel .content-box .content {
  padding-top: 0;
  padding-bottom: 0;
}

.banner-one-page .banner-carousel .vid-link {
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  z-index: 3;
  margin-left: 15px;
}

@media (min-width: 376px) {
  .banner-one-page .banner-carousel .vid-link {
    margin-left: 30px;
  }
}

.banner-one-page .banner-carousel .vid-link .icon {
  position: relative;
  display: block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 16px;
  color: var(--thm-black);
  background: #fff;
  border-radius: 50%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.banner-one-page .banner-carousel .vid-link a:hover .icon {
  background: var(--thm-black);
  color: var(--thm-base);
}

.banner-one-page .banner-carousel .vid-link .ripple,
.banner-one-page .banner-carousel .vid-link .ripple:before,
.banner-one-page .banner-carousel .vid-link .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(var(--thm-base-rgb), 0.6);
  -o-box-shadow: 0 0 0 0 rgba(var(--thm-base-rgb), 0.6);
  -webkit-box-shadow: 0 0 0 0 rgba(var(--thm-base-rgb), 0.6);
  box-shadow: 0 0 0 0 rgba(var(--thm-base-rgb), 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}

.banner-one-page .banner-carousel .vid-link .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.banner-one-page .banner-carousel .vid-link .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

.banner-one-page .banner-carousel .content-box .link-box {
  padding-top: 30px;
}

.banner-one-page .banner-carousel .owl-nav {
  width: 100%;
  left: 0;
  margin-left: 0;
  display: none;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 376px) {
  .banner-one-page .banner-carousel .owl-nav {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (min-width: 768px) {
  .banner-one-page .banner-carousel .owl-nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }

  .banner-one-page .banner-carousel .owl-nav {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 992px) {
  .banner-one-page .banner-carousel .owl-nav {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.banner-one-page .banner-carousel .owl-nav .owl-next,
.banner-one-page .banner-carousel .owl-nav .owl-prev {
  position: relative;
  top: auto;
  right: auto;
}

/* banner four */
.banner-section-four {
  position: relative;
  margin-top: 44px;
}

.banner-section-four .swiper-slide::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-position: top center;
  z-index: 10;
}

.banner-section-four .swiper-slide {
  position: relative;
}

.banner-section-four .swiper-slide .image-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-transform: scale(1);
  transform: scale(1);
  background-position: center;
  -webkit-transition: -webkit-transform 7000ms ease;
  transition: -webkit-transform 7000ms ease;
  transition: transform 7000ms ease;
  transition: transform 7000ms ease, -webkit-transform 7000ms ease;
}

.banner-section-four .swiper-slide-active .image-layer {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}

.banner-section-four .auto-container {
  padding-top: 350px;
  padding-bottom: 200px;
  position: relative;
  z-index: 22;
}

.banner-section-four h2 {
  margin: 0;
  font-weight: bold;
  color: #fff;
  font-size: 80px;
  line-height: 90px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(110px);
  transform: translateY(110px);
  -webkit-transition-delay: 1000ms;
  transition-delay: 1000ms;
  -webkit-transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease,
    -webkit-transform 2000ms ease;
}

.banner-section-four p {
  margin: 0;
  font-size: 20px;
  line-height: 36px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
  opacity: 0.7;
  margin-top: 40px;
  margin-bottom: 50px;
  opacity: 0;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition-delay: 2500ms;
  transition-delay: 2500ms;
  -webkit-transition: opacity 2000ms ease, color 500ms ease,
    background 500ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, color 500ms ease, background 500ms ease,
    -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease,
    background 500ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease,
    background 500ms ease, -webkit-transform 2000ms ease;
}

.banner-section-four .btn-style-three {
  min-width: 220px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  opacity: 0;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition-delay: 2500ms;
  transition-delay: 2500ms;
  -webkit-transition: opacity 2000ms ease, color 500ms ease,
    background 500ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, color 500ms ease, background 500ms ease,
    -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease,
    background 500ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, color 500ms ease,
    background 500ms ease, -webkit-transform 2000ms ease;
}

.banner-section-four .btn-style-three:hover {
  background-color: #fff;
  color: var(--thm-black);
}

.banner-section-four .swiper-slide-active .btn-style-three,
.banner-section-four .swiper-slide-active h2,
.banner-section-four .swiper-slide-active p {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translateY(0) translateX(0);
  transform: translateY(0) translateX(0);
}

.banner-section-four__nav {
  width: 100%;
  max-width: 1200px;
  padding-left: 15px;
  padding-right: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 21;
}

@media (max-width: 425px) {
  .banner-section-four__nav {
    display: none;
  }
}

.banner-section-four__nav .swiper-button-next,
.banner-section-four__nav .swiper-button-prev {
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  z-index: 100;
  width: 50px;
  height: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  color: #222429;
  background-color: #fff;
  border-radius: 50%;
  opacity: 0.3;
  margin: 0;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media (max-width: 991px) {

  .banner-section-four__nav .swiper-button-next,
  .banner-section-four__nav .swiper-button-prev {
    width: 48px;
    height: 48px;
    font-size: 18px;
  }
}

.banner-section-four__nav .swiper-button-next:hover,
.banner-section-four__nav .swiper-button-prev:hover {
  opacity: 1;
}

.banner-section-four__nav .swiper-button-next::after,
.banner-section-four__nav .swiper-button-prev::after {
  display: none;
}

.banner-section-four__nav .swiper-button-prev {
  margin-bottom: 10px;
}

@media (max-width: 991px) {
  .banner-section-four__nav .swiper-button-prev {
    margin-top: 10px;
  }
}

.banner-four {
  margin-top: 76px;
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
}

@media (min-width: 992px) {
  .banner-four {
    margin-top: 120px;
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media (min-width: 1200px) {
  .banner-four {
    padding-top: 185px;
    padding-bottom: 200px;
  }
}

.banner-four__title {
  margin: 0;
  color: var(--thm-black);
  font-size: 40px;
  font-weight: 500;
}

@media (min-width: 768px) {
  .banner-four__title {
    font-size: 60px;
  }
}

@media (min-width: 1200px) {
  .banner-four__title {
    font-size: 80px;
  }
}

.banner-four__title br {
  display: none;
}

@media (min-width: 768px) {
  .banner-four__title br {
    display: inherit;
  }
}

.banner-four__text {
  margin: 0;
  color: var(--thm-text);
  font-weight: 400;
  font-size: 20px;
  line-height: 1.6em;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .banner-four__text {
    margin-top: 15px;
    font-size: 28px;
  }
}

.banner-four__text a {
  color: var(--thm-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.banner-four__text a:hover {
  color: var(--thm-black);
}

.banner-four__scroll {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--thm-base);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
  color: #fff;
  margin-top: 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media (min-width: 768px) {
  .banner-four__scroll {
    width: 70px;
    height: 70px;
    font-size: 28px;
  }
}

@media (min-width: 1200px) {
  .banner-four__scroll {
    font-size: 34px;
    width: 90px;
    height: 90px;
    margin-top: 35px;
  }
}

.banner-four__scroll:hover {
  background-color: var(--thm-black);
  color: #fff;
}

.banner-four__social {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: none;
}

.banner-four__social a {
  font-size: 20px;
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.banner-four__social a+a {
  margin-top: 30px;
}

.banner-four__social a:hover {
  color: var(--thm-base);
}

@media (min-width: 1200px) {
  .banner-four__social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    right: 90px;
  }
}

.banner-four__info {
  position: absolute;
  display: none;
  left: 30px;
  top: 67%;
  -webkit-transform: translateY(-50%) rotate(-90deg);
  transform: translateY(-50%) rotate(-90deg);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.banner-four__info span {
  font-size: 20px;
  color: var(--thm-text);
  font-weight: 400;
}

.banner-four__info span+span {
  margin-left: 30px;
}

@media (min-width: 1200px) {
  .banner-four__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    left: 90px;
  }
}

/***

====================================================================
11.		Section Title
====================================================================

***/
.sec-title {
  position: relative;
  margin-bottom: 0px;
}

.sec-title.centered {
  max-width: 650px;
  margin: 0 auto;
  margin-bottom: 0px;
}

.sec-title .upper-text {
  position: relative;
  display: inline-block;
  line-height: 1.6em;
  font-size: 16px;
  color: #9b9fa6;
  font-weight: 400;
  margin: 0 0 20px;
}

.sec-title h2 {
  position: relative;
  display: block;
  font-size: 50px;
  line-height: 0.9em;
  color: var(--thm-black);
  font-weight: 400;
}

.sec-title h2 strong {
  font-weight: 700;
}

.sec-title h2 .dot {
  color: var(--thm-base);
}

.sec-title .lower-text {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 2.125em;
  font-weight: 400;
  color: var(--thm-text);
  padding: 0px 0px 0px;
}

/* section title two */
.sec-title-two {
  margin-bottom: 45px;
}

.sec-title-two p {
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 0.1em;
  font-size: 14px;
  font-weight: 600;
  color: var(--thm-base);
  line-height: 1;
}

.sec-title-two h2 {
  margin: 0;
  font-size: 60px;
  color: var(--thm-black);
  font-weight: 800;
}

/***

====================================================================
12.	Services Section
====================================================================

***/
.services-section {
  position: relative;
  padding: 120px 0px 90px;
  background: var(--thm-black) url(../assets/images/background/pattern-1.png) center top repeat;
  color: #ffffff;
}

.services-section .sec-title {
  margin-bottom: 30px;
}

.services-section .sec-title h2 {
  color: #ffffff;
}

.services-section .sec-title .lower-text {
  color: #999b9f;
}

.services-section .row {
  counter-reset: slides-num;
}

.services-section .row .service-block {
  counter-increment: slides-num;
}

.services-section .row .service-block .inner-box:before {
  content: "0" counter(slides-num) "";
  position: absolute;
  left: 45px;
  top: 50px;
  font-size: 50px;
  line-height: 1em;
  color: #ffffff;
  opacity: 0.07;
  font-family: var(--thm-font);
  font-weight: 300;
}

.service-block {
  position: relative;
  margin-bottom: 30px;
}

.service-block .inner-box {
  position: relative;
  display: block;
  padding: 50px 45px;
  background: #1c1e22;
  min-height: 100%;
  border-radius: 7px;
  overflow: hidden;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-block .inner-box:hover {
  background: var(--thm-base);
}

.service-block .inner-box .bottom-curve {
  position: absolute;
  right: -70px;
  bottom: 0;
  width: 100px;
  height: 80%;
  background: var(--thm-black);
  opacity: 0.2;
  z-index: 0;
  -webkit-transform: skewX(0deg) scaleX(0);
  transform: skewX(0deg) scaleX(0);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.service-block .inner-box:hover .bottom-curve {
  -webkit-transform: skewX(-35deg) scaleX(1);
  transform: skewX(-35deg) scaleX(1);
}

.service-block .inner-box .count {
  position: relative;
  display: block;
  font-size: 50px;
  line-height: 1em;
  color: #ffffff;
  opacity: 0.07;
  margin-bottom: 40px;
}

.service-block .inner-box .icon-box {
  position: absolute;
  right: 45px;
  top: 50px;
  color: var(--thm-base);
  font-size: 64px;
  line-height: 1em;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.service-block .inner-box:hover .icon-box {
  color: var(--thm-black);
}

.service-block .inner-box h6 {
  position: relative;
  margin: 0;
  margin-top: 90px;
  font-size: 20px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.1em;
}

.service-block .inner-box h6 a {
  color: #ffffff;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.services-section .row .service-block .inner-box:hover:before,
.service-block .inner-box:hover .count,
.service-block .inner-box:hover h6,
.service-block .inner-box:hover h6 a {
  color: var(--thm-black);
}

.service-block .inner-box h6 a:hover {
  text-decoration: underline;
}

/* service three block */
.service-block-three {
  padding-top: 90px;
}

.service-block-three__single {
  margin-top: 30px;
  background-color: #eff1f4;
  padding: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-block-three__single:hover {
  background-color: var(--thm-base);
}

.service-block-three__single .icon-box span {
  font-size: 64px;
  color: var(--thm-base);
  margin-right: 40px;
  display: block;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-block-three__single:hover .icon-box span {
  color: #fff;
}

.service-block-three__single h6 {
  margin: 0;
  color: var(--thm-black);
  font-size: 20px;
  line-height: 34px;
  font-weight: bold;
  text-transform: capitalize;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-block-three__single:hover h6 {
  color: #fff;
}

.service-block-three__single h6 a {
  color: inherit;
}

/***

====================================================================
13.	About Section
====================================================================

***/
.about-section {
  position: relative;
  padding: 26px 0px 0px;
  background: #ffffff;
}

.about-section:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  margin-left: -410px;
  width: 50%;
  height: 100%;
  background: #f4f5f8;
}

.about-section .image-column {
  position: relative;
  margin-bottom: 0px;
}

.about-section .image-column .inner {
  position: relative;
  display: block;
}

.about-section .image-column .inner:before {
  content: "";
  position: absolute;
  left: 0;
  width: 15px;
  height: 280px;
  background: var(--thm-base);
  border-radius: 8px;
}

.about-section .image-column .image-block {
  position: relative;
  display: block;
  border-radius: 7px;
  overflow: hidden;
}

.about-section .image-column .image-block img {
  display: block;
  width: 100%;
  border-radius: 7px;
}

.about-section .image-column .image-block:nth-child(1) {
  margin-left: 110px;
}

.about-section .image-column .image-block:nth-child(2) {
  margin-right: 140px;
  margin-top: -170px;
}

.about-section .text-column {
  position: relative;
  margin-bottom: 30px;
}

.about-section .text-column .inner {
  position: relative;
  display: block;
  padding-left: 60px;
}

.about-section .text-column .sec-title {
  margin-bottom: 35px;
}

.about-section .text-column .sec-title .lower-text {
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 300;
  letter-spacing: 0;
  font-family: var(--thm-font);
  color: var(--thm-base);
  line-height: 34px;
}

.about-section .text-column .text {
  position: relative;
  font-size: 16px;
  font-weight: 400;
  line-height: 2.125em;
  font-family: var(--thm-b-font);
}

.about-section .text-column .text p {
  margin-bottom: 32px;
  line-height: 2.125em;
}

.about-section .text-column .text ul {
  position: relative;
  float: left;
}

.about-section .text-column .text ul li {
  position: relative;
  line-height: 1.7em;
  margin-bottom: 10px;
  padding-left: 35px;
}

.about-section .text-column .text ul li:before {
  content: "\f107";
  font-family: "Flaticon";
  position: absolute;
  left: 0;
  top: -1px;
  color: var(--thm-base);
  font-weight: 400;
}

.about-section .text-column .text .since {
  position: relative;
  float: left;
  margin-left: 82px;
  margin-top: 10px;
}

.about-section .text-column .text .since:before {
  content: "";
  position: absolute;
  left: -10px;
  top: 0px;
  width: 80px;
  height: 80px;
  background: #e8e9e9;
  border-radius: 50%;
}

.about-section .text-column .text .since:after {
  content: "";
  position: absolute;
  left: -42px;
  top: 0px;
  bottom: 0px;
  border-left: 2px solid var(--thm-base);
}

.about-section .text-column .text .since .txt {
  position: relative;
  display: block;
  padding: 21px 5px;
  width: 90px;
  height: 90px;
  line-height: 24px;
  text-align: center;
  background: var(--thm-black);
  text-transform: uppercase;
  color: #ffffff;
  border-radius: 50%;
  font-family: var(--thm-font);
  font-size: 20px;
  padding-top: 24px;
}

.about-section .text-column .link-box {
  padding-top: 30px;
}

/***

====================================================================
14.	Live Section
====================================================================

***/
.live-section {
  position: relative;
  padding: 120px 0px 0px;
  background: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.live-section__one-page {
  border-top: 0;
}

.live-section .main-image-box {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: -120px;
  z-index: 1;
}

.live-section .main-image-box .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
}

.live-section .main-image-box .inner {
  position: relative;
  padding: 20px 0px;
}

.live-section .main-image-box .round-box {
  position: relative;
  display: block;
  float: right;
  right: -25px;
  width: 490px;
  height: 490px;
  border-radius: 50%;
}

.live-section .main-image-box .round-box:before {
  content: "";
  position: absolute;
  left: -65px;
  top: -45px;
  width: 490px;
  height: 490px;
  background: var(--thm-black);
  opacity: 0.2;
  border-radius: 50%;
}

.live-section .main-image-box .round-inner {
  position: relative;
  display: block;
  width: 490px;
  height: 490px;
  padding: 190px 80px 50px 70px;
  background: var(--thm-base);
  border-radius: 50%;
}

.live-section .main-image-box .vid-link {
  position: absolute;
  left: 55px;
  top: 25px;
  width: 84px;
  height: 84px;
  line-height: 84px;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.live-section .main-image-box .vid-link .icon {
  position: relative;
  display: block;
  width: 84px;
  height: 84px;
  line-height: 84px;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  background: var(--thm-black);
  border-radius: 50%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.live-section .main-image-box .vid-link a:hover .icon {
  background: #ffffff;
  color: var(--thm-base);
}

.live-section .main-image-box .vid-link .icon:before {
  content: "";
  position: absolute;
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

.live-section .main-image-box .vid-link .ripple,
.live-section .main-image-box .vid-link .ripple:before,
.live-section .main-image-box .vid-link .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}

.live-section .main-image-box .vid-link .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.live-section .main-image-box .vid-link .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

@-webkit-keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

@keyframes ripple {
  70% {
    -webkit-box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 40px rgba(255, 255, 255, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

.live-section .main-image-box .title {
  position: relative;
}

.live-section .main-image-box .title h3 {
  text-transform: uppercase;
  line-height: 1em;
}

.live-section .main-image-box .more-link {
  position: relative;
  text-align: right;
}

.live-section .main-image-box .more-link a {
  position: relative;
  display: inline-block;
  padding-bottom: 2px;
  vertical-align: top;
  color: var(--thm-black);
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: var(--thm-font);
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  line-height: 1.25em;
}

.live-section .main-image-box .more-link a:hover {
  color: rgba(0, 0, 0, 0.3);
}

.live-section .main-image-box .more-link a:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 2px solid var(--thm-black);
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.live-section .main-image-box .more-link a:hover:before {
  border-color: rgba(0, 0, 0, 0.3);
  -webkit-transform: scaleX(0.5);
  transform: scaleX(0.5);
}

/* live section two */
.live-section-two {
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #18212e;
}

.live-section-two .jarallax-img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.25;
}

.live-section-two .auto-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.live-section-two .sec-title-two {
  max-width: 557px;
  margin-bottom: 0;
}

.live-section-two .sec-title-two h2 {
  color: #ffffff;
  font-weight: bold;
  font-size: 60px;
  margin-top: 10px;
}

.live-section-two .button-block>a {
  width: 120px;
  height: 116px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  font-size: 26px;
  background-color: var(--thm-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.live-section-two .button-block>a:hover {
  background-color: #fff;
  color: var(--thm-black);
}

/***

====================================================================
15.	We DO Section
====================================================================

***/
.we-do-section {
  position: relative;
  padding: 240px 0px 90px;
  background: #ffffff url(../assets/images/background/pattern-2.png) left top repeat;
}

.we-do-section .sec-title {
  margin-bottom: 40px;
}

.we-do-section .featured-block {
  position: relative;
  display: block;
  padding-left: 230px;
  min-height: 117px;
  margin-bottom: 40px;
}

.we-do-section .featured-block .image {
  position: absolute;
  left: 0;
  top: 0;
  width: 200px;
}

.we-do-section .featured-block .image img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  border-radius: 7px;
}

.we-do-section .featured-block .text {
  position: relative;
  top: -5px;
  display: block;
  font-size: 16px;
  line-height: 34px;
  font-weight: 400;
  margin-bottom: 32px;
}

.we-do-section .left-col {
  position: relative;
  margin-bottom: 30px;
}

.we-do-section .left-col .inner {
  position: relative;
  display: block;
  padding-right: 67px;
}

.we-do-section .right-col {
  position: relative;
  margin-bottom: 30px;
}

.we-do-section .right-col .inner {
  position: relative;
  display: block;
}

.we-do-section .progress-box {
  position: relative;
  display: block;
  width: 100%;
  font-family: var(--thm-font);
}

.we-do-section .progress-box .bar-title {
  position: relative;
  font-size: 20px;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

.we-do-section .progress-box .bar {
  position: relative;
  width: 100%;
  height: 7px;
  background: #ffffff;
  border-radius: 4px;
}

.we-do-section .progress-box .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 7px;
  background: var(--thm-base);
  border-radius: 4px;
  -webkit-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.we-do-section .progress-box .count-box {
  position: absolute;
  right: 0px;
  margin-right: -40px;
  bottom: 18px;
  width: 40px;
  height: 22px;
  background: var(--thm-black);
  color: #ffffff;
  line-height: 22px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.we-do-section .progress-box .counted .count-box {
  opacity: 1;
}

.we-do-section .progress-box .count-box:after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: -5px;
  border: 5px solid transparent;
  border-top: 5px solid var(--thm-black);
  border-left: 5px solid var(--thm-black);
}

.accordion-box {
  position: relative;
}

.accordion-box .block {
  position: relative;
  background: #ffffff;
  border-radius: 7px;
  overflow: hidden;
  margin-bottom: 16px;
  -ms-box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.03);
  -webkit-box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.03);
}

.accordion-box .block.active-block {
  background-color: #ffffff;
  -ms-box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 40px 5px rgba(0, 0, 0, 0.05);
}

.accordion-box .block:last-child {
  margin-bottom: 0;
}

.accordion-box .block .acc-btn {
  position: relative;
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  padding: 20px 40px;
  padding-right: 60px;
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-family: var(--thm-font);
}

.accordion-box .block .acc-btn .count {
  color: var(--thm-base);
  padding-right: 3px;
}

.accordion-box .block .acc-btn:before {
  position: absolute;
  right: 35px;
  top: 20px;
  height: 30px;
  font-size: 15px;
  font-weight: 600;
  color: var(--thm-base);
  line-height: 30px;
  content: "\f116";
  font-family: "Flaticon";
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.accordion-box .block .acc-btn.active {
  background-color: #ffffff;
}

.accordion-box .block .acc-btn.active:before {
  color: var(--thm-black);
  content: "\f104";
}

.accordion-box .block .acc-content {
  position: relative;
  display: none;
}

.accordion-box .block .content {
  position: relative;
  padding: 0px 40px 30px;
}

.accordion-box .block .acc-content.current {
  display: block;
}

.accordion-box .block .content .text {
  display: block;
  position: relative;
  top: 0px;
  display: block;
}

/***

====================================================================
16.		Gallery Section
====================================================================

***/
.gallery-section {
  position: relative;
  padding: 120px 0 90px;
}

.gallery-section .upper-row {
  position: relative;
}

.gallery-section .upper-row .sec-title {
  position: relative;
  float: left;
}

.gallery-section .upper-row .filters {
  position: relative;
  float: right;
  padding-top: 10px;
}

.gallery-section .more-box {
  position: relative;
  display: block;
  padding-top: 20px;
  text-align: center;
  padding-bottom: 10px;
}

/*=== Mixitup Gallery ===*/
.mixitup-gallery .filters {
  position: relative;
  margin-bottom: 40px;
}

.mixitup-gallery .filters .filter-tabs {
  position: relative;
  display: block;
}

.mixitup-gallery .filters li {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 24px;
  line-height: 40px;
  padding: 0px 0px;
  cursor: pointer;
  color: var(--thm-text);
  font-weight: 300;
  margin: 0 0px 10px 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: none;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-family: var(--thm-font);
}

.mixitup-gallery .filters li:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 2px solid var(--thm-base);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.mixitup-gallery .filters.centered li {
  margin: 0px 15px 10px;
}

.mixitup-gallery .filters li sup {
  position: absolute;
  right: 0;
  top: 3px;
  font-size: 16px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.mixitup-gallery .filters .filter.active {
  color: var(--thm-black);
  font-weight: 400;
  padding-right: 20px;
}

.mixitup-gallery .filters .filter.active sup {
  opacity: 1;
  visibility: visible;
}

.mixitup-gallery .filters li.active:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.mixitup-gallery .gallery-item-two {
  display: none;
}

.mix {
  display: none;
}

.gallery-item {
  position: relative;
  margin-bottom: 30px;
}

.gallery-item .inner-box {
  position: relative;
  border-radius: 7px;
  overflow: hidden;
}

.gallery-item .inner-box .image {
  position: relative;
  border-radius: 7px;
}

.gallery-item .inner-box .image img {
  display: block;
  width: 100%;
  height: auto;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border-radius: 7px;
}

.gallery-item .inner-box:hover .image img {
  -webkit-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%);
}

.gallery-item .cap-box {
  position: absolute;
  left: 20px;
  right: 20px;
  bottom: 20px;
  color: #ffffff;
  opacity: 0;
  -webkit-transform: translateY(200px);
  transform: translateY(200px);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.gallery-item .inner-box:hover .cap-box {
  opacity: 1;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.gallery-item .cap-box .cap-inner {
  position: relative;
  display: block;
  padding: 25px 35px;
  background: var(--thm-black);
  border-radius: 7px;
}

.gallery-item a.overlay-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.gallery-item .cap-box .cat {
  position: relative;
  display: block;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  font-family: var(--thm-font);
}

.gallery-item .cap-box .cat span {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  padding-right: 48px;
}

.gallery-item .cap-box .cat span:before {
  content: "";
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -1px;
  width: 38px;
  border-bottom: 2px solid var(--thm-base);
}

.gallery-item .cap-box .title {
  position: relative;
  display: block;
}

.gallery-item .cap-box h5 {
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  margin: 0;
}

.gallery-item .cap-box h5 a {
  color: #ffffff;
}

.gallery-item .cap-box h5 a:hover {
  text-decoration: underline;
}

.gallery-block-three {
  background-color: #eff1f4;
  padding-top: 120px;
  padding-bottom: 90px;
}

.gallery-item-three {
  margin-bottom: 30px;
}

.gallery-item-three>img {
  width: 100%;
}

.gallery-item-three {
  position: relative;
  overflow: hidden;
}

.gallery-item-three__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 30px;
  -webkit-transform: rotateX(90deg);
  transform: rotateX(90deg);
  -webkit-perspective: 370px;
  perspective: 370px;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.gallery-item-three:hover .gallery-item-three__content {
  -webkit-transform: rotateX(0);
  transform: rotateX(0);
}

.gallery-item-three__content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--thm-base);
  opacity: 0.9;
}

.gallery-item-three__content p {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 12px;
  font-weight: 600;
  color: #ffffff;
  position: relative;
}

.gallery-item-three__content h3 {
  margin: 0;
  color: #fff;
  font-size: 34px;
  line-height: 36px;
  font-weight: 800;
  position: relative;
}

.gallery-item-three__content h3 a {
  color: inherit;
}

/***

====================================================================
17.	Fun Facts Section
====================================================================

***/
.facts-section {
  position: relative;
  padding: 0px 0px 290px;
  background: #1c1e22;
  color: #ffffff;
  z-index: 2;
}

.facts-section.alternate {
  background: var(--thm-black);
  padding-bottom: 50px;
}

.facts-section .jarallax-img,
.facts-section .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.3;
}

.facts-section.alternate .image-layer {
  display: none;
}

.fact-counter {
  position: relative;
}

.fact-counter .column {
  position: relative;
  text-align: center;
  margin-bottom: 70px;
}

.fact-counter .column .inner {
  position: relative;
  padding: 120px 0px 0px;
}

.fact-counter .column .inner:before {
  position: absolute;
  content: "";
  left: -15px;
  right: -15px;
  top: 0;
  min-width: 100%;
  border-top: 4px solid var(--thm-base);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.fact-counter .column:hover .inner:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.fact-counter .column .inner:after {
  position: absolute;
  content: "";
  right: -15px;
  top: 0;
  bottom: 7px;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
}

.fact-counter .column:last-child .inner:after {
  display: none;
}

.fact-counter .column .inner .content {
  position: relative;
  text-align: center;
}

.fact-counter .column .inner .count-outer {
  position: relative;
  font-weight: 400;
  font-size: 80px;
  line-height: 1em;
  letter-spacing: 0px;
  display: block;
  font-family: var(--thm-font);
}

.fact-counter .column .inner .counter-title {
  position: relative;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  letter-spacing: 1px;
  font-family: var(--thm-font);
  text-transform: uppercase;
}

/***

====================================================================
18.	We DO Section
====================================================================

***/
.trusted-section {
  position: relative;
  padding: 0px 0px 70px;
  background: none;
  z-index: 2;
}

.trusted-section .outer-container {
  position: relative;
  margin-top: -240px;
}

.trusted-section .row {
  margin: 0 -4px;
}

.trusted-section .left-col {
  position: relative;
  padding: 0 4px;
  margin-bottom: 30px;
}

.trusted-section .left-col .inner {
  position: relative;
  display: block;
}

.trusted-section .col-header {
  position: relative;
  text-align: center;
}

.trusted-section .col-header .header-inner {
  position: relative;
  padding: 75px 50px 20px;
  min-height: 240px;
  background: var(--thm-base);
  font-weight: 400;
  font-size: 40px;
  line-height: 1.2em;
  text-transform: uppercase;
  letter-spacing: 0px;
  color: var(--thm-black);
  border-radius: 7px 7px 0px 0px;
  font-family: var(--thm-font);
}

.trusted-section .col-header .header-inner:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -39px;
  width: 50px;
  height: 40px;
  background: url(../assets/images/icons/curve-down-left.png) left top no-repeat;
}

.trusted-section .features {
  position: relative;
}

.trusted-section .feature {
  position: relative;
  display: block;
  padding: 55px 0px;
  padding-left: 90px;
  min-height: 170px;
  border-bottom: 1px solid #e9ebee;
}

.trusted-section .feature .count {
  position: absolute;
  left: 0;
  top: 55px;
  width: 60px;
  height: 60px;
  text-align: center;
  background: rgba(var(--thm-base-rgb), 0.2);
  padding: 10px;
  line-height: 40px;
  padding-top: 12px;
  font-size: 30px;
  letter-spacing: 1px;
  color: var(--thm-base);
  text-transform: uppercase;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-family: var(--thm-font);
}

.trusted-section .feature:hover .count {
  background-color: var(--thm-black);
  color: #fff;
}

.trusted-section .feature h5 {
  font-weight: 400;
  margin: 0;
  line-height: 1.15em;
  color: var(--thm-black);
  margin-bottom: 10px;
}

.trusted-section .feature .sub-text {
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.1em;
  color: var(--thm-text);
  text-transform: uppercase;
  line-height: 1.25em;
  font-family: var(--thm-font);
}

.trusted-section .right-col {
  position: relative;
  padding: 0px 4px;
  margin-bottom: 30px;
  z-index: 3;
}

.trusted-section .right-col .inner {
  position: relative;
  display: block;
  min-height: 260px;
  padding: 115px 0px 0px 110px;
  background: #ffffff;
  border-radius: 7px 0px 0px 0px;
}

.trusted-section .right-col .inner:before {
  content: "";
  position: absolute;
  left: 100%;
  top: 0;
  margin-left: -1px;
  width: 2000px;
  height: 100%;
  background: #ffffff;
}

.trusted-section .featured-block-two {
  position: relative;
  display: block;
  padding-left: 276px;
  min-height: 170px;
}

.trusted-section .featured-block-two .image {
  position: absolute;
  left: 0;
  top: 0;
  width: 250px;
}

.trusted-section .featured-block-two .image img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  border-radius: 7px;
}

.trusted-section .featured-block-two .text {
  position: relative;
  top: -5px;
  display: block;
  line-height: 1.5em;
}

.trusted-section .featured-block-two .text ul {
  position: relative;
}

.trusted-section .featured-block-two .text ul li {
  position: relative;
  line-height: 2.125em;
  margin-bottom: 12px;
  padding-left: 30px;
}

.trusted-section .featured-block-two .text ul li:before {
  content: "\f107";
  font-family: "Flaticon";
  position: absolute;
  left: 0;
  top: -1px;
  color: var(--thm-base);
  font-weight: 400;
  font-size: 18px;
}

/***

====================================================================
19.	Team Section
====================================================================

***/
.team-section {
  position: relative;
  padding: 0px 0px 90px;
  background: none;
  z-index: 2;
}

.team-section .carousel-box {
  position: relative;
  margin: 0 -5px;
}

.team-block {
  position: relative;
  margin-bottom: 30px;
}

.team-block .inner-box {
  position: relative;
  display: block;
  text-align: center;
}

.team-block .image-box {
  position: relative;
  display: block;
  overflow: hidden;
}

.team-block .image-box img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 7px;
}

.team-block .inner-box:hover .image-box img {
  -webkit-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%);
}

.team-block .image-box .social-links {
  position: absolute;
  left: 0;
  bottom: 35px;
  width: 100%;
  padding: 0px 5px;
  opacity: 0;
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-block .inner-box:hover .social-links {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.team-block .image-box .social-links li {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0px 3px;
}

.team-block .image-box .social-links li a {
  position: relative;
  display: block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  font-size: 16px;
  background: #ffffff;
  color: var(--thm-black);
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-block .image-box .social-links li a span {
  vertical-align: middle;
  line-height: 44px;
}

.team-block .image-box .social-links li a:hover {
  background: var(--thm-base);
}

.team-block .lower-box {
  position: relative;
  display: block;
  padding: 30px 0px 0;
}

.team-block .lower-box .designation {
  position: relative;
  display: block;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.2em;
  font-weight: 400;
  color: var(--thm-text);
  padding-top: 5px;
  font-family: var(--thm-font);
}

.team-block .lower-box h5 {
  font-size: 24px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1.05em;
  font-weight: 400;
  margin: 0;
}

.team-block .lower-box h5 a {
  color: var(--thm-black);
}

.team-block .lower-box h5 a:hover {
  text-decoration: underline;
}

.team-section .team-carousel .tns-nav {
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 30px;
}

.team-section .team-carousel .tns-nav button {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0px 3px;
  width: 10px;
  height: 10px;
  background: #d3d3d4;
  border: 2px solid #d3d3d4;
  border-radius: 50%;
}

.team-section .team-carousel .tns-nav button.tns-nav-active {
  background: #ffffff;
  border-color: var(--thm-base);
}

.team-three {
  padding-top: 120px;
  padding-bottom: 90px;
}

.team-three__about-two {
  background-image: url(../assets/images/background/pattern-2.png);
}

.team-card-three {
  margin-bottom: 30px;
  border-radius: 8px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-card-three:hover {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
}

.team-card-three__inner {
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}

.team-card-three__image img {
  width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.team-card-three__hover,
.team-card-three__content {
  border: 1px solid #e9ebee;
  text-align: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: #fff;
  -webkit-transition: opacity 500ms ease, visibility 500ms ease,
    -webkit-transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease,
    -webkit-transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease, transform 500ms ease,
    -webkit-transform 500ms ease;
}

.team-card-three__content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
}

.team-card-three:hover .team-card-three__content {
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
}

.team-card-three__hover {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
}

.team-card-three:hover .team-card-three__hover {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.team-card-three__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.team-card-three__social a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #f4f5f8;
  font-size: 16px;
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-top: 15px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.team-card-three__social a:hover {
  background-color: var(--thm-base);
  color: var(--thm-black);
}

.team-card-three__social li+li {
  margin-left: 10px;
}

.team-card-three__name {
  margin: 0;
  text-transform: uppercase;
  font-size: 24px;
  color: var(--thm-black);
  letter-spacing: 0.1em;
  line-height: 1;
}

.team-card-three__designation {
  text-transform: uppercase;
  color: #686a6f;
  font-size: 16px;
  margin: 0;
  line-height: 1;
  letter-spacing: 0.1em;
  margin-top: 10px;
  font-family: var(--thm-font);
}

/***

====================================================================
20.	Parallax Section
====================================================================

***/
.parallax-section {
  position: relative;
  padding: 120px 0px;
  background: #1c1e22;
  text-align: center;
  color: #ffffff;
  z-index: 1;
}

.parallax-section .jarallax-img,
.parallax-section .image-layer {
  position: absolute;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.3;
}

.parallax-section .content-box {
  position: relative;
  max-width: 870px;
  margin: 0 auto;
}

.parallax-section .content-box .icon-box {
  position: relative;
  display: block;
  width: 114px;
  height: 114px;
  line-height: 114px;
  text-align: center;
  color: var(--thm-black);
  font-size: 54px;
  border-radius: 50%;
  margin: 0 auto 50px;
}

.parallax-section .content-box .icon-box:before {
  content: "";
  position: absolute;
  left: -7px;
  top: 0;
  display: block;
  width: 80px;
  height: 80px;
  background: #ffffff;
  opacity: 0.3;
  border-radius: 50%;
}

.parallax-section .content-box .icon-box:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: var(--thm-base);
  z-index: 1;
  border-radius: 50%;
}

.parallax-section .content-box .icon-box span {
  position: relative;
  z-index: 2;
}

.parallax-section .content-box h2 {
  position: relative;
  display: block;
  font-size: 70px;
  line-height: 1em;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 500;
  margin-bottom: 0px;
}

.parallax-section .content-box h2 span {
  color: var(--thm-base);
}

/***

====================================================================
21.	Sponsors Section
====================================================================

***/
.sponsors-section {
  position: relative;
  padding: 0px 0px 24px 0px;
  background: #ffffff;
}

.sponsors-section__has-border-top {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.sponsors-section__pricing-two {
  background-color: #f4f5f8;
}

.sponsors-section__dark {
  background-color: #1c1e22;
}

.sponsors-section__one-page {
  border-bottom: 1px solid #e9ebee; 
}

.sponsors-outer {
  position: relative;
}

.sponsors-outer .slide-item {
  display: block;
}

.sponsors-outer .image-box {
  position: relative;
  display: inline-block;
}

.sponsors-outer .image-box img {
  max-width: 100%;
  width: auto;
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.sponsors-outer .image-box img:hover {
  opacity: 1;
}

.sponsors-outer .owl-dots,
.sponsors-outer .owl-nav {
  position: relative;
  display: none;
}

.sponsors-section-four {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (min-width: 992px) {
  .sponsors-section-four {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.sponsors-section-four .row {
  --bs-gutter-y: 30px;
}

.sponsors-section-four .image-box {
  background-color: #f4f5f8;
  border-radius: 8px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sponsors-section-four .image-box:hover {
  background-color: var(--thm-black);
}

.sponsors-section-four .image-box img {
  -webkit-filter: invert(0%);
  filter: invert(0%);
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
}

.sponsors-section-four .image-box:hover img {
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.sponsors-section-four .theme-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.sponsors-section-four .content-box {
  padding-top: 73px;
}

@media (min-width: 992px) {
  .sponsors-section-four .content-box {
    padding-top: 113px;
  }
}

.sponsors-section-four .content-box h3 {
  margin: 0;
  text-transform: uppercase;
  color: var(--thm-black);
  font-size: 40px;
  line-height: 1em;
  letter-spacing: 0.1em;
  margin-bottom: 10px;
}

@media (min-width: 768px) {
  .sponsors-section-four .content-box h3 {
    font-size: 50px;
  }
}

@media (min-width: 992px) {
  .sponsors-section-four .content-box h3 {
    font-size: 80px;
    margin-bottom: 40px;
  }
}

/***

====================================================================
22.	Agency Section
====================================================================

***/
.agency-section {
  position: relative;
  padding: 120px 0px 80px;
  background: #ffffff url(../assets/images/background/pattern-2.png) left top repeat;
}

.agency-section .sec-title {
  margin-bottom: 40px;
}

.agency-section .featured-block {
  position: relative;
  display: block;
  padding-left: 230px;
  min-height: 117px;
  margin-bottom: 40px;
}

.agency-section .featured-block .image {
  position: absolute;
  left: 0;
  top: 0;
  width: 200px;
}

.agency-section .featured-block .image img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  border-radius: 7px;
}

.agency-section .featured-block .text {
  position: relative;
  top: -5px;
  display: block;
  line-height: 1.7em;
  letter-spacing: 0.1em;
  margin-bottom: 32px;
}

.agency-section .left-col {
  position: relative;
  margin-bottom: 30px;
}

.agency-section .left-col .inner {
  position: relative;
  display: block;
  padding-right: 50px;
}

.agency-section .right-col {
  position: relative;
  margin-bottom: 30px;
}

.agency-section .right-col .inner {
  position: relative;
  display: block;
}

.tabs-box {
  position: relative;
}

.tabs-box .tab-buttons {
  position: relative;
}

.tabs-box .tab-buttons .tab-btn {
  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.tabs-box .tabs-content {
  position: relative;
}

.tabs-box .tabs-content .tab {
  position: relative;
  display: none;
}

.tabs-box .tabs-content .active-tab {
  display: block;
}

.default-tabs .tab-buttons .tab-btn {
  position: relative;
  line-height: 30px;
  font-size: 16px;
  font-weight: 500;
  color: var(--thm-black);
  text-align: center;
  padding: 14px 40px 11px;
  text-transform: uppercase;
  margin-bottom: 10px;
  margin-right: 10px;
  letter-spacing: 0.07em;
  border-radius: 7px;
  font-family: var(--thm-font);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.default-tabs .tab-buttons .tab-btn span {
  position: relative;
  display: block;
}

.default-tabs .tab-buttons .tab-btn:last-child {
  margin-right: 0;
}

.default-tabs .tab-buttons .tab-btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  background: var(--thm-base);
  border-radius: 7px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.default-tabs .tab-buttons .tab-btn.active-btn:before {
  height: 100%;
}

.default-tabs .tab-buttons .tab-btn:hover,
.default-tabs .tab-buttons .tab-btn.active-btn:hover {
  color: var(--thm-black);
}

.default-tabs .tabs-content {
  position: relative;
  padding-top: 40px;
}

.default-tabs .tabs-content .content {
  position: relative;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.default-tabs .tabs-content .active-tab .content {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.default-tabs .tabs-content .text {
  position: relative;
  color: var(--thm-text);
  font-size: 16px;
  line-height: 34px;
}

.agency-section .right-col .text {
  position: relative;
  color: var(--thm-text);
  margin-bottom: 50px;
}

.agency-section .featured-block-two {
  position: relative;
  display: block;
  padding-left: 268px;
  min-height: 248px;
  padding-top: 20px;
}

.agency-section .featured-block-two .image {
  position: absolute;
  left: 0;
  top: 0;
  width: 242px;
  border-radius: 50%;
}

.agency-section .featured-block-two .image img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  border-radius: 50%;
}

.agency-section .featured-block-two .text {
  position: relative;
  top: -5px;
  display: block;
  line-height: 1.5em;
  margin-bottom: 0;
}

.agency-section .featured-block-two .text ul {
  position: relative;
}

.agency-section .featured-block-two .text ul li {
  position: relative;
  line-height: 2.125em;
  margin-bottom: 12px;
  padding-left: 30px;
  color: var(--thm-text);
}

.agency-section .featured-block-two .text ul li:before {
  content: "\f107";
  font-family: "Flaticon";
  position: absolute;
  left: 0;
  top: -1px;
  color: var(--thm-base);
  font-weight: 400;
  font-size: 18px;
}

/***

====================================================================
23.	News Section
====================================================================

***/
.news-section {
  position: relative;
  padding: 120px 0px 90px;
}

.news-section.alt-bg {
  background: #ffffff url(../assets/images/background/pattern-2.png) left top repeat;
}

.news-section .more-box {
  position: relative;
  display: block;
  padding-top: 30px;
  text-align: center;
  padding-bottom: 10px;
}

.news-block {
  position: relative;
  margin-bottom: 30px;
}

.news-block .inner-box {
  position: relative;
  display: block;
  text-align: center;
}

.news-block .image-box {
  position: relative;
  display: block;
  overflow: hidden;
}

.news-block .image-box img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 7px;
}

.news-block .inner-box:hover .image-box img {
  -webkit-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%);
}

.news-block .lower-box {
  position: relative;
  padding: 0px 20px 0px;
}

.news-block .post-meta {
  position: relative;
  top: -27px;
  margin: 0 10px -3px;
}

.news-block .post-meta ul {
  position: relative;
  display: block;
  padding: 10px 5px 0px;
  background: #ffffff;
  border-radius: 7px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
}

.news-block .post-meta ul li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 10px 10px;
  font-size: 16px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-family: var(--thm-font);
}

.news-block .post-meta ul li .far,
.news-block .post-meta ul li .fa {
  position: relative;
  color: var(--thm-base);
  font-size: 14px;
  padding-right: 3px;
  vertical-align: middle;
}

.news-block .lower-box h5 {
  text-transform: uppercase;
  font-size: 34px;
  color: var(--thm-black);
  margin-bottom: 35px;
}

.news-block .lower-box h5 a {
  color: var(--thm-black);
  -webkit-transition: 500ms;
  transition: 500ms;
}

.news-block .lower-box h5 a:hover {
  color: var(--thm-base);
  text-decoration: underline;
}

.news-block .lower-box .text {
  position: relative;
  top: -5px;
  color: var(--thm-text);
  display: block;
}

.news-block .lower-box .link-box {
  position: relative;
  margin-top: 20px;
}

.news-block .lower-box .link-box a {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 12px;
  color: #ffffff;
  background: #d3d3d4;
  border-radius: 50%;
}

.news-block .lower-box .link-box a span {
  font-weight: 700;
  vertical-align: middle;
}

.news-block .lower-box .link-box a:hover {
  background: var(--thm-base);
  color: var(--thm-black);
}

.news-block-two {
  position: relative;
  margin-bottom: 40px;
}

.news-block-two .inner-box {
  position: relative;
  display: block;
}

.news-block-two .image-box {
  position: relative;
  display: block;
  overflow: hidden;
}

.news-block-two .image-box img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 7px;
}

.news-block-two .inner-box:hover .image-box img {
  -webkit-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%);
}

.news-block-two .lower-box {
  position: relative;
  padding: 30px 0px 0px;
}

.news-block-two .post-meta {
  position: relative;
  display: block;
}

.news-block-two .post-meta ul {
  position: relative;
  display: block;
}

.news-block-two .post-meta ul li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px 5px 0px;
  font-size: 16px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  font-family: var(--thm-font);
}

.news-block-two .post-meta ul li .far,
.news-block-two .post-meta ul li .fa {
  position: relative;
  top: -2px;
  color: var(--thm-base);
  font-size: 14px;
  padding-right: 3px;
  vertical-align: middle;
}

.news-block-two .lower-box h4 {
  text-transform: uppercase;
  font-size: 40px;
  line-height: 1.1em;
  color: var(--thm-black);
  margin-bottom: 30px;
}

.news-block-two .lower-box h4 a {
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.news-block-two .lower-box h4 a:hover {
  color: var(--thm-base);
  text-decoration: underline;
}

.news-block-two .lower-box .text {
  position: relative;
  top: -5px;
  display: block;
}

.news-block-two .lower-box .link-box {
  position: relative;
  margin-top: 20px;
}

.news-block-two .lower-box .link-box a {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-size: 20px;
  color: var(--thm-black);
  letter-spacing: 1px;
  font-family: var(--thm-font);
}

.news-block-two .lower-box .link-box a:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 2px solid var(--thm-base);
}

.news-block-two .lower-box .link-box a:hover {
  color: var(--thm-base);
}

.news-block-two .lower-box .link-box a:hover:before {
  border-color: var(--thm-black);
}

.news-block-two .image-box .vid-link {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-align: center;
  font-size: 24px;
  color: var(--thm-black);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  z-index: 3;
}

.news-block-two .image-box .vid-link .icon {
  position: absolute;
  display: block;
  left: 50%;
  top: 50%;
  margin-top: -50px;
  margin-left: -50px;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 24px;
  color: var(--thm-black);
  background: var(--thm-base);
  border-radius: 50%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.news-block-two .image-box .vid-link:hover .icon {
  background: var(--thm-black);
  color: var(--thm-base);
}

.news-block-three {
  position: relative;
  margin-bottom: 30px;
}

.news-block-three .inner-box {
  position: relative;
  display: block;
  padding: 50px 50px 40px;
  background: #f4f5f8;
  border-radius: 7px;
}

.news-block-three .quote-icon {
  position: relative;
  display: block;
  top: 30px;
  font-weight: 400;
  font-size: 150px;
  line-height: 0.4em;
  color: var(--thm-base);
  margin-bottom: 10px;
}

.news-block-three .link-icon {
  position: relative;
  display: block;
  font-weight: 400;
  font-size: 40px;
  line-height: 1em;
  color: var(--thm-base);
  margin-bottom: 15px;
}

.news-block-three .inner-box h4 {
  text-transform: uppercase;
  font-size: 40px;
  line-height: 1.1em;
  color: var(--thm-black);
  font-weight: 400;
  margin-bottom: 0px;
}

.news-block-three .inner-box h4 a {
  color: var(--thm-black);
}

.news-block-three .inner-box:hover h4 a {
  color: var(--thm-base);
  text-decoration: underline;
}

.news-block-three .inner-box .text {
  position: relative;
  top: -5px;
  display: block;
  margin: 0;
}

.news-block-three .over-link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.sidebar-page-container .more-box {
  position: relative;
  padding-top: 20px;
}

/* news two */
.news-two {
  padding-top: 120px;
  padding-bottom: 120px;
}

.news-two .swiper-pagination {
  position: relative;
  left: auto;
  bottom: auto;
  margin-top: 40px;
}

.news-two .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  background-color: var(--thm-black);
  opacity: 0.2;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-left: 2.5px;
  margin-right: 2.5px;
}

.news-two .swiper-pagination span:hover,
.news-two .swiper-pagination span.swiper-pagination-bullet-active {
  opacity: 1 !important;
  background-color: var(--thm-base) !important;
}

.news-two__image {
  margin-bottom: 35px;
  background-color: var(--thm-base);
}

.news-two__image>img {
  width: 100%;
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.news-two__box:hover .news-two__image>img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.7;
}

.news-two__content {
  text-align: center;
}

.news-two__content h3 {
  margin: 0;
  color: var(--thm-black);
  font-size: 36px;
  font-weight: 800;
  line-height: 1.28;
  max-width: 466px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
}

.news-two__content h3 a {
  color: inherit;
}

.news-two__meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
}

.news-two__meta li {
  font-weight: 600;
  font-size: 16px;
  color: var(--thm-base);
  line-height: 1;
}

.news-two__meta li a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.news-two__meta li a:hover {
  color: var(--thm-black);
}

.news-two__meta li::after {
  content: "-";
  margin-left: 10px;
  margin-right: 10px;
}

.news-two__meta li:last-child::after {
  content: "";
  margin: 0;
}

/***

====================================================================
24.		Call Section
====================================================================

***/
.call-to-section {
  position: relative;
  padding: 0;
  z-index: 1;
}

.call-to-section .shape-1 {
  position: absolute;
  right: 0;
  top: 0;
  width: 340px;
  max-width: 100%;
  height: 100%;
  background: url(../assets/images/icons/call-to-shape-1.png) right top no-repeat;
  z-index: 1;
}

.call-to-section .shape-2 {
  position: absolute;
  right: 170px;
  top: 0;
  width: 410px;
  height: 54px;
  background: url(../assets/images/icons/call-to-shape-2.png) right top no-repeat;
  z-index: 0;
}

.call-to-section .inner {
  position: relative;
  display: block;
  padding: 95px 95px 68px;
  background: var(--thm-base);
  color: var(--thm-black);
  border-radius: 7px;
  overflow: hidden;
  bottom: -60px;
  margin-top: -60px;
}

.call-to-section h2 {
  position: relative;
  float: left;
  display: block;
  font-size: 60px;
  line-height: 0.85em;
  text-transform: uppercase;
  color: var(--thm-black);
  font-weight: 500;
  margin: 0px 0px 25px;
  z-index: 3;
}

.call-to-section .link-box {
  position: relative;
  float: right;
  padding-top: 12px;
  z-index: 3;
}

.call-to-section__no-margin .inner {
  bottom: auto;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 0;
  padding-top: 120px;
  padding-bottom: 110px;
}

@media (min-width: 992px) {
  .call-to-section__no-margin h2 {
    margin-bottom: 0;
    padding-top: 15px;
  }

  .call-to-section__no-margin .link-box {
    padding-top: 0;
  }
}

/***

====================================================================
25.	Main Footer
====================================================================

***/
.main-footer {
  position: relative;
  color: #999b9f;
  background: var(--thm-black) url(../assets/images/background/footer-bg.png) center top no-repeat;
}

.main-footer .widgets-section {
  position: relative;
  padding: 24px 0px 70px;
}

.main-footer.normal-padding .widgets-section {
  padding:24px 0px 70px;
}

.main-footer .column {
  position: relative;
  margin-bottom: 35px;
}

.main-footer .footer-widget {
  position: relative;
}

.main-footer .footer-logo {
  position: relative;
  margin-top: -10px;
  margin-bottom: 20px;
}

.main-footer h6 {
  position: relative;
  font-size: 24px;
  font-weight: 500;
  color: #ffffff;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  margin-bottom: 30px;
}

.main-footer .logo-widget {
  position: relative;
}

.main-footer .logo-widget .logo {
  position: relative;
  margin-bottom: 26px;
}

.main-footer .logo-widget .logo img {
  height: 103px;
}

.main-footer .logo-widget .text {
  position: relative;
  display: block;
  margin: 0 0 30px;
}

.main-footer .links-widget ul li {
  position: relative;
  margin-bottom: 8px;
}

.main-footer .links-widget ul li:last-child {
  margin: 0;
}

.main-footer .links-widget ul li a {
  position: relative;
  color: #999b9f;
  line-height: 30px;
  text-transform: capitalize;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.main-footer .links-widget ul li a:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 2px solid var(--thm-base);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left;
  transform-origin: left;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.main-footer .links-widget ul li a:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.main-footer .links-widget ul li a:hover {
  color: #ffffff;
}

.main-footer .social-links {
  position: relative;
}

.main-footer .social-links li {
  position: relative;
  margin-right: 8px;
  display: inline-block;
}

.main-footer .social-links li a {
  position: relative;
  display: block;
  color: #999b9f;
  font-size: 16px;
  width: 44px;
  height: 44px;
  line-height: 44px;
  background: #1c1e22;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.main-footer .social-links li a span {
  vertical-align: middle;
}

.main-footer .social-links li a:hover {
  color: #ffffff;
  background: var(--thm-base);
}

.main-footer .info-widget ul li {
  position: relative;
  display: block;
  line-height: 32px;
  padding-left: 30px;
  margin-bottom: 5px;
}

.main-footer .info-widget ul li .icon {
  position: absolute;
  left: 0;
  top: 0;
  line-height: 30px;
  font-size: 16px;
  color: var(--thm-base);
}

.main-footer .info-widget ul li:last-child {
  margin: 0;
}

.main-footer .info-widget ul li a {
  position: relative;
  color: #999b9f;
  line-height: 28px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.main-footer .info-widget ul li a:hover {
  color: #ffffff;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--thm-base);
  text-decoration-color: var(--thm-base);
}

.main-footer .newsletter-widget .text {
  position: relative;
  display: block;
  margin: 25px 0 0;
  font-size: 14px;
  line-height: 32px;
}

.main-footer .newsletter-form {
  position: relative;
}

.main-footer .newsletter-form form {
  position: relative;
}

.main-footer .newsletter-form .form-group {
  position: relative;
  margin: 0;
}

.main-footer .newsletter-form .form-group input[type="text"],
.main-footer .newsletter-form .form-group input[type="tel"],
.main-footer .newsletter-form .form-group input[type="email"] {
  position: relative;
  width: 100%;
  line-height: 30px;
  padding: 20px 60px 20px 25px;
  height: 70px;
  display: block;
  font-size: 18px;
  background: #1c1e22;
  color: #ffffff;
  border-radius: 5px;
  font-family: var(--thm-b-font);
  border: 1px solid #1c1e22;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 14px;
}

.main-footer .newsletter-form .form-group input[type="text"]:focus,
.main-footer .newsletter-form .form-group input[type="email"]:focus,
.main-footer .newsletter-form .form-group input[type="tel"]:focus {
  border-color: var(--thm-base);
}

.newsletter-form .form-group input::-webkit-input-placeholder {
  color: #999b9f;
}

.newsletter-form .form-group .theme-btn {
  position: absolute;
  right: 12px;
  top: 12px;
  width: 44px;
  height: 44px;
  background: var(--thm-base);
  color: var(--thm-black);
  border-radius: 50%;
}

.newsletter-form .form-group .theme-btn span {
  vertical-align: middle;
  font-size: 14px;
}

.newsletter-form .form-group .theme-btn:hover {
  background: #ffffff;
  color: var(--thm-base);
}

.main-footer .footer-bottom {
  position: relative;
  text-align: center;
}

.main-footer .footer-bottom .inner {
  position: relative;
  display: block;
  padding: 35px 0px;
  line-height: 30px;
  color: #999b9f;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.main-footer .footer-bottom .copyright {
  position: relative;
  line-height: 1.5em;
}

/* main footer two */
.main-footer__two {
  background-repeat: no-repeat;
  background-position: center center;
  background-color: var(--thm-black);
  padding-top: 120px;
}

.main-footer__two .footer-widget {
  padding-bottom: 60px;
}

.main-footer__two .footer-widget h3 {
  margin: 0;
  color: #fff;
  font-size: 80px;
  line-height: 0.78;
  text-transform: uppercase;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  letter-spacing: 0.1em;
}

.main-footer__two .footer-widget p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  margin: 0;
  margin-top: 30px;
  margin-bottom: 35px;
}

.main-footer__two .footer-widget p span,
.main-footer__two .footer-widget p a {
  color: #999b9f;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-footer__two .footer-widget p a:hover {
  color: var(--thm-base);
}

.main-footer__two .footer-widget p a+a {
  margin-left: 35px;
}

.main-footer__two .footer-widget p span {
  margin-left: 35px;
}

/* main footer three */
.main-footer__three {
  background-color: #18212e;
  padding-top: 100px;
}

.main-footer__three .footer-widget {
  margin-bottom: 30px;
}

.main-footer__three .footer-widget h3 {
  margin: 0;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 50px;
}

.main-footer__three .footer-widget p {
  margin: 0;
  font-size: 16px;
  line-height: 2.125;
  color: #959ca6;
  font-weight: 500;
  margin-top: -10px;
  margin-bottom: 30px;
}

.main-footer__three .footer-widget__links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: -30px;
}

.main-footer__three .footer-widget__links li {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  margin-top: 30px;
  line-height: 1;
}

.main-footer__three .footer-widget__links li a {
  font-size: 16px;
  font-weight: 500;
  color: #959ca6;
  display: inline-block;
  line-height: 1;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  position: relative;
}

.main-footer__three .footer-widget__links li a::before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.main-footer__three .footer-widget__links li:hover a::before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.main-footer__three .footer-widget__links li a:hover {
  color: var(--thm-base);
}

.main-footer__three .footer-widget .post-list li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.main-footer__three .footer-widget .post-list li+li {
  margin-top: 50px;
}

.main-footer__three .footer-widget .post-list li>img {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 68px;
  height: 70px;
  margin-right: 20px;
}

.main-footer__three .footer-widget .post-list li p {
  margin: 0;
  color: var(--thm-base);
  font-size: 12px;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.1em;
}

.main-footer__three .footer-widget .post-list li h4 {
  color: #959ca6;
  font-size: 16px;
  line-height: 2.125;
  margin: 0;
  font-weight: 500;
  margin-top: 10px;
}

.main-footer__three .footer-widget .post-list li h4 a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-footer__three .footer-widget .post-list li h4 a:hover {
  color: #fff;
}

.main-footer__three .footer-widget .contact-list {
  margin-top: -20px;
}

.main-footer__three .footer-widget .contact-list li {
  position: relative;
  font-size: 16px;
  line-height: 1.875;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-weight: 500;
  padding-left: 30px;
  min-height: 58px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-footer__three .footer-widget .contact-list li:last-child {
  border-bottom: 0;
  margin-top: 15px;
}

.main-footer__three .footer-widget .contact-list li a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-footer__three .footer-widget .contact-list li a:hover {
  color: #fff;
}

.main-footer__three .footer-widget .contact-list li>span {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-44%);
  transform: translateY(-44%);
  font-size: 18px;
  color: var(--thm-base);
}

.main-footer__three .footer-widget .contact-list li:last-child>span {
  top: 0;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.main-footer__three .btn-style-three:hover {
  background-color: #fff;
  color: var(--thm-black);
}

.main-footer__three .bottom-footer {
  background-color: #151d28;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 39.5px;
  padding-bottom: 39.5px;
  margin-top: 60px;
}

.main-footer__three .bottom-footer .auto-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.main-footer__three .bottom-footer .left-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-footer__three .bottom-footer .left-content p {
  margin: 0;
  font-size: 16px;
  color: #959ca6;
  font-weight: 500;
  line-height: 41px;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  padding-left: 40px;
  margin-left: 40px;
}

.main-footer__three .bottom-footer .social-links {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.main-footer__three .bottom-footer .social-links a {
  font-size: 20px;
  color: #959ca6;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.main-footer__three .bottom-footer .social-links a:hover {
  color: var(--thm-base);
}

.main-footer__three .bottom-footer .social-links li+li {
  margin-left: 40px;
}

.footer-four {
  background-color: #161616;
}

.footer-four .auto-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (min-width: 768px) {
  .footer-four .auto-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.footer-four__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}

@media (min-width: 768px) {
  .footer-four__social {
    margin-top: 0;
  }
}

.footer-four__social a {
  color: #a4a4a4;
  font-size: 18px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-four__social a:hover {
  color: #fff;
}

.footer-four__social a+a {
  margin-left: 20px;
}

@media (min-width: 768px) {
  .footer-four__social a {
    font-size: 20px;
  }

  .footer-four__social a+a {
    margin-left: 40px;
  }
}

/***

====================================================================
26.	Services Section
====================================================================

***/
.services-section-two {
  position: relative;
  padding: 120px 0px 0px;
  background: #ffffff;
}

.services-section-two .sec-title {
  margin-bottom: 30px;
}

.services-section-two .sec-title .column {
  margin-bottom: 20px;
}

.services-section-two .sec-title .lower-text {
  padding-top: 0;
  margin-top: 0;
}

.services-section-two .services {
  position: relative;
  z-index: 2;
  margin-bottom: -100px;
}

.service-block-two {
  position: relative;
  margin-bottom: 30px;
}

.service-block-two .inner-box {
  position: relative;
  display: block;
  padding: 50px 45px 40px;
  background: #ffffff;
  border-radius: 7px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-block-two .inner-box:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  border-radius: 7px;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.04);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-block-two .inner-box:hover:before {
  background: var(--thm-black);
  bottom: -10px;
}

.service-block-two .inner-box .bottom-curve {
  position: absolute;
  right: -70px;
  bottom: -10px;
  width: 120px;
  height: 120px;
  background: #ffffff;
  opacity: 0.1;
  z-index: 0;
  -webkit-transform: skewX(0deg) scaleX(0);
  transform: skewX(0deg) scaleX(0);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.service-block-two .inner-box:hover .bottom-curve {
  -webkit-transform: skewX(-45deg) scaleX(1);
  transform: skewX(-45deg) scaleX(1);
}

.service-block-two .inner-box .icon-box {
  position: relative;
  display: block;
  color: var(--thm-base);
  font-size: 64px;
  line-height: 1em;
  margin-bottom: 20px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.service-block-two .inner-box:hover .icon-box {
  color: var(--thm-base);
}

.service-block-two .inner-box h5 {
  font-size: 30px;
  /* text-transform: uppercase; */
  color: var(--thm-black);
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.1em;
  margin: 0;
}

.service-block-two .inner-box h5 a {
  color: var(--thm-black);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.service-block-two .inner-box button {
  font-size: 14px;
  color: var(--thm-black);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.service-block-two .inner-box:hover h5,
.service-block-two .inner-box:hover h5 a,
.service-block-two .inner-box:hover button {
  color: #ffffff;
}

.service-block-two .inner-box h5 a:hover {
  text-decoration: underline;
}

.service-block-two .inner-box button:hover {
  text-decoration: underline;
}

.service-block-two .text {
  position: relative;
  display: block;
  color: var(--thm-text);
  padding: 30px 0px 0px;
  z-index: 1;
  font-size: 17px;
}

.service-block-two .inner-box:hover .text {
  color: #999b9f;
}

.service-block-two .link-box {
  position: absolute;
  right: 3px;
  bottom: 8px;
  width: 30px;
  height: 30px;
  z-index: 1;
}

.service-block-two .link-box a {
  position: relative;
  line-height: 30px;
  font-size: 20px;
  color: #ffffff;
}

/***

====================================================================
27.	We DO Section
====================================================================

***/
.featured-section {
  position: relative;
  padding: 0px 0px 90px;
  background: #ffffff url(../assets/images/background/pattern-2.png) left top repeat;
}

.featured-section__about-two {
  background-color: #fff;
  background-image: none;
  padding-bottom: 80px;
  padding-top: 26px;
}

.featured-section .left-col {
  position: relative;
  margin-bottom: 30px;
}

.featured-section .left-col .inner {
  position: relative;
  display: block;
  padding-right: 30px;
}

.featured-section .left-col .image-box {
  position: relative;
  display: block;
  border-radius: 7px;
  overflow: hidden;
}

.featured-section .left-col .image-box:before {
  content: "";
  position: absolute;
  left: -90px;
  bottom: 0;
  width: 180px;
  height: 180px;
  background: var(--thm-base);
  z-index: 1;
  -webkit-transform: skewX(45deg);
  transform: skewX(45deg);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.featured-section .left-col .image-box img {
  display: block;
  width: 100%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 7px;
}

.featured-section .left-col .image-box:hover img {
  -webkit-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%);
}

.featured-section .right-col {
  position: relative;
}

.featured-section .right-col .inner {
  position: relative;
  display: block;
}

.featured-section .features {
  position: relative;
}

.featured-section .feature {
  position: relative;
  margin-bottom: 30px;
}

.featured-section .feature .inner-box {
  position: relative;
  display: block;
  padding-left: 15px;
}

.featured-section .feature .inner-box:before {
  content: "";
  position: absolute;
  left: 0;
  top: 11px;
  width: 5px;
  height: 5px;
  background: var(--thm-base);
  border-radius: 50%;
}

.featured-section .feature h6 {
  font-size: 24px;
  text-transform: uppercase;
  left: 1px;
  font-weight: 400;
  margin-bottom: 0;
  color: var(--thm-black);
}

.featured-section .feature .text {
  position: relative;
  display: block;
  color: var(--thm-text);
  padding: 10px 0px 0px;
}

/***

====================================================================
28.		Gallery Section
====================================================================

***/
.gallery-section-two {
  position: relative;
  padding: 120px 0 0px;
}

.gallery-section-two.alternate {
  position: relative;
  background: #ffffff url(../assets/images/background/pattern-2.png) right top repeat;
  padding-bottom: 90px;
}

.gallery-section-two .sec-title {
  margin-bottom: 40px;
}

.project-tab {
  position: relative;
}

.project-tab .tabs-header {
  position: relative;
  text-align: center;
}

.project-tab .product-tab-btns {
  position: relative;
  margin-bottom: 50px;
}

.project-tab .product-tab-btns .p-tab-btn {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-size: 24px;
  line-height: 40px;
  padding: 0px 0px;
  cursor: pointer;
  color: var(--thm-text);
  font-weight: 300;
  margin: 0 20px 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  background: none;
  font-family: var(--thm-font);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.project-tab .product-tab-btns .p-tab-btn:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 2px solid var(--thm-base);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left;
  transform-origin: left;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.project-tab .product-tab-btns .p-tab-btn sup {
  position: absolute;
  right: 0;
  top: 3px;
  font-size: 16px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.project-tab .product-tab-btns .p-tab-btn.active-btn sup {
  opacity: 1;
  visibility: visible;
}

.project-tab .product-tab-btns .p-tab-btn.active-btn:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.project-tab .product-tab-btns .p-tab-btn.active-btn {
  color: var(--thm-black);
  font-weight: 400;
  padding-right: 20px;
}

.project-tab .p-tabs-content {
  position: relative;
  display: block;
  margin: 0px -15px;
}

.project-tab .p-tab {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: auto;
  visibility: hidden;
}

.project-tab .p-tab.active-tab {
  position: relative;
  visibility: visible;
  z-index: 5;
}

.p-tab .gallery-item {
  position: relative;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.p-tab.active-tab .gallery-item {
  opacity: 1;
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.project-tab .owl-theme .owl-nav {
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 30px;
  display: none;
}

.project-tab .tns-nav {
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 40px;
  display: none;
}

.active-tab .tns-outer .tns-outer .tns-nav {
  display: block;
}

.project-tab .tns-nav button {
  display: inline-block;
  vertical-align: top;
  margin: 0px 3px;
  position: relative;
  width: 10px;
  height: 10px;
  background: #d3d3d4;
  border: 2px solid #d3d3d4;
  border-radius: 50%;
}

.project-tab .tns-nav button.tns-nav-active {
  background: #ffffff;
  border-color: var(--thm-base);
}

.gallery-section-two.alternate .tns-nav {
  display: none;
}

/***

====================================================================
29.	Why Us Section
====================================================================

***/
.why-us-section {
  position: relative;
  padding: 110px 0px 80px;
  background: #ffffff;
}

.why-us-section .left-col {
  position: relative;
  margin-bottom: 40px;
}

.why-us-section .left-col .inner {
  position: relative;
  display: block;
}

.why-us-section .left-col .round-box {
  position: relative;
  display: block;
  border-radius: 50%;
}

.why-us-section .left-col .image-box {
  position: relative;
  display: block;
  border-radius: 50%;
  overflow: hidden;
}

.why-us-section .left-col .image-box img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 50%;
}

.why-us-section .left-col .image-box:hover img {
  -webkit-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%);
}

.why-us-section .left-col .image-box:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: url(../assets/images/icons/shape-1.png) left top no-repeat;
  z-index: 1;
}

.why-us-section .left-col .image-box:after {
  content: "";
  position: absolute;
  left: 50px;
  top: -70%;
  width: 100%;
  height: 100%;
  background: var(--thm-black);
  opacity: 0.2;
  border-radius: 50%;
  z-index: 2;
}

.why-us-section .left-col .vid-link {
  position: absolute;
  right: 55px;
  bottom: 25px;
  width: 84px;
  height: 84px;
  line-height: 84px;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  z-index: 3;
}

.why-us-section .left-col .vid-link .icon {
  position: relative;
  display: block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
  font-size: 24px;
  color: var(--thm-black);
  background: var(--thm-base);
  border-radius: 50%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.why-us-section .left-col .vid-link a:hover .icon {
  background: var(--thm-black);
  color: var(--thm-base);
}

.why-us-section .left-col .vid-link .icon:before {
  content: "";
  position: absolute;
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}

.why-us-section .left-col .vid-link .ripple,
.why-us-section .left-col .vid-link .ripple:before,
.why-us-section .left-col .vid-link .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 116px;
  height: 116px;
  border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(var(--thm-base-rgb), 0.6);
  -o-box-shadow: 0 0 0 0 rgba(var(--thm-base-rgb), 0.6);
  -webkit-box-shadow: 0 0 0 0 rgba(var(--thm-base-rgb), 0.6);
  box-shadow: 0 0 0 0 rgba(var(--thm-base-rgb), 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}

.why-us-section .left-col .vid-link .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.why-us-section .left-col .vid-link .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

.why-us-section .right-col {
  position: relative;
}

.why-us-section .right-col .inner {
  position: relative;
  display: block;
  padding-left: 50px;
}

.why-us-section .features {
  position: relative;
}

.why-us-section .feature {
  position: relative;
  margin-bottom: 40px;
}

.why-us-section .feature .inner-box {
  position: relative;
  display: block;
  padding-left: 90px;
}

.why-us-section .feature .inner-box:before {
  content: "\f131";
  font-family: "Flaticon";
  position: absolute;
  left: 0;
  top: 0px;
  width: 60px;
  height: 60px;
  color: var(--thm-base);
  font-size: 20px;
  line-height: 60px;
  text-align: center;
  background: rgba(var(--thm-base-rgb), 0.2);
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.why-us-section .feature:hover .inner-box:before {
  background-color: var(--thm-black);
  color: #fff;
}

.why-us-section .feature h6 {
  font-size: 24px;
  text-transform: uppercase;
  left: 1px;
  font-weight: 400;
  margin-bottom: 0;
}

.why-us-section .feature .text {
  position: relative;
  display: block;
  color: var(--thm-text);
  padding: 10px 0px 0px;
}

/***

====================================================================
30.	Testimonials Section
====================================================================

***/
.testimonials-section {
  position: relative;
  padding: 120px 0px;
  background: #ffffff url(../assets/images/background/pattern-2.png) left top repeat;
  overflow: hidden;
}

.testimonials-page {
  padding-bottom: 90px;
}

.testimonials-section .sec-title {
  margin-bottom: 40px;
}

.testimonials-section .carousel-box {
  position: relative;
  margin: 0px 0px;
}

.testimonials-section .carousel-box .tns-ovh {
  overflow: visible;
}

.testi-block {
  position: relative;
  margin-bottom: 30px;
}

.tns-ovh .testi-block {
  margin: 0;
}

.testi-block .inner {
  position: relative;
  display: block;
  padding: 60px 60px 50px;
  background: #ffffff;
  border-radius: 7px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testi-block:hover .inner {
  background-color: var(--thm-base);
}

.testimonials-page .testi-block .inner {
  -ms-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05);
}

.tns-ovh .testi-block .inner {
  margin: 7px 0px;
}

.testi-block .icon {
  position: absolute;
  right: 50px;
  top: 60px;
  font-weight: 700;
  font-size: 100px;
  line-height: 1em;
  color: var(--thm-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testi-block:hover .icon {
  color: var(--thm-black);
}

.testi-block .info {
  position: relative;
  padding-left: 100px;
  padding-top: 16px;
  min-height: 70px;
  margin-bottom: 35px;
}

.testi-block .info .image {
  position: absolute;
  left: 0;
  top: 0;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
}

.testi-block .info .image img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  border-radius: 7px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testi-block:hover .info .image img {
  -webkit-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%);
}

.testi-block .info .name {
  display: block;
  font-size: 24px;
  line-height: 1.2em;
  color: var(--thm-black);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-family: var(--thm-font);
}

.testi-block .info .designation {
  display: block;
  font-size: 16px;
  color: var(--thm-base);
  line-height: 1.2em;
  font-weight: 300;
  text-transform: uppercase;
  font-family: var(--thm-font);
  letter-spacing: 0.05em;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testi-block:hover .info .designation {
  color: var(--thm-black);
}

.testi-block .text {
  position: relative;
  display: block;
  color: var(--thm-text);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  padding: 0px 0px;
}

.testi-block:hover .text {
  color: var(--thm-black);
}

.testimonials-section .testimonials-carousel .owl-nav {
  position: relative;
  width: 100%;
  text-align: center;
  padding-top: 30px;
  display: none;
}

.testimonials-section .testimonials-carousel .tns-nav {
  position: absolute;
  top: -70px;
  width: 1170px;
  left: 50%;
  margin-left: -585px;
  text-align: right;
}

.testimonials-section .testimonials-carousel .tns-nav button {
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin: 0px 0px 0px 6px;
  width: 10px;
  height: 10px;
  background: #d3d3d4;
  border: 2px solid #d3d3d4;
  border-radius: 50%;
}

.testimonials-section .testimonials-carousel .tns-nav button.tns-nav-active {
  background: #ffffff;
  border-color: var(--thm-base);
}

/* testimonials block threee */
.testi-block-three {
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #eff1f4;
}

.testi-block-three__single {
  text-align: center;
}

.testi-block-three__top {
  background-color: #fff;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-bottom: 50px;
  padding: 49px 0;
}

.testi-block-three__single:hover .testi-block-three__top {
  background-color: var(--thm-base);
}

.testi-block-three__top p {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 34px;
  color: #697280;
  max-width: 252px;
  margin-left: auto;
  margin-right: auto;
}

.testi-block-three__single:hover .testi-block-three__top p {
  color: #fff;
}

.testi-block-three__top::after {
  content: "";
  width: 78px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center center;
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testi-block-three__single h3 {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  color: var(--thm-black);
  line-height: 1;
  margin-top: 15px;
  margin-bottom: 10px;
}

.testi-block-three__single span {
  color: var(--thm-base);
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.2em;
  line-height: 1;
  display: block;
}

.testimonials-four {
  padding-top: 90px;
  padding-bottom: 60px;
}

@media (min-width: 992px) {
  .testimonials-four {
    padding-top: 120px;
    padding-bottom: 90px;
  }
}

.testimonials-four-card {
  border-style: solid;
  border-width: 1px;
  border-color: #e9ebee;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 45px;
  padding-bottom: 63px;
  padding-top: 30px;
  margin-bottom: 77px;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonials-four-card:hover {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
}

@media (min-width: 992px) {
  .testimonials-four-card {
    padding: 60px;
    padding-top: 50px;
  }
}

.testimonials-four-card__content p {
  margin: 0;
  font-size: 16px;
  line-height: 34px;
  color: #686a6f;
  font-family: var(--thm-b-font);
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .testimonials-four-card__content p {
    margin-bottom: 28px;
    font-size: 18px;
  }
}

.testimonials-four-card__content .name {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0;
  font-size: 24px;
  line-height: 1;
  color: var(--thm-black);
  font-weight: 400;
  font-family: var(--thm-font);
}

.testimonials-four-card__content .designation {
  font-weight: 300;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: var(--thm-base);
  font-family: var(--thm-font);
  margin: 0;
}

.testimonials-four-card .image {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 10px;
  border: 1px solid #e9ebee;
  border-radius: 8px;
  position: absolute;
  left: 45px;
  bottom: 0;
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonials-four-card:hover .image {
  border-color: var(--thm-base);
}

.testimonials-four-card .image img {
  border-radius: 8px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.testimonials-four__block__top {
  text-align: center;
  margin-bottom: 50px;
}

@media (min-width: 992px) {
  .testimonials-four__block__top {
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.testimonials-four__block__top .sec-title {
  margin-bottom: 15px;
}

@media (min-width: 992px) {
  .testimonials-four__block__top .sec-title {
    margin-bottom: 0;
  }
}

.testimonials-four__block__top .sec-title h2 {
  white-space: pre-line;
}

.testimonials-four__block__top .block-text p {
  white-space: pre-line;
  font-size: 16px;
  line-height: 34px;
  color: #686a6f;
  margin: 0;
  font-family: var(--thm-b-font);
}

.testimonials-four-carousel .tns-nav {
  display: none;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 30px;
}

.testimonials-four-carousel .tns-outer .tns-outer .tns-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.testimonials-four-carousel .tns-nav button {
  width: 10px;
  height: 10px;
  background-color: var(--thm-black);
  border: 2px solid var(--thm-black);
  opacity: 0.2;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-left: 5px;
}

.testimonials-four-carousel .tns-nav button.tns-nav-active {
  border-color: var(--thm-base);
  background-color: #fff;
  opacity: 1;
}

/* testimonials five */
.testimonials-five {
  padding-top: 90px;
  padding-bottom: 45px;
}

@media (min-width: 992px) {
  .testimonials-five {
    padding-top: 120px;
    padding-bottom: 75px;
  }
}

.testimonials-five .sec-title {
  margin-bottom: 50px;
}

.testimonials-five .sec-title h2 {
  font-size: 40px;
}

.testimonials-five-card {
  text-align: center;
}

.testimonials-five .testimonials-five-card .testimonials-five-card__image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  width: 125px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.testimonials-five-card__text {
  margin: 0;
  font-size: 30px;
  line-height: 1.5em;
  color: var(--thm-black);
  font-weight: 300;
  max-width: 875px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

@media (min-width: 992px) {
  .testimonials-five-card__text {
    font-size: 40px;
  }
}

.testimonials-five-card__title {
  margin: 0;
  text-transform: uppercase;
  color: var(--thm-base);
  font-size: 26px;
  line-height: 1;
  margin-top: 55px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .testimonials-five-card__title {
    font-size: 30px;
  }
}

.testimonials-five-card__designation {
  margin: 0;
  color: var(--thm-text);
  font-size: 18px;
  line-height: 1;
}

.testimonials-five__carousel .tns-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin-top: 40px !important;
}

@media (min-width: 992px) {
  .testimonials-five__carousel .tns-controls {
    margin-top: 0 !important;
  }
}

.testimonials-five__carousel .tns-controls button {
  width: 55px;
  height: 55px;
  background-color: #f4f5f8;
  border-radius: 50%;
  color: var(--thm-black);
  font-size: 18px;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonials-five__carousel .tns-controls button:hover {
  background-color: var(--thm-base);
  color: var(--thm-black);
}

.testimonials-five__carousel .tns-controls button.tns-next {
  margin-left: 20px;
}

@media (min-width: 992px) {
  .testimonials-five__carousel .tns-controls button {
    width: 73px;
    height: 73px;
    font-size: 22px;
    position: absolute;
    top: 50%;
    right: 100%;
  }

  .testimonials-five__carousel .tns-controls button.tns-next {
    right: auto;
    left: 100%;
  }
}

.testimonials-five__carousel .tns-controls button span::before {
  content: "\f172";
  font-family: "Flaticon";
  font-weight: 400;
}

.testimonials-five__carousel .tns-controls button.tns-next span::before {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  display: block;
}

.testimonials-six {
  text-align: center;
  padding-top: 70px;
}

@media (min-width: 768px) {
  .testimonials-six {
    padding-top: 110px;
  }
}

@media (min-width: 1200px) {
  .testimonials-six {
    padding-top: 190px;
  }
}

.testimonials-six__content {
  margin: 0;
  color: var(--thm-black);
  font-size: 40px;
  font-weight: 400;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.4em;
}

.testimonials-six__content br {
  display: none;
}

@media (min-width: 992px) {
  .testimonials-six__content {
    font-size: 50px;
  }

  .testimonials-six__content br {
    display: inherit;
  }
}

.testimonials-six__meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  color: var(--thm-text);
  margin-top: 20px;
}

@media (min-width: 992px) {
  .testimonials-six__meta {
    font-size: 28px;
    margin-top: 50px;
  }
}

.testimonials-six__meta span:first-child {
  color: var(--thm-base);
}

.testimonials-six__meta span:not(:last-child)::after {
  content: "-";
  margin-left: 20px;
  margin-right: 20px;
  color: var(--thm-text) !important;
}

.testimonials-six hr {
  border-width: 1px;
  border-color: #e9ebee;
  margin: 0;
  margin-top: 80px;
}

@media (min-width: 768px) {
  .testimonials-six hr {
    margin-top: 120px;
  }
}

#testimonials-six-pagination {
  position: relative;
  bottom: auto;
  left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  line-height: 1;
  margin-top: 45px;
}

#testimonials-six-pagination .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  background-color: var(--thm-text);
  border-radius: 50%;
  display: block;
  line-height: 1;
  opacity: 0.3;
  margin: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

#testimonials-six-pagination .swiper-pagination-bullet+.swiper-pagination-bullet {
  margin-left: 10px;
}

#testimonials-six-pagination .swiper-pagination-bullet-active {
  background-color: var(--thm-base);
  opacity: 1;
}

/***

====================================================================
31.	Parallax Section
====================================================================

***/
.features-section {
  position: relative;
  padding: 120px 0px;
  background: #1c1e22;
  color: #ffffff;
  z-index: 1;
}

.features-section .jarallax-img,
.features-section .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100% !important;
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.3;
  background-attachment: fixed;
}

.features-section .content-box {
  position: relative;
  max-width: 700px;
}

.features-section .content-box h2 {
  position: relative;
  display: block;
  font-size: 70px;
  line-height: 1em;
  text-transform: uppercase;
  color: #ffffff;
  letter-spacing: 0.01em;
  font-weight: 400;
  margin-bottom: 45px;
}

.features-section .content-box h2 span {
  color: var(--thm-base);
  padding-left: 3px;
}

.features-section .feature-block {
  position: relative;
  float: left;
  margin-bottom: 45px;
}

.features-section .feature-block .inner {
  position: relative;
  display: block;
  padding-right: 80px;
  margin-right: 80px;
}

.features-section .feature-block .inner:before {
  content: "";
  position: absolute;
  right: 0;
  top: 20px;
  bottom: 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
}

.features-section .feature-block:last-child .inner {
  padding-right: 0;
  margin-right: 0;
}

.features-section .feature-block:last-child .inner:before {
  display: none;
}

.features-section .feature-block .icon-box {
  position: relative;
  display: block;
  line-height: 1em;
  color: #ffffff;
  font-size: 60px;
  margin: 0 0 20px;
}

.features-section .feature-block h6 {
  font-size: 24px;
  line-height: 1.25em;
  margin: 0 0;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  font-weight: 400;
  color: #fff;
}

/* feature six */
.feature-six {
  background-color: #f4f5f8;
}

.feature-six__image {
  position: relative;
  margin-bottom: 60px;
}

@media (min-width: 1200px) {
  .feature-six__image {
    margin-bottom: 0;
  }
}

.feature-six__image img {
  width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

@media (min-width: 1200px) {
  .feature-six__image img {
    max-width: none !important;
    width: auto;
    float: right;
  }
}

.feature-six__image__caption {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--thm-base);
  padding: 40px;
  white-space: pre-line;
  text-transform: uppercase;
  line-height: 1;
  color: var(--thm-black);
  font-weight: 500;
  font-size: 22px;
  font-family: var(--thm-font);
}

@media (min-width: 992px) {
  .feature-six__image__caption {
    font-size: 30px;
    padding: 43px 60px;
  }
}

@media (min-width: 1200px) {
  .feature-six__content {
    padding-left: 60px;
  }
}

@media (min-width: 1440px) {
  .feature-six__content {
    padding-left: 115px;
  }
}

.feature-six__content .sec-title {
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .feature-six__content .sec-title {
    max-width: 550px;
  }
}

@media (min-width: 1200px) {
  .feature-six__content .sec-title {
    margin-bottom: 30px;
  }
}

.feature-six__content__text {
  font-size: 16px;
  line-height: 34px;
  color: var(--thm-text);
  font-family: var(--thm-b-font);
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .feature-six__content__text {
    max-width: 550px;
  }
}

@media (min-width: 1200px) {
  .feature-six__content__text {
    margin-bottom: 40px;
  }
}

.feature-six__list li {
  position: relative;
  padding-left: 30px;
  font-size: 16px;
  color: var(--thm-black);
  font-family: var(--thm-b-font);
  line-height: 1.6em;
  margin-bottom: 13px;
}

.feature-six__list li:last-child {
  margin-bottom: 0;
}

.feature-six__list li>i {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--thm-base);
  font-size: 20px;
}

/* feature seven */
.feature-seven {
  padding-top: 90px;
}

@media (min-width: 992px) {
  .feature-seven {
    padding-top: 120px;
  }
}

.feature-seven hr {
  border-width: 1px;
  border-color: #e9ebee;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 1170px;
  margin-top: 60px;
}

@media (min-width: 992px) {
  .feature-seven hr {
    margin-top: 90px;
  }
}

.feature-seven-card {
  border-radius: 8px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-bottom: 30px;
}

.feature-seven-card:hover {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
}

.feature-seven-card__inner {
  overflow: hidden;
  border-radius: 8px;
  background-color: #fff;
}

.feature-seven-card__image img {
  width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
}

.feature-seven-card:hover .feature-seven-card__image img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.feature-seven-card__content {
  border-style: solid;
  border-width: 1px;
  border-color: #e9ebee;
  padding: 30px;
  padding-bottom: 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

@media (min-width: 992px) {
  .feature-seven-card__content {
    padding: 50px;
    padding-bottom: 40px;
  }
}

.feature-seven-card__title {
  color: var(--thm-black);
  text-transform: uppercase;
  margin: 0;
  font-size: 34px;
  line-height: 1;
}

.feature-seven-card__title a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-seven-card__title a:hover {
  color: var(--thm-base);
}

.feature-seven-card__text {
  margin: 0;
  font-size: 16px;
  font-family: var(--thm-b-font);
  color: var(--thm-text);
  line-height: 34px;
  margin-top: 10px;
}

@media (min-width: 992px) {
  .feature-seven-card__text {
    margin-top: 25px;
  }
}

/* feature eight */
.feature-eight {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media (min-width: 992px) {
  .feature-eight {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.feature-eight__image img {
  max-width: 100%;
  border-radius: 8px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  background-color: #1c1e22;
  display: block;
  margin-left: auto;
  margin-right: auto;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media (min-width: 992px) {
  .feature-eight__image img {
    border-radius: 8px;
    max-width: none;
  }
}

.feature-eight__content {
  background-color: #fff;
  padding: 50px;
  border-radius: 8px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.07);
  position: relative;
  z-index: 10;
}

@media (min-width: 992px) {
  .feature-eight__content {
    margin-top: 60px;
    border-radius: 8px;
  }
}

@media (min-width: 1200px) {
  .feature-eight__content {
    padding: 100px;
  }
}

.feature-eight__text {
  margin-bottom: 35px;
}

.feature-eight .progress-box {
  position: relative;
  display: block;
  width: 100%;
  font-family: var(--thm-font);
}

.feature-eight .progress-box .bar-title {
  position: relative;
  font-size: 20px;
  line-height: 1.1em;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  color: var(--thm-black);
}

.feature-eight .progress-box .bar {
  position: relative;
  width: 100%;
  height: 7px;
  background: #e9ebee;
  border-radius: 4px;
}

.feature-eight .progress-box .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 7px;
  background: var(--thm-base);
  border-radius: 4px;
  -webkit-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.feature-eight .progress-box .count-box {
  position: absolute;
  right: 0px;
  margin-right: -40px;
  bottom: 18px;
  width: 40px;
  height: 22px;
  background: var(--thm-black);
  color: #ffffff;
  line-height: 22px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.feature-eight .progress-box .counted .count-box {
  opacity: 1;
}

.feature-eight .progress-box .count-box:after {
  content: "";
  position: absolute;
  left: 0;
  top: 100%;
  margin-top: -5px;
  border: 5px solid transparent;
  border-top: 5px solid var(--thm-black);
  border-left: 5px solid var(--thm-black);
}

.feature-eight__content .sec-title {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .feature-eight__content .sec-title {
    margin-bottom: 35px;
  }
}

/***

====================================================================
32.	We DO Section
====================================================================

***/
.get-quote-section {
  position: relative;
  padding: 120px 0px 90px;
  border-bottom: 1px solid #e9ebee;
}

.get-quote-section .left-col {
  position: relative;
  margin-bottom: 30px;
}

.get-quote-section .left-col .inner {
  position: relative;
  display: block;
  padding-right: 70px;
}

.get-quote-section .featured-block {
  position: relative;
  display: block;
  padding-left: 320px;
  min-height: 186px;
  margin-bottom: 55px;
}

.get-quote-section .featured-block .image {
  position: absolute;
  left: 0;
  top: 0;
  width: 270px;
}

.get-quote-section .featured-block .image img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  border-radius: 7px;
}

.get-quote-section .featured-block h4 {
  font-size: 40px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.get-quote-section .featured-block .text {
  position: relative;
  display: block;
  margin-bottom: 32px;
}

.get-quote-section .counter {
  position: relative;
  display: block;
  padding-top: 55px;
  border-top: 1px solid #e9ebee;
}

.get-quote-section .counter .counter-text {
  position: relative;
  float: left;
}

.get-quote-section .counter .counter-image {
  position: relative;
  float: right;
  padding-left: 55px;
}

.get-quote-section .counter .counter-image:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 14px;
  height: 100%;
  background: var(--thm-base);
  border-radius: 7px;
}

.get-quote-section .counter .counter-image img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  border-radius: 7px;
}

.get-quote-section .counter .count-box {
  position: relative;
  display: block;
  font-size: 60px;
  text-transform: uppercase;
  color: var(--thm-black);
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0.02em;
  font-family: var(--thm-font);
}

.get-quote-section .counter .counter-title {
  position: relative;
  display: block;
  font-size: 20px;
  text-transform: uppercase;
  color: var(--thm-text);
  font-weight: 400;
  line-height: 1.25em;
  letter-spacing: 0.05em;
  font-family: var(--thm-font);
}

.get-quote-section .right-col {
  position: relative;
  margin-bottom: 30px;
}

.get-quote-section .right-col .inner {
  position: relative;
  display: block;
}

.get-quote-section .form-box {
  position: relative;
  display: block;
  text-align: center;
  background: #ffffff;
  padding: 50px 45px 40px;
  margin-top: -180px;
  border-radius: 7px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 70px 0px rgba(0, 0, 0, 0.1);
  z-index: 5;
}

.get-quote-section .form-box h4 {
  color: var(--thm-black);
  font-size: 40px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 25px;
}

.get-quote-section .form-box h4 span {
  padding-left: 3px;
  color: var(--thm-base);
}

.get-quote-section .default-form .form-group {
  margin-bottom: 10px;
}

.get-quote-section .default-form .theme-btn {
  display: block;
  width: 100%;
}

/***

====================================================================
33.		Call Section
====================================================================

***/
.fluid-section {
  position: relative;
  padding: 0;
}

.fluid-section .row {
  margin: 0;
}

.fluid-section .column {
  padding: 0;
}

.fluid-section .column .inner {
  position: relative;
  display: block;
  text-align: center;
  min-height: 100%;
  padding: 120px 20px;
  background: #1c1e22;
}

.fluid-section .column:nth-child(2) .inner {
  background: var(--thm-base);
}

.fluid-section .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.1;
}

.fluid-section .content-box {
  position: relative;
  max-width: 620px;
  margin: 0 auto;
}

.fluid-section h3 {
  position: relative;
  display: block;
  font-size: 60px;
  line-height: 1.1em;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 400;
  margin: 0px 0px;
  z-index: 3;
}

.fluid-section .column:nth-child(2) h3 {
  color: var(--thm-black);
}

.fluid-section .link-box {
  position: relative;
  padding-top: 25px;
  z-index: 3;
}

/***

====================================================================
34.		Call Section
====================================================================

***/
.call-to-section-two {
  position: relative;
  padding: 120px 0px 90px;
  background-color: #2a2c30;
  background-image: url(../assets/images/icons/cta-bg-1-1.png);
  color: #ffffff;
  z-index: 1;
}

.call-to-section-two.alternate {
  background: var(--thm-base);
  background-image: url(../assets/images/background/cta-2-bg-1-1.png);
}

.call-to-section-two .inner {
  position: relative;
  display: block;
}

.call-to-section-two h2 {
  position: relative;
  float: left;
  display: block;
  font-size: 70px;
  line-height: 0.85em;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 400;
  margin: 0px 0px 25px;
  z-index: 1;
}

.call-to-section-two.alternate h2 {
  color: var(--thm-black);
}

.call-to-section-two .link-box {
  position: relative;
  float: right;
  padding-top: 25px;
  z-index: 1;
}

/* call to section three */
.call-to-section-three {
  background-color: var(--thm-base);
  padding-top: 119px;
  padding-bottom: 119px;
}

.call-to-section-three .jarallax-img {
  mix-blend-mode: luminosity;
  opacity: 0.1;
}

.call-to-section-three .auto-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.call-to-section-three .auto-container h3 {
  margin: 0;
  font-size: 50px;
  font-weight: 800;
  color: #fff;
}

.call-to-section-three .auto-container .btn-style-three {
  min-width: 220px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  background-color: #fff;
  color: var(--thm-black);
}

.call-to-section-three .auto-container .btn-style-three:hover {
  background-color: var(--thm-black);
  color: #fff;
}

/***

====================================================================
35.	Services Section
====================================================================

***/
.services-section-three {
  position: relative;
  padding: 24px 0px 0px;
  background: #ffffff;
}

.services-section-three.padd-top {
  padding-top: 120px;
}

.services-section-three .services {
  position: relative;
}

.services-section-three__one-page {
  position: relative;
  padding: 120px 0px 90px;
  background-color: var(--thm-black);
  background-image: url(../assets/images/background/contact-bg-1-1.png);
  background-size: cover;
  color: #999b9f;
}

.services-section-three__one-page .sec-title h2 {
  color: #fff;
}

.services-section-three__one-page .service-block-two .inner-box {
  overflow: hidden;
  background-color: #1c1e22;
}

.services-section-three__one-page .service-block-two .inner-box h5 a {
  color: #fff;
}

.services-section-three__one-page .service-block-two .inner-box button {
  color: #fff;
}

.services-section-three__one-page .service-block-two .inner-box .bottom-curve,
.services-section-three__one-page .service-block-two:hover .inner-box h5 a,
.services-section-three__one-page .service-block-two:hover .inner-box .icon-box,
.services-section-three__one-page .service-block-two:hover .text {
  color: var(--thm-black);
}

.services-section-three__one-page .service-block-two .link-box {
  opacity: 0;
  -webkit-transition: 500ms;
  transition: 500ms;
}

.services-section-three__one-page .service-block-two:hover .link-box {
  opacity: 1;
}

.services-section-three__one-page .service-block-two .inner-box:hover::before {
  background-color: var(--thm-base);
}

/***

====================================================================
36.	Discover Section
====================================================================

***/
.discover-section {
  position: relative;
  padding: 120px 0px 90px;
  background: #ffffff;
}

.discover-block {
  position: relative;
  margin-bottom: 30px;
}

.discover-block .inner-box {
  position: relative;
  display: block;
  padding-left: 30px;
}

.discover-block .image-box {
  position: relative;
  display: block;
  overflow: hidden;
}

.discover-block .image-box img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 7px;
}

.discover-block .image-box:hover img {
  -webkit-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%);
}

.discover-block .cap-box {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  max-width: 330px;
}

.discover-block .cap-inner {
  position: relative;
  display: block;
  padding: 40px 80px 36px 40px;
  background: var(--thm-base);
  border-radius: 7px;
}

.discover-block .cap-inner h5 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--thm-black);
  line-height: 1em;
  margin: 0;
}

.discover-block .cap-inner .more-link {
  position: absolute;
  right: 20px;
  bottom: 20px;
  width: 50px;
  height: 50px;
}

.discover-block .cap-inner .more-link a {
  position: relative;
  display: block;
  width: 50px;
  height: 50px;
  font-size: 16px;
  background: var(--thm-black);
  color: #ffffff;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
}

.discover-block .cap-inner .more-link a:hover {
  opacity: 0.5;
}

/***

====================================================================
37.	Parallax Section
====================================================================

***/
.features-section-two {
  position: relative;
  padding: 0px 0px;
  background: #ffffff;
}

.features-section-two .content-container {
  position: relative;
  display: block;
  padding: 120px 0px 90px;
}

.features-section-two .content-container:before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: -2000px;
  background: #ffffff url(../assets/images/background/pattern-2.png) right top repeat;
  border-radius: 0px 7px 7px 0px;
}

.features-section-two .left-col {
  position: relative;
  margin-bottom: 30px;
}

.features-section-two .left-col .inner {
  position: relative;
  display: block;
  padding-right: 40px;
}

.features-section-two .sec-title {
  margin-bottom: 40px;
}

.features-section-two .features {
  position: relative;
}

.features-section-two .feature {
  position: relative;
  display: block;
  padding: 0px 0px 40px;
  margin-bottom: 47px;
  padding-left: 90px;
  min-height: 100px;
  border-bottom: 1px solid #e9ebee;
}

.features-section-two .feature:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}

.features-section-two .feature .count {
  position: absolute;
  left: 0;
  top: 0px;
  width: 60px;
  height: 60px;
  text-align: center;
  background: var(--thm-base);
  padding: 10px;
  line-height: 40px;
  font-size: 30px;
  letter-spacing: 1px;
  color: var(--thm-black);
  text-transform: uppercase;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1em;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  font-family: var(--thm-font);
}

.features-section-two .feature:hover .count {
  background-color: var(--thm-black);
  color: #fff;
}

.features-section-two .feature .count span {
  line-height: 1em;
  position: relative;
  top: 2px;
}

.features-section-two .feature h5 {
  font-weight: 400;
  font-size: 24px;
  margin: 0;
  line-height: 1.2em;
  text-transform: uppercase;
  margin-bottom: 15px;
}

.features-section-two .feature .sub-text {
  display: block;
  position: relative;
  top: 0px;
  display: block;
}

.features-section-two .right-col {
  position: relative;
  margin-bottom: 30px;
}

.features-section-two .right-col .inner {
  position: relative;
  display: block;
}

.features-section-two .right-col .image-box {
  position: relative;
  display: block;
}

.features-section-two .right-col .image-box img {
  position: relative;
  display: block;
  width: auto;
  max-width: none;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  border-radius: 7px;
}

.features-section-two .right-col .image-box:hover img {
  -webkit-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%);
}

.features-section-two .right-col .cap-box {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  max-width: 350px;
}

.features-section-two .right-col .cap-inner {
  position: relative;
  display: block;
  padding: 40px 60px;
  background: var(--thm-base);
  border-radius: 7px;
}

.features-section-two .right-col .cap-inner h5 {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 400;
  color: var(--thm-black);
  line-height: 1em;
  margin: 0;
}

/***

====================================================================
38.		We Work Section
====================================================================

***/
.we-work-section {
  position: relative;
  padding: 120px 0 80px;
}

.work-tabs {
  position: relative;
}

.work-tabs .tab-buttons {
  position: relative;
  display: block;
  background: #f4f5f8;
  border-radius: 7px;
  text-align: center;
  margin-bottom: 50px;
  overflow: hidden;
}

.work-tabs .tab-buttons .tab-btn {
  position: relative;
  float: left;
  width: 33.333%;
  line-height: 40px;
  font-size: 30px;
  font-weight: 400;
  letter-spacing: 0.01em;
  color: var(--thm-black);
  padding: 30px 15px;
  text-transform: uppercase;
  margin: 0;
  font-family: var(--thm-font);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.work-tabs .tab-buttons .tab-btn span {
  position: relative;
  display: block;
  z-index: 1;
}

.work-tabs .tab-buttons .tab-btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0px;
  background: var(--thm-black);
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.work-tabs .tab-buttons .tab-btn.active-btn:before {
  height: 100%;
}

.work-tabs .tab-buttons .tab-btn.active-btn {
  color: #ffffff;
}

.work-tabs .tabs-content {
  position: relative;
}

.work-tabs .image-col {
  position: relative;
  margin-bottom: 30px;
}

.work-tabs .image-col .inner {
  position: relative;
  display: block;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.work-tabs .tabs-content .image {
  position: relative;
  display: block;
  border-radius: 7px;
}

.work-tabs .tabs-content .image img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  border-radius: 7px;
}

.work-tabs .text-col {
  position: relative;
  margin-bottom: 30px;
}

.work-tabs .text-col .inner {
  position: relative;
  display: block;
  padding-left: 30px;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(50px);
  transform: translateY(50px);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.work-tabs .active-tab .text-col .inner,
.work-tabs .active-tab .image-col .inner {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
}

.work-tabs .tabs-content .text {
  position: relative;
}

.work-tabs .tabs-content .text p {
  position: relative;
  margin-bottom: 30px;
}

.work-tabs .tabs-content .text ul {
  position: relative;
}

.work-tabs .tabs-content .text ul li {
  position: relative;
  line-height: 2.125em;
  margin-bottom: 8px;
  padding-left: 30px;
  font-weight: 400;
}

.work-tabs .tabs-content .text ul li:before {
  content: "\f131";
  font-family: "Flaticon";
  position: absolute;
  left: 0;
  top: -1px;
  color: var(--thm-base);
  font-weight: 400;
  font-size: 18px;
}

/***

====================================================================
39.	Testimonials Section
====================================================================

***/
.testimonials-section-two {
  position: relative;
  padding: 120px 0px;
  background: #1c1e22;
  color: #ffffff;
  z-index: 1;
}

.testimonials-section-two .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.3;
}

.testimonials-section-two .carousel-box {
  position: relative;
  padding-right: 200px;
}

.testi-block-two {
  position: relative;
}

.testi-block-two .inner {
  position: relative;
  display: block;
}

.testi-block-two .icon {
  position: relative;
  top: 35px;
  display: block;
  font-weight: 400;
  font-size: 200px;
  line-height: 0.7em;
  color: var(--thm-base);
  margin-bottom: 0px;
}

.testi-block-two .info {
  position: relative;
  margin-top: 50px;
}

.testi-block-two .info .name {
  display: block;
  font-size: 24px;
  line-height: 1.2em;
  color: var(--thm-base);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-family: var(--thm-font);
}

.testi-block-two .text {
  position: relative;
  display: block;
  font-size: 42px;
  line-height: 1.381em;
  font-weight: 400;
  letter-spacing: 0em;
  color: #ffffff;
  font-family: var(--thm-b-font);
  padding: 0px 0px;
}

.testimonials-section-two .owl-theme .owl-dots {
  display: none;
}

.testimonials-section-two .tns-controls {
  position: absolute;
  right: -170px;
  top: 50%;
  margin-top: -50px;
  height: 110px;
  text-align: right;
}

.testimonials-section-two .tns-controls .tns-next,
.testimonials-section-two .tns-controls .tns-prev {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background: #ffffff !important;
  color: var(--thm-black);
  padding: 0;
  margin: 0;
  line-height: 50px;
  text-align: center;
  opacity: 0.2;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonials-section-two .tns-controls .tns-next {
  top: auto;
  bottom: 0;
}

.testimonials-section-two .tns-controls .tns-next span,
.testimonials-section-two .tns-controls .tns-prev span {
  vertical-align: middle;
}

.testimonials-section-two .tns-controls .tns-next:hover,
.testimonials-section-two .tns-controls .tns-prev:hover {
  opacity: 1;
}

/***

====================================================================
40.	We DO Section
====================================================================

***/
.about-section-two {
  position: relative;
  padding: 120px 0px 90px;
}

.about-section-two .left-col {
  position: relative;
}

.about-section-two .left-col .inner {
  position: relative;
  display: block;
  padding-right: 50px;
}

.about-section-two .counter {
  position: relative;
  display: block;
}

.about-section-two .right-col {
  position: relative;
  margin-bottom: 30px;
}

.about-section-two .right-col .inner {
  position: relative;
  display: block;
  padding-left: 30px;
}

.about-section-two .image-box {
  position: relative;
  display: block;
  margin-top: -240px;
  z-index: 5;
}

.about-section-two .image-box .image {
  position: relative;
  display: block;
}

.about-section-two .image-box .image img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  border-radius: 7px;
}

.about-section-two .image-box .since {
  position: absolute;
  right: 50px;
  bottom: 50px;
}

.about-section-two .image-box .since:before {
  content: "";
  position: absolute;
  left: -42px;
  top: 4px;
  bottom: 4px;
  border-left: 2px solid var(--thm-base);
}

.about-section-two .image-box .since .txt {
  position: relative;
  display: block;
  padding: 21px 5px;
  width: 90px;
  height: 90px;
  line-height: 24px;
  text-align: center;
  background: #ffffff;
  text-transform: uppercase;
  color: var(--thm-black);
  border-radius: 50%;
}

.counter-block {
  position: relative;
  margin-bottom: 30px;
}

.counter-block .inner-box {
  position: relative;
  padding-left: 160px;
  min-height: 140px;
  padding-top: 42px;
}

.counter-block .graph-outer {
  position: absolute;
  left: 0;
  top: 0;
  width: 140px;
  height: 140px;
  display: block;
  text-align: center;
}

.counter-block .graph-outer canvas {
  position: relative;
}

.counter-block .graph-outer .count-box {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  font-size: 30px;
  color: var(--thm-black);
  font-weight: 400;
  line-height: 60px;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin-top: -30px;
  font-family: var(--thm-font);
}

.counter-block .graph-outer .count-box .sign {
  font-size: 20px;
}

.counter-block input {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  left: 0;
  top: 0;
}

.counter-block h4 {
  position: relative;
  line-height: 1.1em;
  font-size: 24px;
  color: var(--thm-black);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 0 0;
}

/* about section three */
.about-section-three {
  padding-top: 120px;
  padding-bottom: 120px;
}

.about-section-three__image {
  position: relative;
  display: inline-block;
}

.about-section-three__image img {
  position: relative;
}

.about-section-three__image::before {
  content: "";
  position: absolute;
  top: 30px;
  bottom: 30px;
  right: 0;
  left: -30px;
  background-color: var(--thm-base);
}

.about-section-three__content .sec-title-two {
  margin-bottom: 35px;
}

.about-section-three__content .sec-title-two h2 {
  margin-top: 10px;
}

.about-section-three__summery p {
  margin: 0;
  font-size: 18px;
  line-height: 2;
  font-weight: 500;
  color: #697280;
  margin-bottom: 30px;
}

.about-section-three__list {
  margin-bottom: 50px;
}

.about-section-three__list li {
  position: relative;
  padding-left: 30px;
  color: #697280;
  font-size: 18px;
  font-weight: 500;
  line-height: 2;
}

.about-section-three__list li>i {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--thm-base);
  font-size: 16px;
}

.about-section-three__content .progress-box {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 50px;
}

.about-section-three__content .progress-box .bar-title {
  position: relative;
  font-size: 18px;
  line-height: 1.1em;
  margin-bottom: 10px;
  font-weight: 600;
  color: var(--thm-black);
}

.about-section-three__content .progress-box .bar {
  position: relative;
  width: 100%;
  height: 7px;
  background: #eff1f4;
  border-radius: 4px;
}

.about-section-three__content .progress-box .bar-inner {
  position: relative;
  display: block;
  width: 0px;
  height: 7px;
  background: var(--thm-base);
  -webkit-transition: all 1500ms ease;
  transition: all 1500ms ease;
}

.about-section-three__content .progress-box .count-box {
  position: absolute;
  right: 0px;
  margin-right: -40px;
  bottom: 18px;
  width: 40px;
  height: 22px;
  color: #697280;
  line-height: 22px;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  opacity: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.about-section-three__content .progress-box .counted .count-box {
  opacity: 1;
}

.about-section-three__name {
  position: relative;
  padding-top: 15px;
}

.about-section-three__name>img {
  position: absolute;
  top: 0;
  left: 0;
}

.about-section-three__name h3 {
  margin: 0;
  font-size: 20px;
  color: var(--thm-black);
  font-weight: bold;
  line-height: 1;
  margin-bottom: 5px;
  position: relative;
}

.about-section-three__name p {
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.1;
  font-weight: 600;
  font-size: 14px;
  color: var(--thm-base);
  position: relative;
}

/* about me */
.about-me-one {
  padding-top: 90px;
  padding-bottom: 90px;
}

@media (min-width: 1200px) {
  .about-me-one {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.about-me-one__image img {
  border-radius: 8px;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  max-width: 100%;
  margin-bottom: 50px;
}

@media (min-width: 1200px) {
  .about-me-one__image img {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .about-me-one__content {
    padding-left: 30px;
  }
}

@media (min-width: 1440px) {
  .about-me-one__content {
    padding-left: 70px;
  }
}

.about-me-one__content .sec-title {
  margin-bottom: 0;
}

.about-me-one__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .about-me-one__social {
    margin-top: 25px;
    margin-bottom: 40px;
  }
}

.about-me-one__social a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 44px;
  height: 44px;
  background-color: #f4f5f8;
  color: var(--thm-black);
  font-size: 16px;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.about-me-one__social a+a {
  margin-left: 10px;
}

.about-me-one__social a:hover {
  background-color: var(--thm-base);
  color: var(--thm-black);
}

.about-me-one__certificate-row {
  margin-left: -10px;
  margin-right: -10px;
  margin-top: 30px;
  max-width: 500px;
}

@media (min-width: 1200px) {
  .about-me-one__certificate-row {
    margin-top: 70px;
  }
}

.about-me-one__certificate-row [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.about-me-one__certificate-row img {
  max-width: 100%;
  border-radius: 8px;
  mix-blend-mode: luminosity;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .about-me-one__certificate-row img {
    margin-bottom: 0;
  }
}

.about-me-one__qoute {
  color: var(--thm-base);
  font-size: 30px;
  line-height: 34px;
  margin: 0;
  font-weight: 400;
}

@media (min-width: 1200px) {
  .about-me-one__qoute {
    margin-top: 65px;
  }
}

.about-me-one__text {
  margin: 0;
  font-size: 16px;
  line-height: 34px;
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .about-me-one__text {
    margin-bottom: 35px;
  }
}

/* about me progress */
.about-me-counter {
  background-color: #f4f5f8;
  padding-top: 90px;
  padding-bottom: 60px;
}

@media (min-width: 992px) {
  .about-me-counter {
    padding-top: 120px;
    padding-bottom: 90px;
  }
}

@media (min-width: 1200px) {
  [class*="col-"]:nth-child(3) .counter-block {
    margin-left: 20px;
  }
}

.about-me-counter .counter-block h4 {
  font-size: 26px;
}

.about-me-counter .sec-title {
  text-align: center;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .about-me-counter .sec-title {
    margin-bottom: auto;
    width: 100%;
    text-align: left;
    margin-top: auto;
  }
}

/***

====================================================================
41.	Get Quote
====================================================================

***/
.get-quote-two {
  position: relative;
  background-color: var(--thm-white);
  background-size: cover;
  color: #686868;
}

.get-quote-two .sec-title {
  margin-bottom: 35px;
}

.get-quote-two .sec-title h2 {
  color: #222429;
}

.get-quote-two .left-col {
  position: relative;
  margin-bottom: 30px;
}

.get-quote-two .left-col .inner {
  position: relative;
  display: block;
  max-width: 480px;
}

.get-quote-two .left-col .text {
  position: relative;
  display: block;
  color: #222429;
  margin-bottom: 35px;
}

.get-quote-two .info {
  position: relative;
  display: block;
}

.get-quote-two .form-box form .form-group input,
.get-quote-two .form-box form .form-group select,
.get-quote-two .form-box form .form-group textarea,
.get-quote-two .form-group .ui-selectmenu-button.ui-button {
  background-color: #f4f5f8;
  border-color: #f4f5f8;
}

.get-quote-two .info ul li {
  position: relative;
  display: block;
  min-height: 60px;
  padding-left: 85px;
  line-height: 28px;
  padding-top: 5px;
  margin-bottom: 30px;
}

.get-quote-two .info ul li strong {
  display: block;
  color: #222429;
  letter-spacing: 0.1em;
  font-size: 16px;
  font-weight: 400;
  text-transform: uppercase;
  font-family: var(--thm-font);
  margin-top: -4px;
}

.get-quote-two .info ul li .icon {
  position: absolute;
  left: 0;
  top: 0;
  line-height: 30px;
  padding: 15px 10px;
  width: 60px;
  height: 60px;
  text-align: center;
  background-color: var(--thm-base);
  border-radius: 50%;
  font-size: 16px;
  color: var(--thm-black);
}

.get-quote-two .info ul li:last-child {
  margin: 0;
}

.get-quote-two .info ul li a {
  position: relative;
  color: #7a7a7a;
  line-height: 28px;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.get-quote-two .info ul li a:hover {
  color: #BDC0C3;
  text-decoration: underline;
  -webkit-text-decoration-color: var(--thm-base);
  text-decoration-color: var(--thm-base);
}

.get-quote-two .right-col {
  position: relative;
  margin-bottom: 10px;
}

.get-quote-two .right-col .inner {
  position: relative;
  display: block;
}

.get-quote-two .form-box {
  position: relative;
  display: block;
}

.get-quote-two .form-box form .row {
  margin: 0 -10px;
}

.get-quote-two .form-box form .form-group {
  padding: 0px 10px;
  margin-bottom: 20px;
}

/***

====================================================================
42.	Map Section
====================================================================

***/
.map-section {
  position: relative;
  padding: 0px 0px 120px;
}

.map-section .map-container {
  position: relative;
  display: block;
  width: 100%;
  margin-top: -120px;
  border-radius: 7px;
  overflow: hidden;
  z-index: 3;
}

.map-section__one-page {
  padding-bottom: 0;
}

.map-section__one-page .map-container {
  margin-top: 0;
}

.map-canvas,
.map-iframe {
  position: relative;
  display: block;
  width: 100%;
  height: 450px;
}

.map-section__one-page .map-iframe {
  height: 575px;
}

.map-data {
  font-family: var(--thm-font);
  text-align: center;
  font-size: 20px;
  color: #222222;
  font-weight: 300;
  letter-spacing: 0.05em;
  line-height: 1.7em;
}

.map-data h6 {
  font-family: var(--thm-font);
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
  line-height: 1em;
  color: #222222;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

/***

====================================================================
43.	Sponsors Section
====================================================================

***/
.sponsors-section-two {
  position: relative;
  padding: 120px 0px 90px;
  background: #ffffff;
  border-top: 1px solid #e9ebee;
}

.sponsors-section-two .title-col {
  position: relative;
  margin-bottom: 30px;
}

.sponsors-section-two .sec-title {
  margin-bottom: 0;
}

.sponsors-section-two .logo-col {
  position: relative;
}

.sponsors-section-two .logo-block {
  position: relative;
  margin-bottom: 30px;
}

.sponsors-section-two .image-box img {
  max-width: 100%;
  width: auto;
  display: inline-block;
  transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  opacity: 0.2;
}

.sponsors-section-two .image-box img:hover {
  opacity: 1;
}

/* sponsors section three */
.sponsors-section-three {
  padding-top: 120px;
  padding-bottom: 120px;
}

.sponsors-section-three .thm-swiper__slider .swiper-slide img {
  opacity: 0.2;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  display: block;
}

.sponsors-section-three .thm-swiper__slider .swiper-slide img:hover {
  opacity: 0.6;
}

.sponsors-section__about-two {
  background-color: var(--thm-base);
}

/***

====================================================================
44.		Page Banner
====================================================================

***/
.page-banner {
  position: relative;
  padding: 0;
  color: #ffffff;
  background: var(--thm-black);
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-banner .image-layer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
}

.page-banner .shape-1 {
  position: absolute;
  right: 0;
  top: 0;
  width: 250px;
  max-width: 100%;
  height: 100%;
  background: url(../assets/images/background/b-shape-1.png) right bottom no-repeat;
  z-index: 1;
}

.page-banner .shape-2 {
  position: absolute;
  right: 0;
  top: 0;
  width: 250px;
  max-width: 100%;
  height: 100%;
  background: url(../assets/images/background/b-shape-2.png) right top no-repeat;
  z-index: 1;
}

.page-banner .banner-inner {
  position: relative;
  display: block;
  text-align: center;
  z-index: 3;
}

.page-banner .inner-container {
  position: relative;
  padding: 240px 0px 120px;
}

.page-banner h1 {
  position: relative;
  font-size: 100px;
  color: #ffffff;
  line-height: 0.9em;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0;
}

.page-banner .page-nav {
  position: relative;
  padding-top: 5px;
  text-align: center;
}

.page-banner .bread-crumb {
  position: relative;
  display: inline-block;
}

.page-banner .bread-crumb li {
  position: relative;
  float: left;
  font-size: 20px;
  line-height: 30px;
  color: #ccd6df;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: default;
  padding-right: 15px;
  margin-right: 15px;
  letter-spacing: 0.1em;
  font-family: var(--thm-font);
}

.page-banner .bread-crumb li:before {
  position: absolute;
  right: -15px;
  width: 30px;
  text-align: center;
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  content: "-";
}

.page-banner .bread-crumb li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.page-banner .bread-crumb li:last-child:before {
  display: none;
}

.page-banner .bread-crumb li a {
  color: #ffffff;
  font-weight: 400;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.page-banner .bread-crumb li a:hover,
.page-banner .bread-crumb li.active {
  color: var(--thm-base);
}

/***

====================================================================
45.	Faqs Section
====================================================================

***/
.faqs-section {
  position: relative;
  padding: 120px 0px 100px;
  background: #ffffff url(../assets/images/background/pattern-2.png) left top repeat;
  overflow: hidden;
}

.faqs-section .faq-block {
  position: relative;
  margin-bottom: 20px;
}

/***

====================================================================
46.	Get Quote
====================================================================

***/
.get-quote-three {
  position: relative;
  padding: 120px 0px 100px;
  background: #ffffff;
}

.get-quote-three .sec-title {
  margin-bottom: 40px;
}

.get-quote-three .form-box {
  position: relative;
  display: block;
  max-width: 770px;
  margin: 0 auto;
  text-align: center;
}

.get-quote-three .form-box form .row {
  margin: 0 -10px;
}

.get-quote-three .form-box form .form-group {
  padding: 0px 10px;
  margin-bottom: 20px;
}

/***

====================================================================
47.	Error Section
====================================================================

***/
.error-section {
  position: relative;
  background: #ffffff;
  padding: 120px 0px;
}

.error-section .content {
  position: relative;
  max-width: 700px;
  text-align: center;
  margin: 0 auto;
}

.error-section .big-text {
  position: relative;
  line-height: 0.7em;
  font-size: 340px;
  color: var(--thm-base);
  font-weight: 400;
  letter-spacing: 15px;
  line-height: 1em;
}

.error-section .big-text .front {
  position: relative;
  display: block;
  width: 100%;
  text-align: center;
}

.error-section .big-text .back {
  position: absolute;
  padding-left: 20px;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  text-align: center;
  color: rgba(var(--thm-base-rgb), 0.1);
}

.error-section h2 {
  font-weight: 400;
  color: var(--thm-black);
  line-height: 1em;
  margin-bottom: 0px;
  text-transform: uppercase;
  letter-spacing: 0.005em;
}

.error-section .text {
  position: relative;
  display: block;
  line-height: 1.5em;
}

.error-section .link-box {
  position: relative;
  padding-top: 20px;
}

.error-section .link-box .theme-btn {
  position: relative;
  min-width: 200px;
}

.error-form {
  position: relative;
  max-width: 520px;
  margin: 50px auto 0;
}

.error-form form {
  position: relative;
}

.error-form .form-group {
  position: relative;
  margin: 0;
}

.error-form .form-group input[type="text"],
.error-form .form-group input[type="search"],
.error-form .form-group input[type="email"] {
  position: relative;
  width: 100%;
  line-height: 30px;
  padding: 20px 60px 20px 30px;
  height: 70px;
  display: block;
  font-size: 14px;
  background: #f4f5f8;
  color: var(--thm-text);
  border-radius: 7px;
  font-family: var(--thm-b-font);
  border: 1px solid #f4f5f8;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.error-form .form-group input[type="text"]:focus,
.error-form .form-group input[type="email"]:focus,
.error-form .form-group input[type="search"]:focus {
  border-color: var(--thm-base);
}

.error-form .form-group input::-webkit-input-placeholder {
  color: #575757;
}

.error-form .form-group .theme-btn {
  position: absolute;
  right: 25px;
  top: 15px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 20px;
  background: none;
  color: var(--thm-text);
}

.error-form .form-group .theme-btn span {
  vertical-align: middle;
}

/***

====================================================================
48.	Contact Section
====================================================================

***/
.contact-section {
  position: relative;
  background: #ffffff;
}

.career-section {
  position: relative;
  background: #ffffff;
  margin-top: 0px!important;
}

.contact-section__one-page {
  padding-bottom: 0;
}

.contact-section .map-box {
  position: relative;
  display: block;
  margin: 0 0 0px;
  border-radius: 7px;
  overflow: hidden;
}

.contact-section .form-box {
  position: relative;
  display: block;
  max-width: 770px;
  margin: 0 auto;
  text-align: center;
}

.contact-section .form-box form .row {
  margin: 0 -10px;
}

.contact-section .form-box form .form-group {
  padding: 0px 10px;
  margin-bottom: 20px;
  text-align: left;
}

.contact-section .upper-info {
  position: relative;
  margin-bottom: 90px;
}

.contact-section .info-block {
  position: relative;
  margin-bottom: 30px;
}

.contact-section .info-block .inner-box {
  position: relative;
  display: block;
  height: 100%;
  min-height: 100%;
  padding: 45px 45px 40px;
  background: #ffffff;
  border-radius: 7px;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-section .info-block .inner-box:hover {
  background: var(--thm-black);
}

.contact-section .info-block .inner-box h5 {
  font-size: 24px;
  text-transform: uppercase;
  color: var(--thm-black);
  font-weight: 400;
  letter-spacing: 1px;
  line-height: 1.1em;
  margin: 0;
}

.contact-section .info-block .inner-box h5 a {
  color: var(--thm-black);
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.contact-section .info-block .inner-box:hover h5,
.contact-section .info-block .inner-box:hover h5 a {
  color: #ffffff;
}

.contact-section .info-block .inner-box .text {
  position: relative;
  display: block;
  color: var(--thm-text);
  padding: 30px 0px 0px;
  z-index: 1;
}

.contact-section .info-block .inner-box .text a {
  position: relative;
  color: var(--thm-text);
}

.contact-section .info-block .inner-box:hover .text,
.contact-section .info-block .inner-box:hover .text a {
  color: #999b9f;
}

.contact-section .info-block .inner-box:hover h5 a:hover,
.contact-section .info-block .inner-box:hover .text a:hover {
  text-decoration: underline;
  color: #ffffff;
}

.contact-infos {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: var(--thm-black);
}

.contact-infos .row {
  --bs-gutter-y: 20px;
}

@media (min-width: 992px) {
  .contact-infos {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}

@media (min-width: 1200px) {
  .contact-infos [class*="col-"]:not(:first-child) p {
    padding-left: 55px;
  }
}

.contact-infos p {
  margin: 0;
  font-size: 16px;
  line-height: 36px;
  color: #a4a4a4;
  font-weight: 400;
  font-family: var(--thm-font);
}

.contact-infos p br {
  display: none;
}

@media (min-width: 768px) {
  .contact-infos p br {
    display: inherit;
  }
}

.contact-infos p a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-infos p a:hover {
  color: #fff;
}

/***

====================================================================
49.	Sidebar Page Container
====================================================================

***/
.sidebar-page-container {
  position: relative;
  padding: 120px 0px 90px;
}

.sidebar-page-container .content-side {
  position: relative;
  margin-bottom: 30px;
  z-index: 1;
}

/* Sidebar */
.sidebar-page-container .sidebar-side {
  position: relative;
  margin-bottom: 30px;
}

.sidebar-page-container .sidebar {
  position: relative;
}

.sidebar .sidebar-widget {
  position: relative;
  display: block;
  margin-bottom: 30px;
}

.sidebar .sidebar-widget:last-child {
  margin-bottom: 0px;
}

.sidebar .sidebar-widget .widget-inner {
  position: relative;
  display: block;
  padding: 45px 45px;
  background: #f4f5f8;
  border-radius: 7px;
}

.sidebar-title {
  position: relative;
  margin-bottom: 20px;
}

.sidebar-title h4 {
  position: relative;
  display: inline-block;
  font-size: 24px;
  line-height: 1.1em;
  color: var(--thm-black);
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.01em;
  margin-bottom: 0px;
}

.sidebar .services ul {
  position: relative;
}

.sidebar .services ul li {
  position: relative;
  line-height: 48px;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--thm-text);
  margin-bottom: 5px;
}

.sidebar .services ul li:before {
  content: "";
  position: absolute;
  left: -20px;
  top: 0;
  right: -20px;
  bottom: 0;
  background: #ffffff;
  border-radius: 7px;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar .services ul li:after {
  content: "";
  position: absolute;
  left: -20px;
  top: 50%;
  margin-top: -12px;
  height: 24px;
  border-left: 2px solid var(--thm-base);
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar .services ul li:last-child {
  margin-bottom: 0;
}

.sidebar .services ul li a {
  position: relative;
  display: block;
  line-height: 48px;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--thm-text);
  font-family: var(--thm-b-font);
  font-weight: 400;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
}

.sidebar .services ul li:hover a,
.sidebar .services ul li.active a {
  color: var(--thm-black);
}

.sidebar .services ul li:hover:before,
.sidebar .services ul li.active:before,
.sidebar .services ul li:hover:after,
.sidebar .services ul li.active:after {
  opacity: 1;
}

.sidebar .services ul li a:after {
  position: absolute;
  right: 0;
  top: -1px;
  font-family: "Flaticon";
  content: "\f15f";
  opacity: 1;
  font-size: 10px;
  line-height: 48px;
  font-weight: 700;
  z-index: 1;
}

.sidebar .services ul li a:hover:before,
.sidebar .services ul li.active a:before {
  opacity: 1;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition: all 0.3s ease 0.1s;
  transition: all 0.3s ease 0.1s;
}

.sidebar .call-up .widget-inner {
  background: var(--thm-black);
  color: #999b9f;
}

.sidebar .call-up .sidebar-title h4 {
  color: #ffffff;
}

.sidebar .call-up .text {
  position: relative;
  display: block;
}

.sidebar .call-up .phone {
  position: relative;
  margin-top: 20px;
  color: #ffffff;
  font-size: 30px;
  line-height: 40px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: var(--thm-font);
  font-weight: 300;
}

.sidebar .call-up .phone .icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: var(--thm-base);
  font-size: 20px;
  padding-right: 15px;
}

.sidebar .call-up .phone a {
  position: relative;
  color: #ffffff;
}

.sidebar .call-up .phone a:hover {
  text-decoration: underline;
}

.sidebar .search-box .widget-inner {
  padding: 0;
  background: none;
}

.sidebar .search-box .form-group {
  position: relative;
  margin: 0px;
}

.sidebar .search-box .form-group input[type="text"],
.sidebar .search-box .form-group input[type="search"] {
  position: relative;
  line-height: 40px;
  padding: 19px 80px 19px 35px;
  background: var(--thm-base);
  color: var(--thm-black);
  display: block;
  font-size: 14px;
  width: 100%;
  height: 80px;
  border: 1px solid var(--thm-base);
  font-family: var(--thm-b-font);
  border-radius: 7px;
  transition: all 500ms ease;
  -moz-transition: all 500ms ease;
  -webkit-transition: all 500ms ease;
  -ms-transition: all 500ms ease;
  -o-transition: all 500ms ease;
}

.sidebar .search-box .form-group ::-webkit-input-placeholder {
  opacity: 1;
  color: inherit;
}

.sidebar .search-box .form-group :-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}

.sidebar .search-box .form-group ::-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}

.sidebar .search-box .form-group ::placeholder {
  opacity: 1;
  color: inherit;
}

.sidebar .search-box .form-group input:focus {
  border-color: var(--thm-black);
}

.sidebar .search-box .form-group button {
  position: absolute;
  right: 20px;
  top: 0px;
  height: 80px;
  width: 60px;
  line-height: 80px;
  display: block;
  font-size: 24px;
  color: var(--thm-black);
  background: none;
  font-weight: normal;
}

.sidebar .search-box .form-group input:focus+button,
.sidebar .search-box .form-group button:hover {
  color: #181b1f;
}

.sidebar .recent-posts .post {
  position: relative;
  font-size: 20px;
  color: var(--thm-text);
  padding: 7px 0px 0px 85px;
  min-height: 62px;
  margin-bottom: 30px;
}

.sidebar .recent-posts .post:last-child {
  margin-bottom: 0px;
}

.sidebar .recent-posts .post-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 62px;
  height: 62px;
  border-radius: 50%;
}

.sidebar .recent-posts .post-thumb img {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 50%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.sidebar .recent-posts .post:hover .post-thumb img {
  -webkit-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%);
}

.sidebar .recent-posts .text {
  font-size: 20px;
  line-height: 1.3em;
  color: var(--thm-text);
  font-weight: 300;
  text-transform: capitalize;
  margin: 0;
  letter-spacing: 0.07em;
  max-width: 170px;
}

.sidebar .recent-posts .text a {
  color: var(--thm-text);
}

.sidebar .recent-posts .text a:hover {
  color: var(--thm-black);
}

.sidebar .popular-tags .tags-list {
  padding-right: 20px;
}

.sidebar .popular-tags .tags-list li,
.sidebar .popular-tags .tags-list a {
  position: relative;
  color: var(--thm-text);
  display: inline-block;
}

.sidebar .popular-tags .tags-list a {
  position: relative;
  display: inline-block;
  line-height: 1.45em;
  text-align: center;
  color: var(--thm-text);
  text-transform: capitalize;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.sidebar .popular-tags .tags-list a:hover {
  color: var(--thm-black);
}

.sidebar .archives ul {
  position: relative;
}

.sidebar .archives ul li {
  position: relative;
  line-height: 30px;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--thm-text);
  margin-bottom: 15px;
}

.sidebar .archives ul li:last-child {
  margin-bottom: 0;
}

.sidebar .archives ul li a {
  position: relative;
  display: block;
  line-height: 30px;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--thm-text);
  font-weight: 400;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
}

.sidebar .archives ul li:hover a,
.sidebar .archives ul li.active a {
  color: var(--thm-black);
  font-weight: 400;
}

.sidebar .archives ul li a:after {
  position: absolute;
  right: 0;
  top: -1px;
  font-family: "Flaticon";
  content: "\f15f";
  opacity: 1;
  font-size: 10px;
  line-height: 30px;
  font-weight: 700;
  z-index: 1;
}

.sidebar .recent-comments .comment {
  position: relative;
  font-size: 20px;
  color: var(--thm-text);
  padding: 0px 0px 0px 70px;
  min-height: 48px;
  margin-bottom: 30px;
}

.sidebar .recent-comments .comment:last-child {
  margin-bottom: 0px;
}

.sidebar .recent-comments .comment .icon {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 48px;
  height: 48px;
  font-size: 18px;
  line-height: 48px;
  background-color: var(--thm-black);
  color: #ffffff;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar .recent-comments .comment:hover .icon {
  background: var(--thm-base);
}

.sidebar .recent-comments .comment .text {
  font-size: 20px;
  line-height: 24px;
  color: var(--thm-text);
  font-weight: 300;
  letter-spacing: 0.07em;
  text-transform: uppercase;
  margin: 0;
}

.sidebar .recent-comments .comment .text a {
  color: var(--thm-text);
}

.sidebar .recent-comments .comment .text a:hover {
  color: var(--thm-black);
}

/* Post Details */
.post-details {
  position: relative;
  margin-bottom: 30px;
}

.post-details .inner-box {
  position: relative;
  display: block;
}

.post-details .image-box {
  position: relative;
  display: block;
  overflow: hidden;
}

.post-details .image-box img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  border-radius: 7px;
}

.post-details .inner-box:hover .image-box img {
  -webkit-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%);
}

.post-details .lower-box {
  position: relative;
  padding: 30px 0px 0px;
}

.post-details .post-meta {
  position: relative;
  display: block;
}

.post-details .post-meta ul {
  position: relative;
  display: block;
}

.post-details .post-meta ul li {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 20px 5px 0px;
  font-size: 16px;
  letter-spacing: 0.07em;
  text-transform: uppercase;
}

.post-details .post-meta ul li .far,
.post-details .post-meta ul li .fa {
  position: relative;
  top: -2px;
  color: var(--thm-base);
  font-size: 14px;
  padding-right: 3px;
  vertical-align: middle;
}

.post-details .lower-box h4 {
  text-transform: uppercase;
  font-size: 40px;
  line-height: 1.1em;
  color: var(--thm-black);
  margin-bottom: 30px;
}

.post-details .lower-box h4 a {
  color: var(--thm-black);
}

.post-details .lower-box h4 a:hover {
  color: var(--thm-base);
  text-decoration: underline;
}

.post-details .lower-box .text {
  position: relative;
  display: block;
}

.post-details .lower-box .text p {
  position: relative;
  margin-bottom: 35px;
}

.post-details .info-row {
  position: relative;
  display: block;
  padding: 28px 0px 0px;
  border-top: 1px solid #e9ebee;
  font-size: 16px;
  line-height: 30px;
}

.post-details .info-row strong {
  font-weight: 300;
  letter-spacing: 0.05em;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 400;
  font-family: var(--thm-font);
  color: var(--thm-black);
}

.post-details .info-row a {
  color: var(--thm-text);
}

.post-details .info-row a:hover {
  text-decoration: underline;
}

.post-details .info-row .tags-info {
  position: relative;
  float: left;
}

.post-details .info-row .cat-info {
  position: relative;
  float: right;
}

.sidebar-page-container .post-control-two {
  position: relative;
  margin-bottom: 20px;
}

.sidebar-page-container .post-control-two .control-col {
  position: relative;
  margin-bottom: 30px;
}

.sidebar-page-container .post-control-two .control-inner {
  position: relative;
  display: block;
  padding: 55px 50px;
  background: #f4f5f8;
  height: 100%;
  min-height: 100%;
  border-radius: 7px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.sidebar-page-container .post-control-two .control-inner:hover {
  background: var(--thm-base);
}

.sidebar-page-container .post-control-two .over-link {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.sidebar-page-container .post-control-two .control-inner h4 {
  position: relative;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 1.1em;
  color: var(--thm-black);
  margin-bottom: 0px;
}

.sidebar-page-container .post-control-two .control-inner h4 a {
  color: var(--thm-black);
}

/***

====================================================================
50.		Comment Area
====================================================================

 ***/
.comments-title {
  position: relative;
  margin-bottom: 40px;
}

.comments-title h3 {
  font-size: 36px;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  color: var(--thm-black);
  margin: 0;
}

.comments-area {
  position: relative;
  margin-bottom: 70px;
}

.comments-area .comment-box {
  position: relative;
  margin-bottom: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid #e9ebee;
}

.comments-area .comment {
  position: relative;
  min-height: 90px;
  padding-left: 130px;
}

.comments-area .comment-box .author-thumb {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  margin-bottom: 20px;
  overflow: hidden;
}

.comments-area .comment-box .author-thumb img {
  width: 90px;
  height: 90px;
  display: block;
  border-radius: 50%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
}

.comments-area .comment-box .info {
  position: relative;
  margin-bottom: 25px;
  line-height: 24px;
}

.comments-area .comment-box .info .name {
  position: relative;
  display: block;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: var(--thm-black);
  text-transform: uppercase;
  font-family: var(--thm-font);
}

.comments-area .comment-box .info .date {
  position: relative;
  display: inline-block;
  color: var(--thm-base);
}

.comments-area .comment-box .text {
  position: relative;
  display: block;
  color: var(--thm-text);
  margin-bottom: 30px;
}

.comments-area .comment-box .reply-btn {
  position: relative;
  display: block;
}

.comments-area .comment-box .theme-btn .btn-title {
  padding: 6px 30px 4px;
  line-height: 30px;
}

/***

====================================================================
51.		Leave Comments Form
====================================================================

 ***/
.leave-comments {
  position: relative;
}

.leave-comments form .row {
  margin: 0 -10px;
}

.leave-comments form .form-group {
  padding: 0px 10px;
  margin-bottom: 20px;
}

.leave-comments form .form-group:last-child {
  margin-bottom: 0;
}

/***

====================================================================
52.	Service Details
====================================================================

***/
.service-details {
  position: relative;
}

.service-details .image {
  position: relative;
  display: block;
  overflow: hidden;
}

.service-details .image img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  border-radius: 7px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.service-details .image:hover img {
  -webkit-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%);
}

.service-details .main-image {
  margin-bottom: 35px;
}

.service-details h3 {
  font-size: 50px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.1em;
  margin-bottom: 25px;
}

.service-details h4 {
  font-size: 36px;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.1em;
  margin-bottom: 20px;
}

.service-details .text-content {
  position: relative;
  display: block;
}

.service-details .text-content p {
  margin-bottom: 35px;
}

.service-details .text-content .last {
  margin-bottom: 0px;
}

.service-details .featured {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}

.service-details .featured .image-col {
  margin-bottom: 30px;
}

.service-details .text-content ul {
  margin-bottom: 20px;
}

.service-details .text-content ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px;
}

.service-details .text-content ul li:before {
  content: "\f131";
  font-family: "Flaticon";
  position: absolute;
  left: 0;
  top: -1px;
  color: var(--thm-base);
  font-weight: 400;
  font-size: 18px;
}

/***

====================================================================
53.	Service Details
====================================================================

***/
.project-single {
  position: relative;
  padding: 120px 0px 80px;
}

.project-single .image-col {
  position: relative;
  margin-bottom: 10px;
}

.project-single .image-col .inner {
  position: relative;
  display: block;
}

.project-single .image-box {
  position: relative;
  display: block;
  border-radius: 7px;
  overflow: hidden;
  margin-bottom: 30px;
}

.project-single.style-two .image-box {
  margin-bottom: 35px;
}

.project-single .image-box img {
  display: block;
  width: 100%;
  -webkit-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  filter: grayscale(100%);
  border-radius: 7px;
  transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.project-single .image-box:hover img {
  -webkit-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  filter: grayscale(0%);
}

.project-single h5 {
  font-size: 30px;
  letter-spacing: 0.005em;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1.1em;
  margin-bottom: 20px;
}

.project-single.style-two h5 {
  font-size: 36px;
}

.project-single .text-content {
  position: relative;
  display: block;
}

.project-single .text-content p {
  margin-bottom: 25px;
}

.project-single .text-content .last {
  margin-bottom: 0px;
}

.project-single .text-content .info {
  position: relative;
}

.project-single .text-content .info li {
  position: relative;
  line-height: 26px;
  margin-bottom: 30px;
}

.project-single .text-content .info li strong {
  font-size: 24px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  font-weight: 400;
  line-height: 1em;
  font-family: var(--thm-font);
  color: var(--thm-black);
}

.post-control {
  position: relative;
}

.post-control .inner {
  position: relative;
  display: block;
  padding: 50px 0px;
  border-top: 1px solid #e9ebee;
  border-bottom: 1px solid #e9ebee;
}

.post-control .control {
  position: relative;
  float: left;
  line-height: 30px;
  font-size: 24px;
  color: var(--thm-black);
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: 400;
  font-family: var(--thm-font);
}

.post-control .control.next {
  float: right;
}

.post-control .control a {
  display: block;
  line-height: 30px;
  font-size: 24px;
  color: var(--thm-black);
  font-weight: 400;
}

.post-control .control a:hover {
  color: var(--thm-base);
}

.post-control .control .fa {
  position: relative;
  vertical-align: top;
  line-height: 30px;
  font-size: 18px;
}

/*--------------------------------------------------------------
	Side Menu
--------------------------------------------------------------*/
/*--------------------------------------------------------------
# Sidemenu
--------------------------------------------------------------*/
.side-menu__block {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: -webkit-transform 0.7s ease;
  transition: -webkit-transform 0.7s ease;
  transition: transform 0.7s ease;
  transition: transform 0.7s ease, -webkit-transform 0.7s ease;
}

.side-menu__block.active {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.side-menu__block-overlay {
  width: 100%;
  height: 100%;
  background-color: var(--thm-black);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  opacity: 0.7;
}

.side-menu__block-overlay .cursor-follower {
  background-color: rgba(8, 39, 64, 0.3);
}

.side-menu__block-inner {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  max-width: 520px;
  height: 100%;
  overflow-y: auto;
  background-color: #eceeef;
  z-index: 999999;
  padding: 40px 0;
  padding-top: 20px;
}

@media (max-width: 575px) {
  .side-menu__block-inner {
    max-width: 480px;
  }
}

@media (max-width: 480px) {
  .side-menu__block-inner {
    max-width: 400px;
  }
}

@media (max-width: 375px) {
  .side-menu__block-inner {
    max-width: 300px;
  }
}

.side-menu__block-inner .mCustomScrollBox {
  width: 100%;
}

.side-menu__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 100px;
  padding-right: 40px;
}

.mobile-nav__container {
  padding-left: 100px;
  padding-right: 100px;
  padding-top: 50px;
}

.mobile-nav__container ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobile-nav__container li.dropdown ul {
  display: none;
  padding: 10px 0px 0px;
  margin-bottom: 20px;
  padding-left: 0.5em;
}

.mobile-nav__container li.dropdown ul li {
  margin-bottom: 10px;
}

.mobile-nav__container li.dropdown {
  position: relative;
}

.mobile-nav__container li.dropdown>a {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding-right: 25px;
  vertical-align: middle;
}

.mobile-nav__container li>a>span {
  background-color: var(--thm-base);
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  width: 40px;
  top: -3px;
  position: relative;
  margin-left: 5px;
}

.mobile-nav__container li.dropdown .dropdown-btn {
  position: absolute;
  top: 0;
  right: 0;
  line-height: 22px;
  width: 22px;
  height: 22px;
  display: block;
  text-align: center;
  border: none;
  outline: none;
  background-color: transparent;
  color: #9ca3a9;
  font-size: 14px;
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mobile-nav__container li.dropdown .dropdown-btn.open {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  color: var(--thm-black);
}

.mobile-nav__container li+li {
  margin-top: 10px;
}

.mobile-nav__container li a {
  text-transform: uppercase;
  color: #9ca3a9;
  font-size: 20px;
  letter-spacing: 1px;
  line-height: 24px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-family: "Teko", sans-serif;
  position: relative;
}

.mobile-nav__container li a:hover {
  color: var(--thm-black);
}

.mobile-nav__container li.current-menu-item>a,
.mobile-nav__container li.current-menu-item .dropdown-btn,
.mobile-nav__container li.current .dropdown-btn,
.mobile-nav__container li.current>a {
  color: var(--thm-black);
}

.side-menu__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 40px;
}

.side-menu__social a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 57px;
  height: 57px;
  background-color: #fff;
  color: #9ca3a9;
  font-size: 16px;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.side-menu__social a+a {
  margin-left: 10px;
}

.side-menu__social a:hover {
  background-color: #ffaa16;
  color: var(--thm-black);
}

.side-menu__content {
  padding-left: 100px;
  padding-right: 100px;
}

.side-menu__content p {
  margin: 0;
  font-family: var(--thm-b-font);
  color: #9ca3a9;
}

.side-menu__content p+p {
  margin-top: 30px;
}

.side-menu__content p a {
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.side-menu__content p a:hover {
  color: #ffaa16;
  text-decoration: underline;
}

.side-menu__sep {
  width: calc(100% - 200px);
  margin-left: auto;
  margin-right: auto;
  height: 1px;
  background-color: #fff;
  margin-top: 70px;
  margin-bottom: 70px;
}

.side-menu__text p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #9ca3a9;
  margin: 0;
  margin-bottom: 25px;
}

.side-menu__text a {
  font-size: 20px;
  color: #ff0143;
  line-height: 30px;
  font-weight: 400;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.side-menu__text a:hover {
  color: var(--thm-base);
}

.side-menu__block__copy {
  text-transform: uppercase;
  font-size: 16px;
  color: #9ca3a9;
  font-weight: 400;
  letter-spacing: 0.2em;
}

@media (max-width: 575px) {

  .side-menu__top,
  .side-menu__content,
  .mobile-nav__container {
    padding-left: 50px;
    padding-right: 50px;
  }

  .side-menu__sep {
    margin-top: 40px;
    margin-bottom: 40px;
    width: calc(100% - 100px);
  }
}

@media (max-width: 480px) {

  .side-menu__top,
  .side-menu__content,
  .mobile-nav__container {
    padding-left: 25px;
    padding-right: 25px;
  }

  .side-menu__sep {
    width: calc(100% - 50px);
  }

  .side-menu__social {
    margin-top: 40px;
  }

  .side-menu__social a {
    width: 45px;
    height: 45px;
  }
}

/* style switcher */
.style-switcher {
  font-family: "Teko", sans-serif;
  position: fixed;
  top: 100px;
  left: 0;
  z-index: 99999;
  background-color: #fff;
  width: 200px;
  text-align: center;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding-top: 25px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.style-switcher.active {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}

.style-switcher h3 {
  margin: 0;
  text-transform: uppercase;
  color: var(--thm-black);
  font-size: 30px;
  line-height: 1;
  font-family: "Teko", sans-serif;
  margin-bottom: 20px;
}

.style-switcher .layout-feature,
.style-switcher .language-feature {
  display: -ms-grid;
  display: grid;
  grid-gap: 10px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  margin-top: 30px;
}

.style-switcher .layout-feature button,
.style-switcher .layout-feature a,
.style-switcher .language-feature button {
  border: none;
  outline: none;
  font-family: "Teko", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  background-color: var(--thm-black);
  color: #fff;
  border-radius: 5px;
}

.style-switcher .layout-feature a:last-child,
.style-switcher .layout-feature button:last-child,
.style-switcher .language-feature button.rtl-switcher {
  background-color: var(--thm-base);
  color: #fff;
}

#switcher-toggler {
  position: absolute;
  top: 20px;
  left: 100%;
  font-size: 22px;
  color: #fff;
  width: 40px;
  height: 40px;
  background-color: var(--thm-base);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  -webkit-transition: 500ms;
  transition: 500ms;
}

#switcher-toggler:hover {
  color: #fff;
  background-color: var(--thm-black);
}

#styleOptions {
  display: -ms-grid;
  display: grid;
  grid-gap: 20px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
}

#styleOptions li {
  width: 100%;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;
}

#styleOptions li a {
  display: block;
  width: 100%;
  height: 100%;
}

#styleOptions li a.color-default {
  background-color: #ffaa17;
}

#styleOptions li a.color-2 {
  background-color: #70f28b;
}

#styleOptions li a.color-3 {
  background-color: #83dcfa;
}

#styleOptions li a.color-4 {
  background-color: #ff6c6c;
}

#styleOptions li a.color-5 {
  background-color: #73a5ff;
}

#styleOptions li a.color-6 {
  background-color: #fe9759;
}

/* lang bar  */
.skiptranslate {
  display: none !important;
}

/* parallax block */
.parallax-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 100vh;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
}

.parallax-block .jarallax-img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

@media (max-height: 450px) {
  .parallax-block {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.parallax-block__content h3 {
  margin: 0;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 100px;
  max-width: 537px;
  line-height: 1;
  margin-bottom: 25px;
}

.parallax-block .auto-container {
  width: 100%;
}

@media (min-width: 1200px) {
  .parallax-block .auto-container {
    max-width: 1200px;
  }
}

/* dark mode css */
.body-dark,
.body-dark .page-wrapper.boxed-wrapper {
  background-color: #1c1e22;
}

/* about section dark */
.body-dark .why-us-section,
.body-dark .services-section-two,
.body-dark .btn-style-two,
.body-dark .testi-block .inner,
.body-dark .error-section,
.body-dark .default-form .form-group input[type="text"],
.body-dark .default-form .form-group input[type="email"],
.body-dark .default-form .form-group input[type="password"],
.body-dark .default-form .form-group input[type="tel"],
.body-dark .default-form .form-group input[type="url"],
.body-dark .default-form .form-group input[type="file"],
.body-dark .default-form .form-group input[type="number"],
.body-dark .default-form .form-group textarea,
.body-dark .default-form .form-group select,
.body-dark .get-quote-two .form-box form .form-group input,
.body-dark .get-quote-two .form-box form .form-group select,
.body-dark .get-quote-two .form-box form .form-group textarea,
.body-dark .get-quote-two .form-group .ui-selectmenu-button.ui-button,
.body-dark .get-quote-section .default-form .theme-btn:hover .btn-curve,
.body-dark .form-group .ui-selectmenu-button.ui-button,
.body-dark .discover-section,
.body-dark .services-section-three,
.body-dark .features-section-two,
.body-dark .get-quote-three,
.body-dark .sponsors-section-two,
.body-dark .contact-section,
.body-dark .search-popup__form input,
.body-dark .about-section {
  background-color: #1c1e22;
}

.body-dark .default-form .form-group input[type="text"],
.body-dark .default-form .form-group input[type="email"],
.body-dark .default-form .form-group input[type="password"],
.body-dark .default-form .form-group input[type="tel"],
.body-dark .default-form .form-group input[type="url"],
.body-dark .default-form .form-group input[type="file"],
.body-dark .default-form .form-group input[type="number"],
.body-dark .default-form .form-group textarea,
.body-dark .form-group .ui-selectmenu-button.ui-button,
.body-dark .get-quote-two .form-box form .form-group input,
.body-dark .get-quote-two .form-box form .form-group select,
.body-dark .get-quote-two .form-box form .form-group textarea,
.body-dark .get-quote-two .form-group .ui-selectmenu-button.ui-button,
.body-dark .default-form .form-group select {
  border-color: #1c1e22;
}

.body-dark .contact-section .default-form .form-group input[type="text"],
.body-dark .contact-section .default-form .form-group input[type="email"],
.body-dark .contact-section .default-form .form-group input[type="password"],
.body-dark .contact-section .default-form .form-group input[type="tel"],
.body-dark .contact-section .default-form .form-group input[type="url"],
.body-dark .contact-section .default-form .form-group input[type="file"],
.body-dark .contact-section .default-form .form-group input[type="number"],
.body-dark .contact-section .default-form .form-group textarea,
.body-dark .get-quote-three .default-form .form-group input[type="text"],
.body-dark .get-quote-three .default-form .form-group input[type="email"],
.body-dark .get-quote-three .default-form .form-group input[type="password"],
.body-dark .get-quote-three .default-form .form-group input[type="tel"],
.body-dark .get-quote-three .default-form .form-group input[type="url"],
.body-dark .get-quote-three .default-form .form-group input[type="file"],
.body-dark .get-quote-three .default-form .form-group input[type="number"],
.body-dark .comment-form.default-form .form-group input[type="text"],
.body-dark .comment-form.default-form .form-group input[type="email"],
.body-dark .comment-form.default-form .form-group input[type="password"],
.body-dark .comment-form.default-form .form-group input[type="tel"],
.body-dark .comment-form.default-form .form-group input[type="url"],
.body-dark .comment-form.default-form .form-group input[type="file"],
.body-dark .comment-form.default-form .form-group input[type="number"],
.body-dark .comment-form.default-form .form-group textarea,
.body-dark .error-form .form-group input[type="text"],
.body-dark .error-form .form-group input[type="search"],
.body-dark .error-form .form-group input[type="email"],
.body-dark .get-quote-three .default-form .form-group textarea {
  background-color: #222429;
  border-color: #222429;
}

.body-dark .why-us-section .feature:hover .inner-box::before,
.body-dark .service-block-two .inner-box,
.body-dark .work-tabs .tab-buttons,
.body-dark .get-quote-section .form-box,
.body-dark .sidebar .sidebar-widget .widget-inner,
.body-dark .news-block-three .inner-box,
.body-dark .contact-section .info-block .inner-box,
.body-dark .about-section::before {
  background-color: #222429;
}

.body-dark .project-tab .product-tab-btns .p-tab-btn.active-btn,
.body-dark .sec-title h2,
.body-dark .get-quote-section .counter .count-box,
.body-dark .get-quote-section .featured-block h4,
.body-dark .testi-block .info .name,
.body-dark .we-do-section .progress-box .bar-title,
.body-dark .accordion-box .block .acc-btn,
.body-dark .we-do-section .sec-title h2,
.body-dark .gallery-section .sec-title h2,
.body-dark .news-section .sec-title h2,
.body-dark .trusted-section .sec-title h2,
.body-dark .trusted-section .feature h5,
.body-dark .gallery-section .mixitup-gallery .filters .filter.active,
.body-dark .live-section .sec-title h2,
.body-dark .agency-section .sec-title h2,
.body-dark .service-block-two .inner-box h5,
.body-dark .service-block-two .inner-box button,
.body-dark .team-section .sec-title h2,
.body-dark .search-popup__form input,
.body-dark .why-us-section .feature h6,
.body-dark .featured-section .feature h6,
.body-dark .get-quote-section .form-box h4,
.body-dark .features-section-two .feature h5,
.body-dark .work-tabs .tab-buttons .tab-btn,
.body-dark .counter-block h4,
.body-dark .contact-section .info-block .inner-box h5,
.body-dark .counter-block .graph-outer .count-box,
.body-dark .sidebar-title h4,
.body-dark .call-to-section-two h2,
.body-dark .error-section h2,
.body-dark .service-details h3,
.body-dark .service-details h4,
.body-dark .project-single h5,
.body-dark .post-details .lower-box h4,
.body-dark .post-details .info-row strong,
.body-dark .news-block-three .inner-box h4 a,
.body-dark .post-control .control a,
.body-dark .sidebar .recent-posts .text:hover a,
.body-dark .sidebar .archives ul li:hover a,
.body-dark .sidebar .popular-tags .tags-list a:hover,
.body-dark .sidebar .archives ul li.active a,
.body-dark .news-block-two .lower-box h4 a,
.body-dark .news-block-two .lower-box .link-box a,
.body-dark .comments-title h3,
.body-dark .comments-area .comment-box .info .name,
.body-dark .about-section .sec-title h2 {
  color: #ffffff;
}

.body-dark,
.body-dark .contact-section .info-block .inner-box .text,
.body-dark .default-form .form-group input[type="text"],
.body-dark .default-form .form-group input[type="email"],
.body-dark .default-form .form-group input[type="password"],
.body-dark .default-form .form-group input[type="tel"],
.body-dark .default-form .form-group input[type="url"],
.body-dark .default-form .form-group input[type="file"],
.body-dark .default-form .form-group input[type="number"],
.body-dark .default-form .form-group textarea,
.body-dark .default-form .form-group select,
.body-dark .form-group .ui-button .ui-icon,
.body-dark .get-quote-two .form-box form .form-group input,
.body-dark .get-quote-two .form-box form .form-group select,
.body-dark .get-quote-two .form-box form .form-group textarea,
.body-dark .get-quote-two .form-group .ui-selectmenu-button.ui-button,
.body-dark .form-group .ui-selectmenu-button.ui-button,
.body-dark .get-quote-section .featured-block .text,
.body-dark .why-us-section .feature .text,
.body-dark .project-tab .product-tab-btns .p-tab-btn,
.body-dark .featured-section .feature .text,
.body-dark .sec-title .lower-text,
.body-dark .trusted-section .feature .sub-text,
.body-dark .gallery-section .mixitup-gallery .filters li,
.body-dark .accordion-box .block .content .text,
.body-dark .we-do-section .featured-block .text,
.body-dark .trusted-section .sec-title .lower-text,
.body-dark .team-section .team-block .lower-box .designation,
.body-dark .trusted-section .featured-block-two .text ul li,
.body-dark .agency-section .right-col .text,
.body-dark .agency-section .featured-block-two .text ul li,
.body-dark .news-section .news-block .lower-box .text,
.body-dark .news-section .news-block .post-meta ul li,
.body-dark .agency-section .default-tabs .tabs-content .text,
.body-dark .service-block-two .text,
.body-dark .sidebar .recent-posts .text,
.body-dark .testi-block .text,
.body-dark .comments-area .comment-box .text,
.body-dark .features-section-two .feature .sub-text,
.body-dark .sidebar .popular-tags .tags-list a,
.body-dark .about-section .text-column .text p {
  color: #999b9f;
}

.body-dark .about-section .text-column .text .since .txt {
  background-color: #ffffff;
  color: #222429;
}

.body-dark .about-section .text-column .text .since::before {
  background-color: #fff;
  opacity: 0.1;
}

.body-dark .service-block-two .inner-box {
  overflow: hidden;
}

.body-dark .service-block-two .inner-box h5 a {
  color: inherit;
}

.body-dark .service-block-two .inner-box button {
  color: inherit;
}

.body-dark .work-tabs .tab-buttons .tab-btn::before,
.body-dark .testi-block:hover .inner {
  background-color: var(--thm-base);
}

.body-dark .testi-block:hover .info .name,
.body-dark .work-tabs .tab-buttons .tab-btn.active-btn,
.body-dark .testi-block:hover .text {
  color: var(--thm-black);
}

.body-dark .news-block-two .lower-box .link-box:hover a::before {
  border-color: #fff;
}

/* live section dark */
.body-dark .live-section {
  border-color: rgba(255, 255, 255, 0.1);
  background-color: #1c1e22;
}

/* we do section dark */
.body-dark .featured-section,
.body-dark .call-to-section-two,
.body-dark .news-section.alt-bg,
.body-dark .testimonials-section,
.body-dark .gallery-section-two.alternate,
.body-dark .features-section-two .content-container::before,
.body-dark .faqs-section,
.body-dark .we-do-section {
  background-color: #222429;
  background-image: url(../assets/images/background/we-do-section-bg-dark.jpg);
}

.body-dark .we-do-section .progress-box .bar {
  background-color: rgba(255, 255, 255, 0.1);
}

/* accordion box dark */
.body-dark .accordion-box .block.active-block,
.body-dark .accordion-box .block {
  background-color: #1c1e22;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.05);
}

.body-dark .accordion-box .block .acc-btn.active {
  background-color: #1c1e22;
}

/* gallery section dark */
.body-dark .gallery-section {
  background-color: #1c1e22;
}

/* trusted section */
.body-dark .trusted-section .right-col .inner,
.body-dark .trusted-section .right-col .inner::before {
  background-color: #1c1e22;
}

.body-dark .trusted-section .feature:hover .count {
  background-color: #fff;
  color: #222429;
}

.body-dark .get-quote-section,
.body-dark .features-section-two .feature,
.body-dark .get-quote-section .counter,
.body-dark .post-control .inner,
.body-dark .comments-area .comment-box,
.body-dark .post-details .info-row,
.body-dark .sponsors-section-two,
.body-dark .trusted-section .feature {
  border-color: rgba(255, 255, 255, 0.1);
}

/* team section dark */
.body-dark .news-section .news-block .lower-box h5,
.body-dark .team-section .team-block .lower-box h5 {
  color: #fff;
}

.body-dark .news-section .news-block .lower-box h5 a,
.body-dark .team-section .team-block .lower-box h5 a {
  color: inherit;
}

.body-dark .team-section .owl-theme .owl-dots .owl-dot span {
  opacity: 0.1;
}

.body-dark .team-section .owl-theme .owl-dots .owl-dot:hover span,
.body-dark .team-section .owl-theme .owl-dots .owl-dot.active span {
  background-color: transparent;
  opacity: 1;
}

/* sponsors section */
.body-dark .sponsors-section {
  background-color: #1c1e22;
}

/* agency section dark */
.body-dark .agency-section {
  background-color: #222429;
  background-image: url(../assets/images/background/we-do-section-bg-dark.jpg);
}

.body-dark .agency-section .default-tabs .tab-buttons .tab-btn span {
  color: #fff;
}

.body-dark .agency-section .default-tabs .tab-buttons .tab-btn.active-btn span {
  color: #222429;
}

/* news section dark */
.body-dark .news-section .news-block .post-meta ul {
  -webkit-box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.03);
  background-color: #1c1e22;
}

.body-dark .news-section .news-block .lower-box .link-box a {
  background-color: rgba(255, 255, 255, 0.1);
  color: #222429;
}

.body-dark .news-section .news-block .lower-box .link-box a:hover {
  background-color: var(--thm-base);
}

/* portfolio masonary */
.portfolio-masonary {
  padding-bottom: 100px;
}

@media (min-width: 1200px) {
  .portfolio-masonary .auto-container {
    max-width: 1200px;
  }
}

.portfolio-masonary h3 {
  margin: 0;
  font-size: 80px;
  font-weight: 300;
  color: #fff;
  line-height: 1;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  max-width: 810px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 210px;
}

.portfolio-masonary hr {
  border-color: rgba(255, 255, 255, 0.1);
  margin: 0;
  margin-bottom: 60px;
  margin-top: 120px;
}

.portfolio-masonary__filter-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 50px;
}

.portfolio-masonary .filters {
  margin-bottom: 0;
}

.portfolio-masonary .filters li {
  color: #ffffff;
  font-size: 20px;
}

.portfolio-masonary .mixitup-gallery .filters .filter.active {
  color: #fff;
}

.portfolio-masonary .filters li:first-child {
  margin-left: 0;
}

.portfolio-masonary .masonary-layout {
  margin-bottom: 30px;
}

.portfolio-masonary__box {
  margin-bottom: 30px;
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}

.portfolio-masonary__box__new {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: var(--thm-base);
  border-radius: 8px;
  font-family: var(--thm-font);
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  z-index: 10;
  color: var(--thm-black);
  letter-spacing: 0.1em;
  line-height: 1;
  padding: 9px 13px 8px;
  font-size: 20px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.portfolio-masonary__box-outer:hover .portfolio-masonary__box__new {
  background-color: var(--thm-black);
  color: #fff;
}

.portfolio-masonary__box img {
  width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
}

.portfolio-masonary__box:hover img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.portfolio-masonary__more {
  text-transform: uppercase;
  font-size: 18px;
  color: #fff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-weight: 500;
  font-family: var(--thm-font);
}

.portfolio-masonary__more:hover {
  color: var(--thm-base);
}

.portfolio-masonary__box-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(var(--thm-base-rgb), 0.9);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 60px;
  -webkit-transform: translateY(30%);
  transform: translateY(30%);
  opacity: 0;
  -webkit-transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease,
    -webkit-transform 500ms ease;
}

.portfolio-masonary__box:hover .portfolio-masonary__box-content {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.portfolio-masonary__box-content p {
  margin: 0;
  color: var(--thm-black);
  line-height: 1;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
  font-family: var(--thm-font);
}

.portfolio-masonary__box-content p::after {
  width: 40px;
  height: 2px;
  background-color: var(--thm-black);
  margin-left: 10px;
  content: "";
}

.portfolio-masonary__box-content h4 {
  margin: 0;
  text-transform: uppercase;
  color: var(--thm-black);
  line-height: 1;
  font-size: 30px;
  letter-spacing: 0.1em;
}

.portfolio-masonary__box-content h4 a {
  color: inherit;
}

/* portfolio details header */
.portfolio-details-header {
  margin-top: 106px;
  background-color: #fff;
  padding-top: 90px;
  padding-bottom: 90px;
  position: relative;
}

.portfolio-details-header::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 545px;
  background-color: #f4f5f8;
}

@media (min-width: 1200px) {
  .portfolio-details-header {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.portfolio-details-header__separator {
  border-width: 1px;
  border-color: #e9ebee;
  min-width: 1600px;
  float: left;
  margin-top: 90px;
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .portfolio-details-header__separator {
    margin-top: 120px;
  }
}

.portfolio-details-header__title {
  margin: 0;
  text-transform: uppercase;
  font-size: 50px;
  color: var(--thm-black);
  margin-bottom: 40px;
}

.portfolio-details-header__image {
  display: inline-block;
  background-color: #222429;
  border-radius: 8px;
  margin-bottom: 45px;
}

.portfolio-details-header__image img {
  max-width: 100%;
  border-radius: 8px;
  display: block;
  mix-blend-mode: luminosity;
}

@media (min-width: 1200px) {
  .portfolio-details-header__image img {
    max-width: none;
  }
}

.portfolio-details-header__sub-heading {
  margin: 0;
  text-transform: uppercase;
  font-size: 24px;
  color: var(--thm-black);
  line-height: 1;
  margin-bottom: 10px;
}

.portfolio-details-header__text {
  margin: 0;
  color: var(--thm-text);
  font-size: 16px;
}

.portfolio-details-header__list {
  margin: 0;
  margin-bottom: 45px;
}

.portfolio-details-header__list li {
  font-size: 16px;
  color: var(--thm-text);
}

.portfolio-details-header__main-text {
  margin: 0;
  font-size: 20px;
  list-style: none;
  font-weight: 300;
  line-height: 42px;
  margin-bottom: 40px;
}

@media (min-width: 1200px) {
  .portfolio-details-header__main-text {
    margin-bottom: 0;
  }
}

.portfolio-details-header__text+.portfolio-details-header__sub-heading {
  margin-top: 40px;
}

@media (min-width: 1200px) {
  .portfolio-details-header__main-text {
    padding-left: 100px;
  }
}

.portfolio-details-header__link {
  color: var(--thm-base);
  font-size: 16px;
  position: relative;
  display: inline-block;
  line-height: 1;
  padding-bottom: 10px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-bottom: 50px;
}

@media (min-width: 1200px) {
  .portfolio-details-header__link {
    margin-bottom: 0;
  }
}

.portfolio-details-header__link:hover {
  color: var(--thm-black);
}

.portfolio-details-header__link::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--thm-base);
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.portfolio-details-header__link:hover::after {
  background-color: var(--thm-black);
}

.portfolio-details-header__links a {
  color: var(--thm-text);
  font-size: 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.portfolio-details-header__links a:hover {
  color: var(--thm-base);
}

.portfolio-details-header__links a:not(:last-child)::after {
  content: ",";
}

/* portfolio details info */
.portfolio-details-info__image {
  display: inline-block;
  background-color: var(--thm-black);
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 30px;
}

.portfolio-details-info__image img {
  mix-blend-mode: luminosity;
  border-radius: 8px;
  max-width: 100%;
}

@media (min-width: 768px) {
  .portfolio-details-info__image {
    margin-bottom: 0;
    display: block;
  }

  .portfolio-details-info .row {
    margin-bottom: 40px;
  }
}

@media (min-width: 1200px) {
  .portfolio-details-info .row {
    margin-bottom: 60px;
  }
}

.portfolio-details-info__title {
  margin: 0;
  text-transform: uppercase;
  color: var(--thm-black);
  font-size: 40px;
}

.portfolio-details-info__text {
  margin: 0;
  color: var(--thm-text);
  font-size: 16px;
  line-height: 34px;
  margin-bottom: 30px;
  max-width: 470px;
}

@media (min-width: 768px) {
  .portfolio-details-info__text {
    margin-bottom: 0;
  }
}

@media (min-width: 1200px) {
  .portfolio-details-info__title {
    font-size: 50px;
    margin-bottom: 35px;
  }
}

/* protfolio details video */
.portfolio-details-video {
  margin-top: 90px;
  margin-bottom: 60px;
}

@media (min-width: 1200px) {
  .portfolio-details-video {
    margin-top: 120px;
  }
}

.portfolio-details-video__thumbnail {
  background-color: var(--thm-black);
  overflow: hidden;
  border-radius: 8px;
  position: relative;
}

.portfolio-details-video img {
  mix-blend-mode: luminosity;
  max-width: 100%;
}

.portfolio-details-video .vid-link {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 84px;
  height: 84px;
  line-height: 84px;
  text-align: center;
  font-size: 20px;
  color: #ffffff;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.portfolio-details-video .vid-link .icon {
  position: relative;
  display: block;
  width: 84px;
  height: 84px;
  line-height: 84px;
  text-align: center;
  font-size: 20px;
  color: var(--thm-black);
  background: var(--thm-base);
  border-radius: 50%;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
}

.portfolio-details-video .vid-link a:hover .icon {
  background: #ffffff;
  color: var(--thm-base);
}

.portfolio-details-video .vid-link .icon:before {
  content: "";
  position: absolute;
  left: -8px;
  top: -8px;
  right: -8px;
  bottom: -8px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
}

.portfolio-details-video .vid-link .ripple,
.portfolio-details-video .vid-link .ripple:before,
.portfolio-details-video .vid-link .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}

.portfolio-details-video .vid-link .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.portfolio-details-video .vid-link .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

.portfolio-details-summery {
  padding-bottom: 50px;
}

@media (min-width: 1200px) {
  .portfolio-details-summery {
    padding-bottom: 80px;
  }
}

.portfolio-details-summery__heading {
  margin: 0;
  text-transform: uppercase;
  color: var(--thm-black);
  font-size: 30px;
  line-height: 1;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .portfolio-details-summery__heading {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.portfolio-details-summery__text {
  margin: 0;
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .portfolio-details-summery__text {
    padding-left: 100px;
    padding-right: 100px;
  }
}

/* portfolio horizontal */
.portfolio-horizontal {
  min-height: 100vh;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 90px;
  padding-bottom: 90px;
}

[class*="portfolio-horizontal__shape-"] {
  position: absolute;
  display: none;
  -webkit-transform-origin: center bottom;
  transform-origin: center bottom;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: banner3Shake;
  animation-name: banner3Shake;
  -webkit-animation-timing-function: ease-out;
  animation-timing-function: ease-out;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@media (min-width: 992px) {
  [class*="portfolio-horizontal__shape-"] {
    display: block;
  }
}

@-webkit-keyframes banner3Shake {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }

  30% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  60% {
    -webkit-transform: rotate3d(1, 0, 0, 0deg);
    transform: rotate3d(1, 0, 0, 0deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  100% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
}

@keyframes banner3Shake {
  0% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }

  30% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  60% {
    -webkit-transform: rotate3d(1, 0, 0, 0deg);
    transform: rotate3d(1, 0, 0, 0deg);
  }

  80% {
    -webkit-transform: rotate3d(0, 0, 1, 5deg);
    transform: rotate3d(0, 0, 1, 5deg);
  }

  100% {
    -webkit-transform: rotate3d(0, 1, 0, 0deg);
    transform: rotate3d(0, 1, 0, 0deg);
  }
}

.portfolio-horizontal__shape-1 {
  top: -120px;
  right: 40%;
}

.portfolio-horizontal__shape-2 {
  left: -55px;
  bottom: -36px;
}

.portfolio-horizontal__shape-3 {
  right: -260px;
  bottom: -30px;
}

@media (min-width: 992px) {
  .portfolio-horizontal {
    padding-top: 120px;
    padding-bottom: 110px;
  }
}

.portfolio-horizontal .auto-container {
  max-width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 992px) {
  .portfolio-horizontal .auto-container {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 1200px) {
  .portfolio-horizontal .auto-container {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (min-width: 1440px) {
  .portfolio-horizontal .auto-container {
    padding-left: 155px;
    padding-right: 155px;
  }
}

.portfolio-horizontal__card__title {
  margin: 0;
  color: #fff;
  font-weight: 800;
  font-family: "Montserrat", "Rubik";
  font-size: 30px;
}

.portfolio-horizontal__card__title a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.portfolio-horizontal__card__title a:hover {
  color: var(--thm-black);
}

.portfolio-horizontal__category {
  margin: 0;
  display: block;
  line-height: 1;
  font-weight: 600;
  font-size: 14px;
  font-family: "Montserrat", "Rubik";
  color: #fff;
  margin-bottom: 15px;
  margin-top: 30px;
}

.portfolio-horizontal__carousel .tns-controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 50px;
}

.portfolio-horizontal__carousel .tns-controls .icon {
  width: 41px;
  height: 59px;
  background-repeat: no-repeat;
  background-position: center center;
  display: block;
  opacity: 0.2;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media (min-width: 1200px) {
  .portfolio-horizontal__carousel .tns-controls button {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border: none;
    background-color: transparent;
  }

  .portfolio-horizontal__carousel .tns-controls .tns-prev {
    right: calc(100% + 55px);
  }

  .portfolio-horizontal__carousel .tns-controls .tns-next {
    left: calc(100% + 25px);
  }
}

.portfolio-horizontal__carousel .tns-controls .icon:hover {
  opacity: 1;
}

.portfolio-horizontal__carousel .tns-controls .icon.nav-button-right {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-left: 30px;
}

.portfolio-horizontal-two {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (min-width: 992px) {
  .portfolio-horizontal-two {
    padding-top: 150px;
    padding-bottom: 150px;
    margin-left: 100px;
  }
}

@media (min-width: 1200px) {
  .portfolio-horizontal-two {
    /* display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 100px; */
    padding-top: 200px;
    padding-bottom: 200px;
    height: 100vh;
    width: calc(100vw - 100px);
    padding-left: 70px;
    padding-right: 70px;
  }
}

.portfolio-horizontal-two__image {
  background-color: var(--thm-black);
}

.portfolio-horizontal-two__image img {
  width: 100%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.portfolio-horizontal-two__item:hover .portfolio-horizontal-two__image img {
  opacity: 0.8;
}

.portfolio-horizontal-two__category {
  margin: 0;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: var(--thm-text);
  font-weight: 400;
  line-height: 1;
  display: block;
  margin-top: 20px;
  margin-bottom: 12px;
}

.portfolio-horizontal-two__title {
  margin: 0;
  text-transform: uppercase;
  font-size: 30px;
  color: var(--thm-black);
  font-weight: 400;
  line-height: 1;
  display: block;
}

.portfolio-horizontal-two__title a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.portfolio-horizontal-two__title a:hover {
  color: var(--thm-base);
}

.portfolio-horizontal-two__controls {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
}

@media (min-width: 992px) {
  .portfolio-horizontal-two__controls {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

@media (min-width: 1200px) {
  .portfolio-horizontal-two__controls {
    position: absolute;
    bottom: 100px;
    right: 70px;
  }
}

.portfolio-horizontal-two__carousel__progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 20px;
}

.portfolio-horizontal-two__carousel__progress li span {
  display: block;
  width: 30px;
  height: 1px;
  background-color: #e3e3e3;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .portfolio-horizontal-two__carousel__progress li span {
    width: 55px;
  }
}

.portfolio-horizontal-two__carousel__progress li.active span {
  background-color: var(--thm-black);
}

.portfolio-horizontal-two__carousel__count {
  text-transform: uppercase;
  margin: 0;
  color: var(--thm-text);
  font-size: 16px;
  letter-spacing: 0.2em;
}

/* demo landing page */
.demo-purchase-btn .btn-title {
  padding-top: 21.5px;
  padding-bottom: 17.5px;
}

.demo-purchase-btn:hover .btn-title {
  color: var(--thm-black);
}

.demo-purchase-btn:hover .btn-curve {
  background-color: #fff;
}

.demo-top {
  padding-top: 265px;
  padding-bottom: 170px;
  background-color: #1c1e22;
  background-image: url(../assets/images/background/home-portfolio-bg-1-1.png);
}

.demo-top .auto-container {
  text-align: center;
}

.demo-top h3 {
  margin: 0;
  color: #fff;
  font-size: 110px;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 600;
}

.demo-top h3 span {
  color: var(--thm-base);
}

.demo-top p {
  font-size: 26px;
  line-height: 1.7em;
  margin: 0;
  margin-top: 30px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.6);
  max-width: 590px;
  margin-left: auto;
  margin-right: auto;
  font-family: var(--thm-b-font);
  text-align: center;
}

/* demo gallery */
.demo-gallery {
  padding-top: 120px;
  padding-bottom: 100px;
}

.demo-gallery .mixitup-gallery .filters .filter {
  color: #686a6f;
  font-size: 24px;
}

.demo-gallery .mixitup-gallery .filters .filter+.filter {
  margin-left: 40px;
}

.demo-gallery .mixitup-gallery .filters .filter.active {
  color: var(--thm-black);
}

.demo-gallery .portfolio-masonary__box-outer {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  border-radius: 8px;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
}

.demo-gallery .portfolio-masonary__box-outer:hover {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
}

.demo-gallery .portfolio-masonary__box img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.demo-gallery .portfolio-masonary__box .link {
  position: absolute;
  top: 60px;
  right: 60px;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  font-size: 18px;
  color: var(--thm-black);
  border: 1px solid var(--thm-black);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.demo-gallery .portfolio-masonary__box .link:hover {
  background-color: #fff;
  border-color: #fff;
}

.demo-features {
  background-color: #f4f5f8;
  padding-top: 120px;
  padding-bottom: 120px;
}

.demo-features__wrapper {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr) [7];
  grid-template-columns: repeat(7, 1fr);
  grid-row-gap: 30px;
}

.demo-features__box {
  text-align: center;
}

.demo-features__box img {
  border-radius: 8px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
}

.demo-features__box h4 {
  margin: 0;
  text-transform: uppercase;
  font-size: 20px;
  color: var(--thm-black);
  letter-spacing: 0.1em;
  margin-top: 20px;
  text-align: center;
}

/* demo footer */
.main-footer__demo .footer-widget h3 {
  max-width: none;
  font-size: 80px;
  letter-spacing: 0;
  margin: 0;
}

.main-footer__demo .footer-widget p {
  margin: 0;
  font-size: 36px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 300;
  font-family: var(--thm-font);
  margin-bottom: 45px;
}

.main-footer__demo .footer-widget {
  padding-bottom: 110px;
}

/* login page */
.login-wrapper {
  position: relative;
  min-height: 100vh;
}

.login-wrapper .login-bg {
  position: absolute;
  right: 0;
  bottom: 0;
  width: calc(100% - 630px);
  height: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
}

.login-wrapper .login-content__inner {
  width: 100%;
  max-width: 630px;
  padding-left: 115px;
  padding-right: 115px;
  background-color: #fff;
  -webkit-box-shadow: 9.986px -0.523px 30px 0px rgba(5, 5, 6, 0.1);
  box-shadow: 9.986px -0.523px 30px 0px rgba(5, 5, 6, 0.1);
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  z-index: 10;
}

.login-wrapper form input[type="text"],
.login-wrapper form input[type="email"],
.login-wrapper form input[type="password"] {
  width: 100%;
  border-radius: 8px;
  background-color: #f4f5f8;
  height: 70px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 14px;
  color: #686a6f;
  font-weight: 400;
  font-family: var(--thm-b-font);
  margin-bottom: 20px;
}

.login-wrapper form ::-webkit-input-placeholder {
  opacity: 1;
  color: inherit;
}

.login-wrapper form :-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}

.login-wrapper form ::-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}

.login-wrapper form ::placeholder {
  opacity: 1;
  color: inherit;
}

.login-wrapper form p {
  margin: 0;
  color: #686a6f;
  line-height: 2.125;
}

.login-wrapper form p a {
  color: inherit;
}

.login-wrapper form .theme-btn {
  width: 100%;
  font-size: 18px;
  letter-spacing: 0.1em;
  font-weight: 400;
  margin-top: 15px;
}

.login-wrapper .login-content__inner .copyright-text {
  margin-top: auto;
  text-align: center;
  color: #686a6f;
  margin-bottom: 60px;
}

.login-wrapper .logo {
  margin-top: 90px;
  margin-bottom: 140px;
}

.login-wrapper .signup-link {
  text-transform: uppercase;
  color: #000000;
  font-weight: 400;
  letter-spacing: 0.1em;
  font-size: 20px;
  text-align: center;
  line-height: 1;
  margin-top: 35px;
  font-family: var(--thm-font);
}

.login-wrapper .signup-link a {
  color: var(--thm-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.login-wrapper .signup-link a:hover {
  opacity: 0.7;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing-one {
  padding-top: 100px;
  padding-bottom: 70px;
}

@media (min-width: 992px) {
  .pricing-one {
    padding-top: 120px;
    padding-bottom: 90px;
  }
}

.pricing-one [class*="col-"] {
  margin-bottom: 30px;
}

/* price card */
.pricing-card {
  background-color: #f4f5f8;
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-top: 50px;
  border-radius: 8px;
}

.pricing-card__bottom {
  border-radius: 8px;
  background-color: #fff;
  padding-top: 40px;
  padding-bottom: 40px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
}

.pricing-card__list {
  text-align: left;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 43px;
}

.pricing-card__list li {
  position: relative;
  font-size: 16px;
  color: var(--thm-text);
  padding-left: 40px;
}

.pricing-card__list li+li {
  margin-top: 5px;
}

.pricing-card__list li i {
  position: absolute;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 20px;
  color: var(--thm-base);
}

.pricing-card__list li i.unavailable {
  color: var(--thm-black);
}

.pricing-card__icon {
  width: 100px;
  height: 100px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-bottom: 30px;
}

.pricing-card__icon::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--thm-base);
  opacity: 0.2;
  border-radius: 50%;
  content: "";
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.pricing-card:hover .pricing-card__icon::before {
  opacity: 1;
}

@media (min-width: 992px) {
  .pricing-card__icon {
    width: 130px;
    height: 130px;
  }
}

.pricing-card__icon i {
  font-size: 50px;
  position: relative;
  color: var(--thm-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.pricing-card:hover .pricing-card__icon i {
  color: var(--thm-black);
}

@media (min-width: 992px) {
  .pricing-card__icon i {
    font-size: 64px;
  }
}

.pricing-card__name {
  line-height: 1;
  margin: 0;
  font-size: 20px;
  text-transform: uppercase;
  font-family: var(--thm-font);
  letter-spacing: 0.1em;
  color: var(--thm-base);
  margin-bottom: 2px;
}

.pricing-card__amount {
  margin: 0;
  line-height: 1;
  font-size: 45px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .pricing-card__amount {
    font-size: 60px;
  }
}

/*--------------------------------------------------------------
# Coming Soon
--------------------------------------------------------------*/
.coming-soon {
  background-color: var(--thm-black);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
  padding-top: 60px;
  padding-bottom: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.coming-soon .auto-container {
  width: 100%;
}

.coming-soon__title {
  margin: 0;
  color: #fff;
  font-size: 45px;
  text-transform: uppercase;
  font-weight: 300;
  margin-top: 40px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .coming-soon__title {
    font-size: 60px;
    margin-top: 90px;
    margin-bottom: 30px;
  }
}

.coming-soon__form-text,
.coming-soon__text {
  margin: 0;
  color: #999b9f;
  max-width: 570px;
}

.coming-soon__form-title {
  text-transform: uppercase;
  margin: 0;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
}

.coming-soon__form {
  max-width: 400px;
  width: 100%;
  border-radius: 8px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 50px;
}

@media (min-width: 992px) {
  .coming-soon__form {
    margin-bottom: 35px;
  }
}

.coming-soon__form input[type="text"],
.coming-soon__form input[type="email"] {
  width: 100%;
  height: 70px;
  background-color: #1c1e22;
  font-size: 14px;
  font-family: var(--thm-b-font);
  color: #999b9f;
  border-radius: 8px;
  padding-left: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.coming-soon__form input[type="text"]:focus,
.coming-soon__form input[type="email"]:focus {
  -webkit-box-shadow: 0 0 0 1px var(--thm-base);
  box-shadow: 0 0 0 1px var(--thm-base);
}

.coming-soon__form ::-webkit-input-placeholder {
  opacity: 1;
  color: inherit;
}

.coming-soon__form :-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}

.coming-soon__form ::-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}

.coming-soon__form ::placeholder {
  opacity: 1;
  color: inherit;
}

.coming-soon__form button[type="submit"] {
  width: 42px;
  height: 42px;
  background-color: var(--thm-base);
  color: var(--thm-black);
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 15px;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.coming-soon__form button[type="submit"]:hover {
  background-color: #fff;
  color: var(--thm-black);
}

.coming-soon__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.coming-soon__social li+li {
  margin-left: 10px;
}

.coming-soon__social a {
  width: 44px;
  height: 44px;
  background-color: #1c1e22;
  color: #999b9f;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  font-size: 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.coming-soon__social a:hover {
  background-color: var(--thm-base);
  color: var(--thm-black);
}

.coming-soon__countdown {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (1fr) [2];
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  width: 100%;
  max-width: 580px;
  margin-top: 30px;
  margin-bottom: 35px;
}

@media (min-width: 992px) {
  .coming-soon__countdown {
    margin-top: 50px;
    margin-bottom: 45px;
    -ms-grid-columns: (1fr) [4];
    grid-template-columns: repeat(4, 1fr);
  }
}

.coming-soon__countdown li {
  border: 1px solid var(--thm-base);
  border-radius: 8px;
  text-align: center;
  font-family: var(--thm-font);
  overflow: hidden;
}

.coming-soon__countdown li span {
  font-size: 45px;
  display: block;
  color: #fff;
  padding-top: 50px;
}

@media (min-width: 992px) {
  .coming-soon__countdown li span {
    font-size: 60px;
  }
}

.coming-soon__countdown li i {
  text-transform: uppercase;
  color: var(--thm-black);
  background-color: var(--thm-base);
  display: block;
  font-size: 20px;
  font-style: normal;
  line-height: 40px;
  display: block;
  padding-top: 5px;
  margin-top: 45px;
}

/*--------------------------------------------------------------
# Shop
--------------------------------------------------------------*/
.shop-page {
  padding-top: 100px;
  padding-bottom: 85px;
}

@media (max-width: 992px) {
  .shop-page {
    padding-top: 120px;
    padding-bottom: 105px;
  }
}

.product-sorting {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media (min-width: 768px) {
  .product-sorting {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.product-sorting p {
  margin: 0;
  color: var(--thm-text);
  font-size: 18px;
  margin-bottom: 20px;
}

@media (min-width: 768px) {
  .product-sorting p {
    margin-bottom: 0;
  }
}

.product-sorting .form-group {
  max-width: 340px;
  width: 100%;
}

.product-card {
  margin-bottom: 30px;
}

.product-card__buttons {
  position: absolute;
  bottom: 30px;
  left: 0;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-30%);
  transform: translateY(-30%);
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
}

.product-card:hover .product-card__buttons {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.product-card__buttons .btn-style-one {
  margin-right: 10px;
}

.product-card__buttons .btn-style-two:hover .btn-curve {
  width: 101%;
}

.product-card__buttons .theme-btn .btn-title {
  padding: 7px 21px 3px;
  font-size: 16px;
}

.product-card__image {
  position: relative;
  overflow: hidden;
  border-radius: 8px;
}

.product-card__image>img {
  width: 100%;
}

.product-card__content {
  text-align: center;
}

.product-card__title {
  margin: 0;
  color: var(--thm-black);
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
  margin-top: 25px;
  margin-bottom: 5px;
}

.product-card__title a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.product-card__title a:hover {
  color: var(--thm-base);
}

.product-card__price {
  color: var(--thm-text);
  font-size: 16px;
  line-height: 1;
  margin: 0;
  margin-bottom: 10px;
}

.product-card__stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  line-height: 1;
}

.product-card__stars i {
  font-size: 14px;
  color: var(--thm-base);
}

.product-card__stars i+i {
  margin-left: 5px;
}

.load-more-products {
  margin-top: 30px;
}

/* shop sidebar */
.shop-search form {
  border-radius: 8px;
  position: relative;
  background-color: var(--thm-base);
}

.shop-search button[type="submit"] {
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: var(--thm-black);
  background-color: transparent;
  padding: 0;
  width: auto;
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.shop-search form input[type="search"],
.shop-search form input[type="text"] {
  width: 100%;
  height: 70px;
  background-color: var(--thm-base);
  border-radius: 8px;
  padding-left: 30px;
  padding-right: 30px;
  font-size: 14px;
  color: var(--thm-black);
  font-family: var(--thm-b-font);
}

.shop-search form ::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

.shop-search form :-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

.shop-search form ::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

.shop-search form ::placeholder {
  color: inherit;
  opacity: 1;
}

.shop-sidebar__title {
  margin: 0;
  color: var(--thm-black);
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 25px;
}

.product-sidebar__price-range .rc-slider-handle {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background-color: var(--thm-base);
  border: none;
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.product-sidebar__price-range .rc-slider-handle-dragging {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  cursor: auto !important;
}

.product-sidebar__price-range .rc-slider-horizontal .rc-slider-handle {
  right: -8px;
}

.product-sidebar__price-range .rc-slider-handle::before,
.product-sidebar__price-range .rc-slider-handle::after {
  display: none;
}

.product-sidebar__price-range .rc-slider-track {
  background-color: var(--thm-base);
}

.product-sidebar__price-range .form-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 0;
  margin-top: 20px;
}

.product-sidebar__price-range .form-group p {
  margin: 0;
}

.product-sidebar__price-range .form-group .left>span {
  margin-left: 5px;
  margin-right: 5px;
}

.product-sidebar__price-range .form-group .left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-sidebar__price-range .theme-btn .btn-title {
  padding: 7px 21px 3px;
  font-size: 16px;
}

.shop-category ul {
  position: relative;
  margin-top: -15px;
}

.shop-category ul li {
  position: relative;
  line-height: 30px;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--thm-text);
  margin-bottom: 15px;
}

.shop-category ul li:last-child {
  margin-bottom: 0;
}

.shop-category ul li a {
  position: relative;
  display: block;
  line-height: 30px;
  font-size: 16px;
  text-transform: capitalize;
  color: var(--thm-text);
  font-weight: 400;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  z-index: 1;
}

.shop-category ul li:hover a,
.shop-category ul li.active a {
  color: var(--thm-black);
  font-weight: 400;
  text-shadow: 0 0 1px currentColor;
}

.shop-category ul li a:after {
  position: absolute;
  right: 0;
  top: -1px;
  font-family: "Flaticon";
  content: "\f15f";
  opacity: 1;
  font-size: 10px;
  line-height: 30px;
  font-weight: 700;
  z-index: 1;
}

.shop-sidebar__single {
  padding: 30px;
  border: 1px solid;
  border-color: #e9ebee;
  margin-bottom: 30px;
  border-radius: 8px;
}

.shop-sidebar__single.shop-search {
  border: none;
  padding: 0;
}

/* review form */
.review-form {
  padding-bottom: 100px;
}

@media (min-width: 992px) {
  .review-form {
    padding-bottom: 120px;
  }
}

.review-form .row {
  margin-left: -10px;
  margin-right: -10px;
}

.review-form [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.review-form .form-group {
  margin-bottom: 20px;
}

.review-form__title {
  margin: 0;
  text-transform: uppercase;
  font-size: 36px;
  margin-bottom: 30px;
  letter-spacing: 0.01em;
}

.review-form__text {
  margin: 0;
  color: var(--thm-text);
  font-size: 18px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.review-form__text i {
  font-size: 14px;
  color: var(--thm-base);
}

.review-form__text i:first-child {
  margin-left: 10px;
}

.review-form__text i+i {
  margin-left: 5px;
}

.review-one .comment-box .reply-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 992px) {
  .review-one .comment-box .reply-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (min-width: 992px) {
  .review-one .comment-box .text {
    margin-bottom: 0;
  }
}

.review-one .comment-box .reply-btn i {
  font-size: 14px;
  color: var(--thm-base);
}

.review-one .comment-box .reply-btn i+i {
  margin-left: 5px;
}

/* product description */
.product-description {
  padding-bottom: 40px;
}

.product-description__title {
  margin: 0;
  font-size: 36px;
  text-transform: uppercase;
  color: var(--thm-black);
  letter-spacing: 0.01em;
  margin-bottom: 30px;
}

.product-description__text {
  margin: 0;
}

.product-description__text+.product-description__text {
  margin-top: 30px;
}

/* product details */
.product-details {
  padding-bottom: 60px;
  padding-top: 100px;
}

@media (min-width: 1200px) {
  .product-details {
    padding-top: 120px;
  }
}

.product-details__buttons {
  margin-bottom: 35px;
  margin-top: 15px;
}

.product-details__buttons .theme-btn {
  margin-right: 10px;
  margin-top: 5px;
}

.product-details__social {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.product-details__social span {
  font-size: 24px;
  color: var(--thm-black);
  text-transform: uppercase;
  font-family: var(--thm-font);
  margin-right: 20px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  .product-details__social span {
    margin-bottom: 0;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
}

.product-details__social a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 44px;
  height: 44px;
  background-color: #f4f5f8;
  color: #686a6f;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  font-size: 16px;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.product-details__social a+a {
  margin-left: 10px;
}

.product-details__social a:hover {
  color: var(--thm-black);
  background-color: var(--thm-base);
}

.product-details__quantity {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-details__quantity-title {
  margin: 0;
  color: var(--thm-black);
  text-transform: uppercase;
  font-size: 24px;
  margin-right: 20px;
}

.product-details__quantity .quantity-box {
  position: relative;
  width: 98px;
  border-radius: 8px;
  height: 60px;
}

.product-details__quantity .quantity-box input {
  width: 98px;
  border-radius: 8px;
  height: 60px;
  border: 1px solid #e9ebee;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  font-family: var(--thm-b-font);
  padding-left: 30px;
}

.product-details__quantity .quantity-box button {
  width: 29px;
  height: 29px;
  background-color: transparent;
  color: var(--thm-black);
  font-size: 8px;
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: #fff;
  border-left: 1px solid #e9ebee;
  border-top-right-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.product-details__quantity .quantity-box button.sub {
  bottom: 1px;
  top: auto;
  border-top: 1px solid #e9ebee;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 8px;
}

.product-details__reveiw {
  border-bottom: 1px solid #e9ebee;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.product-details__reveiw i {
  font-size: 18px;
  color: var(--thm-base);
}

.product-details__reveiw i+i {
  margin-left: 5px;
}

.product-details__reveiw span {
  line-height: 1;
  font-size: 16px;
  color: var(--thm-text);
  position: relative;
  top: 2px;
  margin-left: 10px;
}

.product-details__title {
  font-size: 45px;
  text-transform: uppercase;
  margin: 0;
  margin-top: 30px;
}

@media (min-width: 1200px) {
  .product-details__title {
    margin-top: -5px;
    margin-right: 10px;
  }
}

.product-details__price {
  margin: 0;
  color: var(--thm-base);
  font-size: 20px;
  line-height: 1;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .product-details__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}

/* cart page */
.cart-page {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (min-width: 992px) {
  .cart-page {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.cart-table {
  margin-bottom: 60px;
}

@media (max-width: 1199px) {
  .cart-table {
    min-width: 1170px;
  }
}

.cart-table thead th {
  text-transform: uppercase;
  font-family: var(--thm-font);
  font-size: 30px;
  color: var(--thm-black);
  font-weight: 400;
  padding: 0;
  border: none;
  padding-bottom: 20px;
}

.cart-table thead th:last-child {
  text-align: right;
}

.cart-table .quantity-box {
  position: relative;
  width: 98px;
  border-radius: 8px;
  height: 60px;
}

.cart-table .quantity-box input {
  width: 98px;
  border-radius: 8px;
  height: 60px;
  border: 1px solid #e9ebee;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  font-family: var(--thm-b-font);
  padding-left: 30px;
}

.cart-table .quantity-box button {
  width: 29px;
  height: 29px;
  background-color: transparent;
  color: var(--thm-black);
  font-size: 8px;
  position: absolute;
  top: 1px;
  right: 1px;
  background-color: #fff;
  border-left: 1px solid #e9ebee;
  border-top-right-radius: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.cart-table .quantity-box button.sub {
  bottom: 1px;
  top: auto;
  border-top: 1px solid #e9ebee;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 8px;
}

.cart-table .product-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.cart-table h3 {
  text-transform: uppercase;
  margin: 0;
  color: var(--thm-black);
  font-size: 24px;
  margin-left: 30px;
}

.cart-table h3 a {
  color: inherit;
}

.cart-table tbody tr {
  vertical-align: middle;
}

.cart-table tbody tr:last-child {
  border-bottom: 1px solid #e9ebee;
}

.cart-table tbody td {
  vertical-align: middle;
  border-top: 1px solid #e9ebee;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 0;
  padding-right: 0;
  font-size: 16px;
  color: #686a6f;
}

.cart-table tbody td:last-child {
  text-align: right;
}

.cart-cupon__form {
  margin-bottom: 30px;
}

.cart-cupon__input {
  width: 100%;
  max-width: 370px;
  border-radius: 8px;
  background-color: #f4f5f8;
  padding-left: 30px;
  padding-right: 30px;
  margin-right: 10px;
  font-family: var(--thm-b-font);
  font-size: 14px;
  color: #686a6f;
  height: 65px;
  margin-bottom: 20px;
}

.cart-cupon__form ::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}

.cart-cupon__form :-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

.cart-cupon__form ::-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}

.cart-cupon__form ::placeholder {
  color: inherit;
  opacity: 1;
}

.cart-total {
  margin-bottom: 20px;
}

.cart-total li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 18px;
}

.cart-total li+li {
  margin-top: 10px;
}

.cart-total li span:first-child {
  text-transform: uppercase;
  font-family: var(--thm-font);
  font-size: 30px;
  color: var(--thm-black);
  margin-right: 70px;
  display: block;
  width: 133px;
  text-align: right;
}

.cart-total li:nth-child(2) span:first-child {
  position: relative;
  right: 6px;
}

.cart-total__total {
  color: var(--thm-base);
  font-weight: 500;
}

.cart-page__buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.cart-page__buttons .btn-style-one {
  margin-left: 0px;
  margin-top: 10px;
}

@media (min-width: 1200px) {
  .cart-page__buttons .btn-style-one {
    margin-top: 0;
    margin-left: 10px;
  }
}

.product-details__buttons .btn-style-two:hover .btn-curve,
.cart-page__buttons .btn-style-two:hover .btn-curve {
  background-color: var(--thm-base);
}

/* checkout page */
.checkout-page {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (min-width: 992px) {
  .checkout-page {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.checkout-page .default-form .row {
  margin-left: -10px;
  margin-right: -10px;
}

.checkout-page .default-form [class*="col-"] {
  padding-left: 10px;
  padding-right: 10px;
}

.checkout-page .default-form .form-group {
  margin-bottom: 20px;
}

.checkout-page__returning {
  margin: 0;
  font-size: 16px;
  color: var(--thm-text);
  line-height: 1.5em;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .checkout-page__returning {
    line-height: 1;
  }
}

.checkout-page__returning a {
  color: var(--thm-base);
}

.checkout__title {
  text-transform: uppercase;
  margin: 0;
  color: var(--thm-black);
  font-size: 35px;
  font-weight: 400;
  font-family: var(--thm-font);
  line-height: 1;
  margin-bottom: 40px;
}

@media (min-width: 768px) {
  .checkout__title {
    font-size: 45px;
  }
}

@media (min-width: 1200px) {
  .checkout__title {
    font-size: 60px;
  }
}

.checkout__checkbox {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkout__checkbox input[type="checkbox"] {
  position: absolute;
  left: -999999px;
  visibility: hidden;
}

.checkout__checkbox label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
}

.checkout__checkbox label::after {
  content: "";
  width: 19px;
  height: 19px;
  background-color: #f4f5f8;
  border-radius: 50%;
  margin-left: 20px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 10px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkout__checkbox input[type="checkbox"]:checked+label::after {
  background-color: var(--thm-base);
  content: "\f00c";
}

.checkout-page .default-form .field-checkbox {
  position: relative;
}

.checkout-page .default-form .field-checkbox input[type="checkbox"] {
  position: absolute;
  left: -999999px;
  visibility: hidden;
}

.checkout-page .default-form .field-checkbox label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  color: var(--thm-text);
  cursor: pointer;
}

.checkout-page .default-form .field-checkbox label::before {
  content: "";
  width: 19px;
  height: 19px;
  background-color: #f4f5f8;
  border-radius: 50%;
  margin-right: 10px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 10px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.checkout-page .default-form .field-checkbox input[type="checkbox"]:checked+label::before {
  background-color: var(--thm-base);
  content: "\f00c";
}

.checkout-page .default-form textarea {
  height: 150px;
}

.checkout-page .default-form {
  margin-bottom: 60px;
}

.checkout__table {
  margin-bottom: 50px;
}

@media (min-width: 992px) {
  .checkout__table {
    margin-bottom: 0;
  }
}

.checkout__table thead th {
  text-transform: uppercase;
  font-family: var(--thm-font);
  color: var(--thm-black);
  font-size: 30px;
  line-height: 1;
  font-weight: 400;
}

.checkout__table th:last-child,
.checkout__table td:last-child {
  text-align: right;
}

.checkout__table thead {
  border-top: 1px solid #e9ebee;
  border-bottom: 1px solid #e9ebee;
}

.checkout__table thead th,
.checkout__table tbody td {
  padding: 0;
  /* border-top: 1px solid #e9ebee; */
  border: 0;
}

.checkout__table thead th {
  padding-top: 30px;
  padding-bottom: 23px;
}

.checkout__table tbody {
  border-bottom: 1px solid #e9ebee;
}

.checkout__table tbody td {
  padding-top: 10px;
  padding-bottom: 10px;
  color: var(--thm-text);
}

.checkout__payment {
  background-color: #f4f5f8;
  border-radius: 8px;
  padding: 30px;
  padding-bottom: 5px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .checkout__payment {
    padding: 50px;
    padding-bottom: 25px;
  }
}

.checkout__payment__item+.checkout__payment__item {
  margin-top: 35px;
}

.checkout__payment__title {
  margin: 0;
  text-transform: uppercase;
  font-size: 24px;
  color: var(--thm-black);
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 20px;
  cursor: pointer;
}

.checkout__payment__title::before {
  content: "";
  width: 19px;
  height: 19px;
  background-color: #fff;
  border: 2px solid #e9ebee;
  border-radius: 50%;
  margin-right: 10px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 10px;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px;
  position: relative;
  top: -2px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.checkout__payment__title img {
  margin-left: 15px;
}

.checkout__payment__item--active .checkout__payment__title::before {
  background-color: var(--thm-base);
  border-color: var(--thm-base);
  content: "\f00c";
}

.checkout__payment__content {
  font-size: 16px;
  line-height: 30px;
  color: #686a6f;
}

/*--------------------------------------------------------------
# Video Styles
--------------------------------------------------------------*/
.video-one {
  padding-top: 90px;
  padding-bottom: 75px;
}

@media (min-width: 992px) {
  .video-one {
    padding-top: 120px;
    padding-bottom: 105px;
  }
}

.video-one .video-one__image {
  position: relative;
  margin-bottom: 60px;
}

.video-one .video-one__image img {
  width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  border-radius: 8px;
}

.video-one__content {
  text-align: center;
}

@media (min-width: 992px) {
  .video-one__content {
    text-align: left;
  }
}

.video-one__content .sec-title {
  margin-bottom: 0;
}

.video-one__content .sec-title h2 {
  letter-spacing: -0.02em;
}

@media (min-width: 768px) {
  .video-one__content .sec-title h2 {
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}

.video-one__content .block-text {
  margin: 0;
  font-family: var(--thm-b-font);
  font-size: 16px;
  line-height: 34px;
  color: var(--thm-text);
  margin-top: 20px;
}

@media (min-width: 768px) {
  .video-one__content .block-text {
    max-width: 500px;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.video-one__image .vid-link {
  background-color: var(--thm-base);
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: absolute;
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  width: 70px;
  height: 70px;
  bottom: calc(50% - 35px);
  left: calc(50% - 35px);
  font-size: 20px;
}

@media (min-width: 768px) {
  .video-one__image .vid-link {
    width: 107px;
    font-size: 30px;
    right: 100px;
    bottom: -53.5px;
    height: 107px;
    left: auto;
  }
}

.video-one__image .vid-link:hover {
  background-color: var(--thm-black);
  color: #ffffff;
}

.video-one__image .vid-link a {
  color: inherit;
}

.video-one__image .vid-link .icon {
  position: relative;
  top: 3px;
}

.video-one__image .vid-link::before {
  position: absolute;
  content: "";
  border-radius: 50%;
  border: 1px solid var(--thm-black);
  top: -13px;
  bottom: -13px;
  left: -13px;
  right: -13px;
  opacity: 0.2;
}

.video-one__image .vid-link .ripple,
.video-one__image .vid-link .ripple:before,
.video-one__image .vid-link .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 0 0 0 0 rgba(255, 170, 23, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 170, 23, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}

.video-one__image .vid-link .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.video-one__image .vid-link .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

/*--------------------------------------------------------------
# How it works Styles
--------------------------------------------------------------*/
.how-it-works {
  background-color: #f6f6f6;
  padding-top: 90px;
  padding-bottom: 60px;
}

@media (min-width: 992px) {
  .how-it-works {
    padding-top: 120px;
    padding-bottom: 90px;
  }
}

.how-it-works-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 2px;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: background 500ms ease;
  transition: background 500ms ease;
  margin-bottom: 30px;
}

.how-it-works-card:hover {
  background-color: var(--thm-base);
}

.how-it-works-card__inner {
  border-radius: 8px;
  background-color: #fff;
  padding-bottom: 45px;
}

.how-it-works-card__icon {
  font-size: 64px;
  color: var(--thm-base);
  display: block;
  line-height: 1;
  padding-top: 60px;
  margin-bottom: 30px;
}

.how-it-works-card__title {
  margin: 0;
  color: var(--thm-black);
  line-height: 1;
  text-transform: uppercase;
  font-size: 30px;
}

.how-it-works-card__title a {
  color: inherit;
}

.how-it-works-card__text {
  margin: 0;
  font-size: 16px;
  line-height: 34px;
  color: var(--thm-text);
  max-width: 241px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 35px;
  margin-bottom: 27px;
}

.how-it-works-card__link {
  text-transform: uppercase;
  text-align: center;
  color: var(--thm-black);
  font-size: 16px;
  letter-spacing: 0.1em;
  font-family: var(--thm-font);
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.how-it-works-card__link::before {
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--thm-base);
  top: 80%;
  left: 0;
  display: block;
  position: absolute;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.how-it-works-card__link:hover {
  color: var(--thm-black);
}

.how-it-works-card__link:hover::before {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

/*--------------------------------------------------------------
# History Styles
--------------------------------------------------------------*/
.history-timeline {
  padding-bottom: 40px;
  padding-top: 90px;
}

@media (min-width: 1200px) {
  .history-timeline {
    padding-top: 0;
    padding-bottom: 120px;
    margin-top: -53.5px;
  }
}

.history-timeline__year {
  width: 85px;
  height: 85px;
  background-color: var(--thm-base);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: var(--thm-black);
  font-size: 30px;
  font-family: var(--thm-font);
  border-radius: 50%;
  padding-top: 2px;
  margin-bottom: 0px;
  position: relative;
  z-index: 10;
}

@media (min-width: 992px) {
  .history-timeline__year {
    margin-bottom: 0;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 1200px) {
  .history-timeline__year {
    width: 107px;
    height: 107px;
    font-size: 40px;
  }
}

.history-timeline__date {
  font-size: 20px;
  color: var(--thm-base);
  text-transform: uppercase;
  font-family: var(--thm-font);
  margin: 0;
  display: block;
  line-height: 1;
  margin-bottom: 10px;
  position: relative;
}

@media (min-width: 1200px) {
  .history-timeline__date::before {
    content: "";
    width: 23px;
    height: 23px;
    background-color: var(--thm-base);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: -55px;
    -webkit-transform: translateY(50%) translateX(-50%);
    transform: translateY(50%) translateX(-50%);
    z-index: 10;
  }
}

.history-timeline__title {
  margin: 0;
  text-transform: uppercase;
  color: var(--thm-black);
  font-size: 40px;
  line-height: 1;
}

.history-timeline__text {
  margin: 0;
  color: var(--thm-text);
  font-size: 16px;
  line-height: 34px;
  margin-top: 30px;
}

.history-timeline__image {
  background-color: #222429;
  border-radius: 8px;
  display: inline-block;
  margin-top: 30px;
}

@media (min-width: 1200px) {
  .history-timeline__image {
    margin-top: 0;
  }
}

.history-timeline__image img {
  max-width: 100%;
  border-radius: 8px;
  mix-blend-mode: luminosity;
}

.history-timeline__card .row {
  position: relative;
  padding-top: 40px;
}

@media (min-width: 1200px) {
  .history-timeline__card .row {
    padding-top: 80px;
  }
}

.history-timeline__card .row:last-child {
  padding-bottom: 0;
}

@media (min-width: 992px) {
  .history-timeline__card .row:nth-child(odd) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}

@media (min-width: 992px) {
  .history-timeline__card {
    position: relative;
  }

  .history-timeline__card:after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: #e9ebee;
    position: absolute;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 0;
    left: 50%;
  }
}

.history-timeline__card {
  margin-bottom: 40px;
}

@media (min-width: 1200px) {
  .history-timeline__card {
    padding-bottom: 70px;
    margin-bottom: 0;
  }

  .history-timeline__card:last-child {
    padding-bottom: 0;
  }

  .history-timeline__card .row {
    margin-left: -55px;
    margin-right: -55px;
  }

  .history-timeline__card .row [class*="col-"] {
    padding-left: 55px;
    padding-right: 55px;
  }

  .history-timeline__card .row:nth-child(even) .history-timeline__info {
    text-align: right;
  }

  .history-timeline__card .row:nth-child(even) .history-timeline__date::before {
    left: auto;
    right: -55px;
    -webkit-transform: translateY(50%) translateX(50%);
    transform: translateY(50%) translateX(50%);
  }

  .history-timeline__card:nth-child(even) .row:nth-child(even) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .history-timeline__card:nth-child(even) .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .history-timeline__card:nth-child(even) .row:nth-child(odd) .history-timeline__info {
    text-align: right;
  }

  .history-timeline__card:nth-child(even) .row:nth-child(even) .history-timeline__info {
    text-align: left;
  }

  .history-timeline__card:nth-child(even) .row:nth-child(even) .history-timeline__date::before {
    right: auto;
    left: -55px;
    -webkit-transform: translateY(50%) translateX(-50%);
    transform: translateY(50%) translateX(-50%);
  }

  .history-timeline__card:nth-child(even) .row:nth-child(odd) .history-timeline__date::before {
    right: -55px;
    left: auto;
    -webkit-transform: translateY(50%) translateX(50%);
    transform: translateY(50%) translateX(50%);
  }
}

/*--------------------------------------------------------------
# Brand Portfolio Styles
--------------------------------------------------------------*/
.brand-portfolio__item {
  position: relative;
  background-color: var(--thm-base);
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (min-width: 1200px) {
  .brand-portfolio__item {
    padding-top: 170px;
    padding-bottom: 170px;
  }
}

@media (min-width: 992px) {
  .brand-portfolio__item .auto-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: left;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }
}

.brand-portfolio__item__bg {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--thm-base);
  background-blend-mode: luminosity;
  opacity: 0;
  background-attachment: fixed;
  background-position: center center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.brand-portfolio__item:hover .brand-portfolio__item__bg {
  opacity: 0.1;
}

.brand-portfolio__title {
  margin: 0;
  color: #fff;
  font-size: 40px;
  font-weight: 500;
  line-height: 1;
  margin-top: 20px;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .brand-portfolio__title {
    margin-top: 0;
    margin-bottom: 30px;
    font-size: 60px;
  }
}

.brand-portfolio__link {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  opacity: 0.6;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.brand-portfolio__link:hover {
  opacity: 1;
  color: #fff;
}

@media (min-width: 992px) {
  .brand-portfolio__link {
    font-size: 28px;
  }
}

.brand-portfolio__link span {
  margin-left: 10px;
  position: relative;
  top: 2px;
}

.brand-portfolio__image img {
  opacity: 0.6;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.brand-portfolio__item:hover .brand-portfolio__image img {
  opacity: 1;
}

.brand-portfolio__item:nth-child(2) {
  background-color: #8b34e4;
}

.brand-portfolio__item:nth-child(2) .brand-portfolio__item__bg {
  background-color: #8b34e4;
}

.brand-portfolio__item:nth-child(3) {
  background-color: #3abd90;
}

.brand-portfolio__item:nth-child(3) .brand-portfolio__item__bg {
  background-color: #3abd90;
}

.brand-portfolio__item:nth-child(4) {
  background-color: #e7842b;
}

.brand-portfolio__item:nth-child(4) .brand-portfolio__item__bg {
  background-color: #e7842b;
}

.brand-portfolio__item:nth-child(5) {
  background-color: #cdced5;
}

.brand-portfolio__item:nth-child(5) .brand-portfolio__item__bg {
  background-color: #cdced5;
}

/*--------------------------------------------------------------
# Events Styles
--------------------------------------------------------------*/
.events-one {
  padding-top: 80px;
  padding-bottom: 70px;
}

@media (min-width: 992px) {
  .events-one {
    padding-top: 120px;
    padding-bottom: 110px;
  }
}

.events-one .row {
  --bs-gutter-y: 30px;
}

.events-one__image {
  overflow: hidden;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
}

.events-one__date {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: var(--thm-base);
  width: 60px;
  height: 60px;
  font-family: var(--thm-font);
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 20px;
  border-radius: 8px;
  padding: 0 11px;
  line-height: 1;
  padding-top: 4px;
  z-index: 10;
}

.events-one__image img {
  width: 100%;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transition: 500ms ease;
  transition: 500ms ease;
}

.events-one__item:hover .events-one__image img {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.events-one__content {
  border: 1px solid transparent;
  border-top: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding-left: 40px;
  padding-right: 40px;
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  padding-top: 33px;
  padding-bottom: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.events-one__item:hover .events-one__content {
  border-color: #e9ebee;
}

.events-one__title {
  margin: 0;
  font-size: 34px;
  color: var(--thm-black);
  margin-bottom: 16px;
  text-transform: uppercase;
}

.events-one__title a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.events-one__title a:hover {
  color: var(--thm-base);
}

.events-one__meta li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 16px;
  color: var(--thm-text);
}

.events-one__meta li i {
  color: var(--thm-base);
  margin-right: 10px;
  width: 18px;
}

.events-one .more-box {
  margin-top: 60px;
}

.events-details {
  padding: 80px 0 80px;
}

@media (min-width: 992px) {
  .events-details {
    padding: 120px 0;
  }
}

.events-details .events-one__image {
  border-radius: 8px;
}

.events-details__content .theme-btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-top: 50px;
}

.events-details .events-one__title {
  position: relative;
  top: -10px;
}

.events-details__content {
  margin-top: 40px;
}

.events-details__content p {
  margin: 0;
}

.events-details__content p+p {
  margin-top: 45px;
}

@media (min-width: 992px) {
  .events-details .events-one__title {
    font-size: 50px;
  }
}

.events-details__sidebar {
  margin-top: 40px;
}

.events-details__sidebar .row {
  --bs-gutter-y: 30px;
}

.events-details__widget-info {
  background-color: #f4f5f8;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 42px;
  padding-bottom: 47px;
  border-radius: 8px;
}

.events-details__widget-info ul {
  margin: 0;
}

.events-details__widget-info li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.events-details__widget-info li a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.events-details__widget-info li a:hover {
  color: var(--thm-black);
  text-shadow: 0 0 1px currentColor;
}

.events-details__widget-info li+li {
  margin-top: 14px;
}

.events-details__widget-info li strong {
  text-transform: uppercase;
  color: var(--thm-black);
  font-weight: 400;
  font-family: var(--thm-font);
  font-size: 22px;
}

.events-details__widget-info li span {
  text-align: right;
  font-size: 14px;
  color: var(--thm-text);
  line-height: 24px;
  display: block;
  position: relative;
  top: 4px;
}

.events-details__widget-map {
  overflow: hidden;
  border-radius: 8px;
}

.events-details__widget-map iframe {
  height: 386px;
}

/* update 01-10-2021 */
/* common css */
.sec-title-six {
  margin-bottom: 54px;
}

.sec-title-six__text {
  color: var(--thm-base);
  font-size: 16px;
  margin: 0;
  line-height: 1;
  margin-bottom: 26px;
}

.sec-title-six__text span {
  padding-bottom: 6px;
  border-bottom: 2px solid currentColor;
}

.sec-title-six__title {
  font-size: 40px;
  font-weight: bold;
  color: var(--thm-black);
  margin: 0;
  line-height: 1;
}

@media (min-width: 992px) {
  .sec-title-six__title {
    font-size: 50px;
  }
}

.sec-title-seven {
  margin-bottom: 50px;
}

.sec-title-seven__title {
  margin: 0;
  color: var(--thm-black);
  font-size: 40px;
  line-height: 1.2em;
  letter-spacing: -0.04em;
}

@media (min-width: 1200px) {
  .sec-title-seven__title {
    font-size: 50px;
  }
}

.sec-title-seven__title span {
  font-weight: bold;
}

.sec-title-eight {
  margin-bottom: 47px;
}

.sec-title-eight__text {
  margin: 0;
  font-size: 16px;
  color: var(--thm-text);
  line-height: 1;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .sec-title-eight__text {
    font-size: 18px;
  }
}

.sec-title-eight__title {
  margin: 0;
  font-size: 40px;
  color: var(--thm-black);
  font-weight: bold;
  line-height: 1.2em;
}

@media (min-width: 992px) {
  .sec-title-eight__title {
    font-size: 50px;
  }
}

.thm-btn__six {
  background-color: var(--thm-base);
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  padding: 17px 59px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.thm-btn__six:hover {
  background-color: var(--thm-black);
  color: #fff;
}

.thm-btn__seven {
  border-radius: 5px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  letter-spacing: 0.1em;
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      from(#ff9a61),
      color-stop(51%, #ff4b82),
      to(#ff9a61));
  background-image: linear-gradient(to right,
      #ff9a61 0%,
      #ff4b82 51%,
      #ff9a61 100%);
  background-size: 200% auto;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  text-transform: uppercase;
  padding: 11px 49px;
}

.thm-btn__seven:hover {
  color: #fff;
  background-position: 100%;
}

.thm-btn__eight {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background-color: var(--thm-black);
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  padding: 14.5px 50px;
}

.thm-btn__eight:hover {
  background-color: var(--thm-base);
  color: var(--thm-black);
}

/* header home 6 corporate agency */
.preloader .icon {
  background-size: 65px auto;
}

/* header six */
@media (min-width: 1200px) {
  .header-six .auto-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.header-six__logo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 15px;
  padding-bottom: 15px;
}

@media (min-width: 1200px) {
  .header-six__logo {
    padding-top: 29.5px;
    padding-bottom: 29.5px;
  }
}

.header-six .mobile-nav-toggler {
  font-size: 20px;
  color: var(--thm-black);
  cursor: pointer;
}

@media (min-width: 1200px) {
  .header-six .mobile-nav-toggler {
    display: none;
  }
}

.header-six__info,
.header-six__social {
  display: none;
}

@media (min-width: 1200px) {

  .header-six__info,
  .header-six__social {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.header-six__info {
  margin-left: auto;
}

.header-six__info__link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--thm-black);
  font-weight: 700;
  border-left: 1px solid #e2e7ea;
  margin-left: 40px;
  padding-left: 40px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  padding-top: 1.5px;
  padding-bottom: 1.5px;
}

.header-six__info__link:first-of-type {
  border-left: 0;
  margin-left: 0;
  padding-left: 0;
}

.header-six__info__link:hover {
  color: var(--thm-base);
}

.header-six__info__link:hover>i {
  color: var(--thm-black);
}

.header-six__info__link>i {
  font-size: 16px;
  color: var(--thm-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-right: 10px;
}

.header-six__social {
  margin-left: 50px;
}

.header-six__social a {
  color: var(--thm-text);
  font-size: 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.header-six__social a+a {
  margin-left: 25px;
}

.header-six__social a:hover {
  color: var(--thm-base);
}

/* slider six */
.slider-six {
  position: relative;
}

.slider-six__curv svg {
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  z-index: 10;
  color: #fff;
}

.slider-six .swiper-slide {
  position: relative;
  background-color: var(--thm-black);
}

.slider-six .image-layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--thm-black);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.6;
  -webkit-transform: scale(1);
  transform: scale(1);
  visibility: hidden;
  -webkit-transition: visibility 0ms ease, -webkit-transform 7000ms ease;
  transition: visibility 0ms ease, -webkit-transform 7000ms ease;
  transition: visibility 0ms ease, transform 7000ms ease;
  transition: visibility 0ms ease, transform 7000ms ease,
    -webkit-transform 7000ms ease;
}

.slider-six .swiper-slide-active .image-layer {
  visibility: visible;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.slider-six__content {
  position: relative;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (min-width: 1200px) {
  .slider-six__content {
    padding-top: 240px;
    padding-bottom: 268px;
  }
}

.slider-six__title {
  font-size: 45px;
  letter-spacing: -0.02em;
  color: #ffffff;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 30px;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  opacity: 0;
  -webkit-transform: translateY(90px);
  transform: translateY(90px);
  -webkit-transition: opacity 1500ms ease, -webkit-transform 1500ms ease;
  transition: opacity 1500ms ease, -webkit-transform 1500ms ease;
  transition: transform 1500ms ease, opacity 1500ms ease;
  transition: transform 1500ms ease, opacity 1500ms ease,
    -webkit-transform 1500ms ease;
}

@media (min-width: 992px) {
  .slider-six__title {
    font-size: 60px;
  }
}

@media (min-width: 1200px) {
  .slider-six__title {
    font-size: 90px;
    margin-bottom: 50px;
  }
}

.slider-six__text {
  margin: 0;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: scaleY(0) translateY(-50px);
  transform: scaleY(0) translateY(-50px);
  -webkit-transition: opacity 1500ms ease, -webkit-transform 1500ms ease;
  transition: opacity 1500ms ease, -webkit-transform 1500ms ease;
  transition: transform 1500ms ease, opacity 1500ms ease;
  transition: transform 1500ms ease, opacity 1500ms ease,
    -webkit-transform 1500ms ease;
  opacity: 0;
}

@media (min-width: 992px) {
  .slider-six__text {
    font-size: 20px;
  }
}

@media (min-width: 1200px) {
  .slider-six__text {
    margin-bottom: 25px;
  }
}

.slider-six__text span {
  padding-bottom: 5px;
  border-bottom: 1px solid currentColor;
}

.slider-six__btn {
  -webkit-transform: scaleY(0) translateY(-200px);
  transform: scaleY(0) translateY(-200px);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  opacity: 0;
  -webkit-transition: background 500ms ease, color 500ms ease,
    opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: background 500ms ease, color 500ms ease, opacity 2000ms ease,
    -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, background 500ms ease, color 500ms ease,
    opacity 2000ms ease;
  transition: transform 2000ms ease, background 500ms ease, color 500ms ease,
    opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.slider-six__btn:hover {
  background-color: #fff;
  color: var(--thm-black);
}

.swiper-slide-active .slider-six__text,
.swiper-slide-active .slider-six__btn {
  -webkit-transform: scaleY(1) translateY(0);
  transform: scaleY(1) translateY(0);
  opacity: 1;
}

.swiper-slide-active .slider-six__title {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.slider-six .main-slider__nav {
  display: none;
}

@media (min-width: 992px) {
  .slider-six .main-slider__nav {
    display: block;
  }
}

.slider-six .main-slider__nav [class*="swiper-button-"] {
  width: 40px;
  height: 120px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #fff;
  color: var(--thm-black);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  opacity: 0.3;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-size: 22px;
}

.slider-six .main-slider__nav [class*="swiper-button-"]:hover {
  opacity: 1;
}

.slider-six .main-slider__nav [class*="swiper-button-"]::after {
  display: none;
}

@media (min-width: 1200px) {
  .slider-six .main-slider__nav [class*="swiper-button-"] {
    width: 45px;
    height: 173px;
  }
}

.slider-six .main-slider__nav .swiper-button-prev {
  left: 0;
}

.slider-six .main-slider__nav .swiper-button-next {
  right: 0;
}

/* main menu six */
.mainmenu-six {
  position: relative;
  z-index: 99;
  display: none;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media (min-width: 1200px) {
  .mainmenu-six {
    display: block;
  }
}

.mainmenu-six__inner {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: -69px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mainmenu-six.fixed-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--thm-black);
  min-height: 68px;
}

.mainmenu-six.fixed-header .mainmenu-six__inner {
  border-bottom: 0;
}

.mainmenu-six .nav-outer {
  position: relative;
  float: none;
  text-align: left;
}

.mainmenu-six__right {
  margin-left: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mainmenu-six__btn {
  padding: 17px 49px;
}

.mainmenu-six__btn:hover {
  background-color: #fff;
  color: var(--thm-black);
}

.mainmenu-six__search {
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  padding-left: 30px;
  margin-right: 30px;
}

.mainmenu-six__search button {
  color: #fff;
  font-size: 24px;
  background-color: transparent;
}

.mainmenu-six .main-menu .navigation>li {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.mainmenu-six .main-menu .navigation>li+li {
  margin-left: 38px;
}

.mainmenu-six .main-menu .navigation>li>a {
  color: #fff;
  font-size: 15px;
  text-transform: capitalize;
  font-weight: bold;
}

.mainmenu-six .main-menu .navigation>li>ul>li>a {
  font-size: 15px;
  font-weight: bold;
  color: var(--thm-black);
  text-transform: capitalize;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mainmenu-six .main-menu .navigation>li>ul>li>a:hover {
  color: var(--thm-base);
}

.mainmenu-six .main-menu .navigation>li>ul {
  border-radius: 0;
}

/* Footer six */
.footer-six {
  background-color: var(--thm-black);
  padding-top: 100px;
  padding-bottom: 70px;
}

@media (min-width: 992px) {
  .footer-six {
    padding-bottom: 100px;
  }
}

.footer-six__title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 40px;
}

.footer-six__widget {
  margin-bottom: 30px;
}

@media (min-width: 992px) {
  .footer-six__widget {
    margin-bottom: 0;
  }
}

.footer-six__about__text {
  margin: 0;
  font-size: 24px;
  color: #fff;
  line-height: 1.2em;
  margin-top: 35px;
  margin-bottom: 35px;
}

.footer-six__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-six__social a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 37px;
  height: 37px;
  color: #91aab9;
  font-size: 14px;
  background-color: #172f3e;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-six__social a:hover {
  background-color: #fff;
  color: var(--thm-base);
}

.footer-six__social a+a {
  margin-left: 10px;
}

.footer-six__links__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.footer-six__links__list__item {
  margin-top: -13px;
}

.footer-six__links__list__item:first-child {
  margin-right: 65px;
}

.footer-six__links__list__item li a {
  font-weight: bold;
  font-size: 14px;
  color: #91aab9;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  position: relative;
}

.footer-six__links__list__item li a::after {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.footer-six__links__list__item li a:hover {
  color: #fff;
}

.footer-six__links__list__item li a:hover::after {
  -webkit-transform-origin: right center;
  transform-origin: right center;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.footer-six__copyright {
  background-color: #172f3e;
  text-align: center;
  padding-top: 35px;
  padding-bottom: 35px;
}

.footer-six__copyright p {
  margin: 0;
  color: #91aab9;
  font-size: 14px;
  font-weight: bold;
}

.footer-six__newsletter__form {
  margin-top: -16px;
  position: relative;
}

.footer-six__newsletter__form button[type="submit"] {
  position: absolute;
  background-color: rgba(255, 255, 255, 0);
  font-size: 18px;
  color: #91aab9;
  position: absolute;
  top: 0;
  right: 0;
}

.footer-six__newsletter__form input[type="text"],
.footer-six__newsletter__form input[type="email"],
.footer-six__newsletter__form input[type="phone"] {
  display: block;
  width: 100%;
  border-bottom: 5px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(0, 0, 0, 0);
  color: #91aab9;
  padding-bottom: 10px;
}

.footer-six__newsletter__form ::-webkit-input-placeholder {
  opacity: 1;
  color: inherit;
}

.footer-six__newsletter__form :-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}

.footer-six__newsletter__form ::-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}

.footer-six__newsletter__form ::placeholder {
  opacity: 1;
  color: inherit;
}

.footer-six__newsletter__form p {
  margin: 0;
  color: #91aab9;
  font-size: 16px;
  font-weight: 400;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}

.footer-six__newsletter__form p a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-left: 5px;
}

.footer-six__newsletter__form p a:hover {
  color: #fff;
}

.footer-six__newsletter__form p::before {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  border: 1px solid rgba(255, 255, 255, 0.1);
  width: 16px;
  height: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
  font-size: 10px;
  margin-right: 10px;
}

/* video six */
.video-six {
  position: relative;
}

.video-six__parallax {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.video-six__parallax .video-six__parallax__inner {
  background-color: #41b974e6;
}

.video-six__parallax .jarallax-img {
  mix-blend-mode: luminosity;
}

.video-six__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: relative;
  padding-top: 90px;
  padding-bottom: 100px;
}

@media (min-width: 1200px) {
  .video-six__inner {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: left;
    padding-top: 110px;
    padding-bottom: 120px;
  }
}

.video-six__title {
  margin: 0;
  color: #fff;
  font-size: 40px;
  font-weight: bold;
}

@media (min-width: 1200px) {
  .video-six__title {
    font-size: 50px;
    max-width: 651px;
  }
}

.video-six__video {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 30px;
}

@media (min-width: 1200px) {
  .video-six__video {
    margin-top: 0;
  }
}

.video-six__video__text {
  font-family: "reeyregular";
  font-size: 30px;
  color: #ffffff;
  margin: 0;
  line-height: 1;
}

.video-six__btn {
  width: 111px;
  height: 111px;
  background-color: var(--thm-black);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 24px;
  border-radius: 50%;
  margin-left: 30px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  position: relative;
}

.video-six__btn:hover {
  background-color: #fff;
  color: var(--thm-base);
}

.video-six__btn .ripple,
.video-six__btn .ripple:before,
.video-six__btn .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -ms-border-radius: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}

.video-six__btn .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.video-six__btn .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

/* map six */
.map-six iframe {
  height: 553px;
}

/* blog six */
.blog-six {
  padding-bottom: 70px;
  padding-top: 100px;
}

@media (min-width: 1200px) {
  .blog-six {
    padding-bottom: 90px;
    padding-top: 120px;
  }
}

.blog-six__item {
  position: relative;
  margin-bottom: 30px;
}

.blog-six__image {
  background-color: var(--thm-black);
}

.blog-six__image img {
  width: 100%;
  opacity: 1;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-six__item:hover .blog-six__image img {
  opacity: 0.8;
}

.blog-six__content {
  padding-left: 40px;
  padding-right: 40px;
  background-color: #fff;
  -webkit-box-shadow: 0px 20px 60px 0px rgba(23, 39, 54, 0.07);
  box-shadow: 0px 20px 60px 0px rgba(23, 39, 54, 0.07);
  padding-top: 20px;
  padding-bottom: 44px;
  position: relative;
}

.blog-six__content::after {
  content: "";
  width: 100%;
  height: 2px;
  background-color: var(--thm-base);
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform-origin: right center;
  transform-origin: right center;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.blog-six__item:hover .blog-six__content::after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.blog-six__text {
  font-size: 18px;
  line-height: 34px;
  color: var(--thm-text);
  margin: 0;
  margin-top: 27px;
}

.blog-six__title {
  margin: 0;
  color: var(--thm-black);
  font-size: 24px;
  line-height: 34px;
  font-weight: bold;
}

.blog-six__title a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-six__title a:hover {
  color: var(--thm-base);
}

.blog-six__meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 6px;
}

.blog-six__meta a {
  font-size: 14px;
  font-weight: bold;
  color: var(--thm-text);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-six__meta a+a {
  margin-left: 10px;
}

.blog-six__meta a i {
  font-size: 14px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: var(--thm-base);
  margin-right: 4px;
}

.blog-six__meta a:hover {
  color: var(--thm-base);
}

.blog-six__meta a:hover i {
  color: var(--thm-black);
}

/* sponsor six */
.sponsor-six {
  border-bottom: 1px solid #e2e7ea;
  padding-bottom: 120px;
  padding-top: 120px;
}

.sponsor-six img {
  opacity: 0.2;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sponsor-six img:hover {
  opacity: 0.6;
}

/* about six */
.about-six {
  background-color: #edf1f3;
  position: relative;
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (min-width: 992px) {
  .about-six {
    padding-bottom: 150px;
    padding-top: 120px;
  }
}

@media (min-width: 1200px) {
  .about-six {
    padding-bottom: 235px;
  }
}

.about-six__curv svg {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  z-index: 10;
  color: #fff;
}

@media (min-width: 1200px) {
  .about-six__content {
    margin-right: -30px;
  }
}

.about-six__text {
  font-size: 18px;
  line-height: 2em;
}

.about-six__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  margin-top: 30px;
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .about-six__image {
    margin: 0;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.about-six__box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  padding: 40px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-top: 30px;
}

@media (min-width: 992px) {
  .about-six__box {
    padding: 30px;
  }
}

@media (min-width: 1200px) {
  .about-six__box {
    padding: 40px;
    margin-top: 60px;
  }
}

.about-six__box:hover {
  background-color: var(--thm-base);
}

.about-six__box__title {
  color: var(--thm-black);
  margin: 0;
  font-size: 20px;
  line-height: 1.5em;
  font-weight: bold;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.about-six__box:hover .about-six__box__title {
  color: #fff;
}

.about-six__box__icon {
  width: 63px;
  height: 63px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: var(--thm-base);
  text-align: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 20px;
  font-size: 32px;
  color: #fff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.about-six__box:hover .about-six__box__icon {
  background-color: #fff;
  color: var(--thm-base);
}

/* about seven */
.about-seven {
  padding-top: 120px;
  padding-bottom: 120px;
}

.about-seven__images {
  display: inline-block;
  position: relative;
  margin-bottom: 60px;
}

@media (min-width: 992px) {
  .about-seven__images {
    margin-bottom: 0;
  }
}

.about-seven__images::before {
  content: "";
  width: 88%;
  height: 88%;
  background-color: var(--thm-base);
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: -40px;
}

.about-seven__images img {
  border-radius: 50%;
  max-width: 100%;
  position: relative;
}

.about-seven__images img:nth-of-type(2) {
  position: absolute;
  bottom: -60px;
  right: 50px;
}

.about-seven .sec-title-six {
  margin-bottom: 0;
}

.about-seven__summery {
  font-size: 18px;
  line-height: 1.88em;
  color: var(--thm-text);
  margin: 0;
  margin-top: 45px;
  margin-bottom: 27px;
}

.about-seven__title {
  font-size: 24px;
  line-height: 1.2em;
  margin: 0;
  color: var(--thm-base);
  font-weight: 700;
  margin-bottom: 40px;
}

.about-seven__list {
  margin: 0;
  margin-bottom: 54px;
}

.about-seven__list li {
  position: relative;
  font-size: 18px;
  font-weight: bold;
  color: var(--thm-black);
  padding-left: 35px;
}

.about-seven__list li>i {
  font-size: 20px;
  color: var(--thm-base);
  position: absolute;
  top: 10px;
  left: 0;
}

.about-seven__list li+li {
  margin-top: 4px;
}

/* Funfact six */
.funfact-six {
  padding-top: 100px;
  padding-bottom: 70px;
  background-color: var(--thm-black);
}

@media (min-width: 992px) {
  .funfact-six {
    padding-top: 120px;
    padding-bottom: 90px;
  }
}

.funfact-six__item {
  margin-bottom: 30px;
  text-align: center;
  position: relative;
}

.funfact-six__item::after {
  display: none;
  content: "";
  height: 158px;
  width: 1px;
  background-color: #fff;
  opacity: 0.1;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media (min-width: 768px) {
  .funfact-six__item::after {
    display: block;
  }
}

.funfact-six [class*="col-"]:nth-of-type(2n) .funfact-six__item::after {
  display: none;
}

@media (min-width: 992px) {
  .funfact-six [class*="col-"]:nth-of-type(2n) .funfact-six__item::after {
    display: block;
  }
}

@media (min-width: 1200px) {
  .funfact-six [class*="col-"]:last-child .funfact-six__item::after {
    display: none;
  }
}

.funfact-six__icon {
  font-size: 64px;
  color: #fff;
  line-height: 1;
}

.funfact-six__count {
  margin: 0;
  color: #fff;
  font-size: 40px;
  line-height: 1;
  margin-top: 25px;
  margin-bottom: 12px;
}

.funfact-six__text {
  margin: 0;
  font-size: 14px;
  font-weight: bold;
  color: #91aab9;
  line-height: 1;
}

/* Project six */
.project-six {
  padding-top: 100px;
}

@media (min-width: 1200px) {
  .project-six {
    padding-top: 120px;
  }
}

.project-six .auto-container {
  max-width: 100%;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 2px;
}

@media (min-width: 1200px) {
  .project-six .auto-container {
    padding-left: 0;
    padding-right: 0;
  }
}

.project-six__item img {
  width: 100%;
}

.project-six__content {
  margin: 40px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 50px;
  -webkit-transform: translateY(-50%) translateZ(500px);
  transform: translateY(-50%) translateZ(500px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 500ms ease, visibility 500ms ease,
    -webkit-transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease,
    -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease, visibility 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease, visibility 500ms ease,
    -webkit-transform 500ms ease;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
}

.project-six__content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--thm-base);
  opacity: 0.9;
}

.project-six__item:hover .project-six__content {
  opacity: 1;
  visibility: visible;
  -webkit-transform-origin: top center;
  transform-origin: top center;
  -webkit-transform: translateY(0) translateZ(0);
  transform: translateY(0) translateZ(0);
}

.project-six__category {
  position: relative;
  font-size: 15px;
  font-weight: bold;
  color: #fff;
  margin: 0;
  line-height: 1;
}

.project-six__title {
  margin: 0;
  line-height: 1;
  font-size: 28px;
  font-weight: bold;
  color: #fff;
  margin-top: 15px;
}

.project-six__title a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

/* subscribe one */
.subscribe-one {
  padding-top: 90px;
  padding-bottom: 90px;
  border-bottom: 1px solid #e2e7ea;
}

@media (min-width: 1200px) {
  .subscribe-one {
    padding-top: 110px;
    padding-bottom: 110px;
  }
}

.subscribe-one__title {
  margin: 0;
  color: var(--thm-black);
  font-size: 40px;
  line-height: 1.2em;
  font-weight: bold;
  margin-bottom: 40px;
}

@media (min-width: 1200px) {
  .subscribe-one__title {
    font-size: 50px;
    margin-bottom: 0px;
  }
}

.subscribe-one__validate {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 21px;
}

.subscribe-one__validate::before {
  content: "";
  width: 20px;
  height: 20px;
  border: 2px solid #e2e7ea;
  display: block;
  margin-right: 10px;
}

@media (min-width: 1200px) {
  .subscribe-one__validate {
    margin-left: 70px;
  }
}

.subscribe-one__form {
  position: relative;
}

@media (min-width: 1200px) {
  .subscribe-one__form {
    padding-left: 70px;
  }
}

.subscribe-one__form input[type="text"],
.subscribe-one__form input[type="email"],
.subscribe-one__form input[type="phone"] {
  width: 100%;
  font-size: 16px;
  color: var(--thm-text);
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 2px solid #e2e7ea;
  padding-bottom: 15px;
}

.subscribe-one__form ::-webkit-input-placeholder {
  opacity: 1;
  color: inherit;
}

.subscribe-one__form :-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}

.subscribe-one__form ::-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}

.subscribe-one__form ::placeholder {
  opacity: 1;
  color: inherit;
}

.subscribe-one__form button[type="submit"] {
  font-size: 18px;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: transparent;
  color: var(--thm-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.subscribe-one__form button[type="submit"]:hover {
  color: var(--thm-black);
}

/* Service Six */
.service-six {
  background-color: #edf1f3;
  position: relative;
  padding-top: 100px;
  padding-bottom: 70px;
}

@media (min-width: 992px) {
  .service-six {
    padding-bottom: 120px;
    padding-top: 120px;
  }
}

@media (min-width: 1200px) {
  .service-six {
    padding-bottom: 205px;
  }
}

.service-six__curv svg {
  width: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  z-index: 10;
  color: #fff;
}

.service-six__item {
  margin-bottom: 30px;
}

.service-six__content {
  position: relative;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  padding: 45px 50px;
}

.service-six__content::before {
  content: "";
  width: 100%;
  height: 3px;
  background-color: var(--thm-base);
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.service-six__item:hover .service-six__content::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

.service-six__icon {
  width: 102px;
  height: 102px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  position: absolute;
  top: -63px;
  right: 30px;
  z-index: 10;
}

.service-six__icon i {
  font-size: 60px;
  color: var(--thm-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-six__item:hover .service-six__icon {
  background-color: var(--thm-base);
}

.service-six__item:hover .service-six__icon i {
  color: #fff;
}

.service-six__title {
  margin: 0;
  color: var(--thm-black);
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 35px;
}

.service-six__title a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-six__title a:hover {
  color: var(--thm-base);
}

.service-six__text {
  font-size: 18px;
  color: var(--thm-text);
  line-height: 2em;
  margin: 0;
}

/* Footer seven */
.footer-seven {
  background-color: var(--thm-black);
  background-position: center center;
  padding-top: 100px;
  padding-bottom: 60px;
}

@media (min-width: 1200px) {
  .footer-seven {
    padding-bottom: 90px;
  }
}

.footer-seven__widget {
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .footer-seven__widget {
    margin-bottom: 0;
  }
}

.footer-seven__about__text {
  margin: 0;
  font-size: 16px;
  line-height: 1.875em;
  color: #a7a4b3;
  margin-top: 20px;
}

.footer-seven__about__text a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  line-height: 40px;
}

.footer-seven__about__text a:hover {
  color: #fff;
}

.footer-seven__title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 25px;
}

.footer-seven__links__list li+li {
  margin-top: 6px;
}

.footer-seven__links__list li a {
  color: #a7a4b3;
  font-size: 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  position: relative;
}

.footer-seven__links__list li a::before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.footer-seven__links__list li a:hover {
  color: #fff;
}

.footer-seven__links__list li a:hover::before {
  -webkit-transform-origin: right center;
  transform-origin: right center;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

@media (min-width: 992px) {
  .footer-seven [class*="col-"]:last-child {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-seven [class*="col-"]:last-child .footer-seven__social {
    margin-top: -50px;
  }
}

.footer-seven__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-seven__social a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #201e28;
  font-size: 14px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  color: #fff;
  border-radius: 50%;
}

@media (min-width: 992px) {
  .footer-seven__social a {
    width: 56px;
    height: 56px;
    font-size: 18px;
  }
}

.footer-seven__social a+a {
  margin-left: 10px;
}

.footer-seven__social a:hover {
  background-color: #fff;
  color: var(--thm-black);
}

.footer-seven__copyright {
  background-color: #201e28;
  padding: 39px 0;
}

.footer-seven__copyright p {
  margin: 0;
  font-size: 16px;
  color: #a7a4b3;
}

/* footer eight */
.footer-eight {
  padding-top: 75px;
  padding-bottom: 60px;
  background-color: var(--thm-black);
}

@media (min-width: 1200px) {
  .footer-eight {
    padding-top: 95px;
    padding-bottom: 80px;
  }
}

.footer-eight__title {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  margin-bottom: 15px;
}

@media (min-width: 1200px) {
  .footer-eight__title {
    margin-bottom: 39px;
  }
}

.footer-eight__widget {
  margin-bottom: 20px;
}

.footer-eight__widget p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  color: #acb5cb;
  line-height: 2.14;
}

.footer-eight__widget p a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-eight__widget p a:hover {
  color: #fff;
}

.footer-eight__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
}

@media (min-width: 1200px) {
  .footer-eight__social {
    margin-top: 30px;
  }
}

.footer-eight__social a {
  color: #acb5cb;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-eight__social a+a {
  margin-left: 25px;
}

.footer-eight__social a:hover {
  color: var(--thm-base);
}

.footer-eight__copyright {
  background-color: #112350;
}

.footer-eight__copyright .auto-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .footer-eight__copyright .auto-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 35px;
    padding-bottom: 35px;
  }
}

.footer-eight__copyright p {
  font-size: 14px;
  font-weight: 500;
  color: #acb5cb;
  margin: 0;
  margin-top: 5px;
}

/* footer nine */
.footer-nine {
  padding-top: 80px;
  padding-bottom: 40px;
  background-color: var(--thm-black);
}

@media (min-width: 1200px) {
  .footer-nine .row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .footer-nine {
    padding-top: 0;
    padding-bottom: 0;
  }
}

.footer-nine__widget {
  margin-bottom: 30px;
}

@media (min-width: 1200px) {
  .footer-nine__widget {
    margin-bottom: 0;
  }
}

.footer-nine__widget__title {
  margin: 0;
  color: #fff;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .footer-nine__widget__title {
    margin-bottom: 30px;
  }
}

.footer-nine__subscribe__text {
  margin: 0;
  font-size: 16px;
  color: #999b9f;
  line-height: 2.25;
  max-width: 302px;
  margin-bottom: 20px;
}

.footer-nine__subscribe form {
  position: relative;
  max-width: 300px;
  width: 100%;
}

.footer-nine__subscribe form input[type="text"],
.footer-nine__subscribe form input[type="email"],
.footer-nine__subscribe form input[type="phone"] {
  background-color: #1c1e22;
  width: 100%;
  display: block;
  height: 70px;
  border-radius: 8px;
  color: #999b9f;
  font-size: 14px;
  padding-left: 30px;
  font-family: var(--thm-b-font);
}

.footer-nine__subscribe form button[type="submit"] {
  background-color: rgba(0, 0, 0, 0);
  color: var(--thm-base);
  font-size: 16px;
  padding: 0;
  border: none;
  outline: none;
  position: absolute;
  top: 50%;
  right: 30px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.footer-nine__subscribe form ::-webkit-input-placeholder {
  opacity: 1;
  color: inherit;
}

.footer-nine__subscribe form :-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}

.footer-nine__subscribe form ::-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}

.footer-nine__subscribe form ::placeholder {
  opacity: 1;
  color: inherit;
}

.footer-nine__links {
  margin-bottom: 0;
}

@media (min-width: 1200px) {
  .footer-nine__links {
    border-left: 1px solid rgba(255, 255, 255, 0.07);
    border-right: 1px solid rgba(255, 255, 255, 0.07);
    margin-left: -10px;
    padding-left: 90px;
    padding-right: 15px;
    margin-right: -15px;
    padding-top: 140px;
    padding-bottom: 110px;
  }
}

.footer-nine__links__list {
  margin: 0;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .footer-nine__links__list {
    margin-bottom: 0;
  }
}

.footer-nine__links__list li a {
  color: #999b9f;
  font-size: 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  position: relative;
}

.footer-nine__links__list li a::before {
  content: "";
  width: 100%;
  height: 1px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: right center;
  transform-origin: right center;
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.footer-nine__links__list li a:hover {
  color: #fff;
}

.footer-nine__links__list li a:hover::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

@media (min-width: 1200px) {
  .footer-nine__contact {
    padding-left: 75px;
  }
}

.footer-nine__contact__text {
  margin: 0;
  font-size: 16px;
  line-height: 1.875;
  color: #999b9f;
  max-width: 193px;
  margin-bottom: 10px;
}

@media (min-width: 1200px) {
  .footer-nine__contact__text {
    margin-bottom: 20px;
  }
}

.footer-nine__contact__list {
  margin-bottom: 0;
}

.footer-nine__contact__list li {
  position: relative;
  padding-left: 28px;
  color: #fff;
  font-size: 16px;
}

.footer-nine__contact__list li a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-nine__contact__list li a:hover {
  opacity: 0.8;
}

.footer-nine__contact__list li i {
  color: var(--thm-base);
  font-size: 15px;
  position: absolute;
  top: 0;
  left: 0;
}

.footer-nine__bottom {
  background-color: #1c1e22;
}

.footer-nine__bottom .auto-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 992px) {
  .footer-nine__bottom .auto-container {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 26px;
    padding-bottom: 26px;
  }
}

.footer-nine__copyright {
  margin: 0;
  font-size: 16px;
  color: #999b9f;
}

.footer-nine__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 20px;
}

@media (min-width: 992px) {
  .footer-nine__social {
    margin-top: 0;
  }
}

.footer-nine__social a {
  width: 44px;
  height: 44px;
  background-color: #000000;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  color: #999b9f;
  font-size: 16px;
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.footer-nine__social a:hover {
  background-color: #fff;
  color: var(--thm-black);
}

.footer-nine__social a+a {
  margin-left: 10px;
}

/* subscribe two */
.subscribe-two {
  background-color: #ff9a61;
  padding-top: 100px;
  padding-bottom: 100px;
  background-repeat: no-repeat;
  background-position: top right;
  position: relative;
}

@media (min-width: 1200px) {
  .subscribe-two {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.subscribe-two__shape {
  position: absolute;
  bottom: 55px;
  left: 150px;
  width: 154px;
  height: 92px;
  background-repeat: no-repeat;
}

@media (min-width: 1200px) {
  .subscribe-two .row {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.subscribe-two__title {
  margin: 0;
  color: #fff;
  font-size: 40px;
  letter-spacing: -0.04em;
}

@media (min-width: 1200px) {
  .subscribe-two__title {
    font-size: 50px;
    max-width: 505px;
  }
}

.subscribe-two__title span {
  font-weight: bold;
}

.subscribe-two__form {
  position: relative;
  margin-top: 50px;
}

@media (min-width: 992px) {
  .subscribe-two__form {
    margin-top: 0;
  }
}

.subscribe-two__form input[type="text"],
.subscribe-two__form input[type="email"],
.subscribe-two__form input[type="phone"] {
  display: block;
  width: 100%;
  border-radius: 5px;
  background-color: #fff;
  color: var(--thm-text);
  font-size: 16px;
  padding-left: 30px;
  padding-right: 30px;
  height: 91px;
}

.subscribe-two__form ::-webkit-input-placeholder {
  opacity: 1;
  color: inherit;
}

.subscribe-two__form :-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}

.subscribe-two__form ::-ms-input-placeholder {
  opacity: 1;
  color: inherit;
}

.subscribe-two__form ::placeholder {
  opacity: 1;
  color: inherit;
}

.subscribe-two__form button[type="submit"] {
  height: 61px;
  width: 61px;
  background-color: var(--thm-black);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  color: #fff;
  font-size: 22px;
  border-radius: 50%;
  position: absolute;
  top: calc(50% - 30.5px);
  right: 10px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.subscribe-two__form button[type="submit"]:hover {
  background-color: var(--thm-base);
  color: #fff;
}

/* Blog Seven */
.blog-seven {
  padding-top: 90px;
  padding-bottom: 70px;
}

@media (min-width: 992px) {
  .blog-seven {
    padding-top: 110px;
    padding-bottom: 90px;
  }
}

.blog-seven__item {
  margin-bottom: 30px;
}

.blog-seven__image {
  overflow: hidden;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.blog-seven__image img {
  width: 100%;
}

.blog-seven__content {
  position: relative;
  border: 1px solid #f0eae4;
  border-top: 0;
  border-bottom: 0;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  padding-bottom: 40px;
  padding-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-seven__content::after {
  content: "";
  background-color: #f0eae4;
  width: 100%;
  height: 11px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-seven__item:hover .blog-seven__content {
  border-color: #ff9a61;
}

.blog-seven__item:hover .blog-seven__content::after {
  background-color: #ff9a61;
}

.blog-seven__title {
  margin: 0;
  color: var(--thm-black);
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5em;
}

.blog-seven__title a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-seven__title a:hover {
  color: inherit;
}

.blog-seven__meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.blog-seven__meta a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--thm-text);
  font-size: 14px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-seven__meta a:not(:last-child)::after {
  content: "/";
  margin-left: 10px;
  margin-right: 10px;
}

.blog-seven__meta a i {
  color: var(--thm-base);
  font-size: 14px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-right: 4px;
}

.blog-seven__meta a:hover {
  color: var(--thm-base);
}

.blog-seven__meta a:hover i {
  color: var(--thm-black);
}

.blog-seven__date {
  position: absolute;
  left: 40px;
  bottom: 100%;
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;
  color: var(--thm-text);
  font-size: 12px;
  z-index: 10;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

/* Testimonials Seven */
.testimonials-seven {
  background-color: #f8f4f0;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

@media (min-width: 1200px) {
  .testimonials-seven {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media (min-width: 1400px) {
  .testimonials-seven {
    padding-top: 120px;
    padding-bottom: 210px;
  }
}

.testimonials-seven .sec-title-seven {
  text-align: center;
}

@media (min-width: 1400px) {
  .testimonials-seven .sec-title-seven {
    text-align: left;
    max-width: 366px;
    margin-bottom: 0;
    margin-top: -10px;
  }
}

.testimonials-seven__nav {
  display: none;
}

@media (min-width: 1400px) {
  .testimonials-seven__nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 40px;
  }
}

.testimonials-seven__nav div+div {
  margin-left: 10px;
}

.testimonials-seven__nav span {
  border-radius: 50%;
  width: 56px;
  height: 56px;
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  width: 56px;
  height: 56px;
  color: var(--thm-black);
  font-size: 22px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.testimonials-seven__nav span:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.testimonials-seven .swiper {
  overflow: visible;
  padding: 60px;
}

@media (min-width: 1400px) {
  .testimonials-seven .swiper {
    left: -60px;
  }
}

.testimonials-seven__slider {
  margin: -60px;
  overflow: hidden;
}

@media (min-width: 1400px) {
  .testimonials-seven__slider-wrapper {
    width: 100%;
    max-width: 1470px;
    position: absolute;
    right: -430px;
    top: 120px;
  }
}

.testimonials-seven__item {
  border-radius: 5px;
  background-color: #fff;
  -webkit-box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.05);
  padding: 40px;
  padding-bottom: 30px;
}

@media (min-width: 992px) {
  .testimonials-seven__item {
    padding: 60px;
    padding-bottom: 50px;
  }
}

.testimonials-seven__item::after {
  content: "\f15a";
  font-family: "Flaticon";
  font-size: 64px;
  color: var(--thm-base);
  position: absolute;
  top: 40px;
  right: 40px;
}

@media (min-width: 992px) {
  .testimonials-seven__item::after {
    top: 60px;
    right: 60px;
  }
}

.testimonials-seven__image {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 20px;
}

.testimonials-seven__image img {
  border-radius: 50%;
}

.testimonials-seven__top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonials-seven__content {
  margin: 0;
  font-size: 18px;
  line-height: 2em;
  color: var(--thm-text);
  font-style: italic;
  margin-top: 25px;
}

.testimonials-seven__name {
  margin: 0;
  font-size: 18px;
  color: var(--thm-black);
  font-weight: 500;
  line-height: 1;
  margin-bottom: 5px;
}

.testimonials-seven__designation {
  margin: 0;
  font-size: 16px;
  line-height: 1;
  color: var(--thm-text);
}

/* sponsor seven */
.sponsor-seven .swiper {
  border-top: 1px solid #f0eae4;
  padding-top: 120px;
  padding-bottom: 120px;
}

.sponsor-seven .swiper img {
  opacity: 0.3;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sponsor-seven .swiper img:hover {
  opacity: 1;
}

/* sponsor eight */
.sponsor-eight {
  background-color: #eef0f6;
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (min-width: 1200px) {
  .sponsor-eight {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.sponsor-eight img {
  opacity: 0.3;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.sponsor-eight img:hover {
  opacity: 0.8;
}

/* Funfact Seven */
.funfact-seven {
  padding-top: 100px;
  padding-bottom: 70px;
}

@media (min-width: 1200px) {
  .funfact-seven {
    padding-top: 120px;
    padding-bottom: 90px;
  }
}

.funfact-seven__item {
  width: 220px;
  height: 220px;
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-shadow: 0px 20px 60px 0px rgba(42, 40, 51, 0.05);
  box-shadow: 0px 20px 60px 0px rgba(42, 40, 51, 0.05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}

.funfact-seven__title {
  font-size: 40px;
  color: var(--thm-black);
  font-weight: 400;
  line-height: 1;
  margin: 0;
  padding-bottom: 13px;
}

.funfact-seven__text {
  margin: 0;
  color: #579eee;
  letter-spacing: 0.2em;
  font-weight: 500;
  font-size: 12px;
  line-height: 1;
  text-transform: uppercase;
}

.funfact-seven__text::before {
  content: "";
  border-radius: 50%;
  border: 8px solid currentColor;
  position: absolute;
  top: 15px;
  left: 15px;
  right: 15px;
  bottom: 15px;
  opacity: 0.1;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.funfact-seven__item:hover .funfact-seven__text::before {
  opacity: 1;
}

@media (min-width: 992px) {
  .funfact-seven [class*="col-"]:nth-child(1) .funfact-seven__item {
    margin-left: 0;
  }
}

.funfact-seven [class*="col-"]:nth-child(2) .funfact-seven__text {
  color: #ff9a61;
}

.funfact-seven [class*="col-"]:nth-child(3) .funfact-seven__text {
  color: #54d6ad;
}

@media (min-width: 992px) {
  .funfact-seven [class*="col-"]:nth-child(4) .funfact-seven__item {
    margin-left: auto;
    margin-right: 0;
  }
}

.funfact-seven [class*="col-"]:nth-child(4) .funfact-seven__text {
  color: #f15d8a;
}

/* Pricing two */
.pricing-two {
  background-color: #f8f4f0;
  background-position: center center;
  padding-top: 100px;
  padding-bottom: 70px;
}

@media (min-width: 1200px) {
  .pricing-two {
    padding-top: 240px;
    padding-bottom: 90px;
  }
}

.pricing-two .animated {
  -webkit-animation-duration: 1500ms;
  animation-duration: 1500ms;
}

.pricing-two__card {
  margin-bottom: 30px;
  background-color: #fff;
  border-radius: 5px;
  padding-left: 40px;
  padding-right: 40px;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  padding-top: 57px;
  padding-bottom: 52px;
}

.pricing-two__card:hover {
  background-color: var(--thm-black);
}

.pricing-two__amount {
  margin: 0;
  font-size: 60px;
  letter-spacing: -0.04em;
  line-height: 1;
  font-weight: 400;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.pricing-two__name {
  margin: 0;
  color: #ff9a61;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-weight: 500;
  font-size: 14px;
  margin-top: 12px;
}

.pricing-two__list {
  margin: 0;
  border-top: 1px solid #f0eae4;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-bottom: 28px;
  padding-top: 30px;
  margin-top: 30px;
}

.pricing-two__list li {
  font-size: 16px;
  color: var(--thm-text);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  font-weight: 400;
}

.pricing-two__list li+li {
  margin-top: 6px;
}

.pricing-two__tagline {
  text-transform: uppercase;
  letter-spacing: 0.2em;
  font-size: 12px;
  font-weight: 400;
  color: var(--thm-text);
  margin: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-top: 12px;
}

.pricing-two__card:hover .pricing-two__list {
  border-color: rgba(255, 255, 255, 0.1);
}

.pricing-two__card:hover .pricing-two__title,
.pricing-two__card:hover .pricing-two__tagline {
  color: #a7a4b3;
}

.pricing-two__card:hover .pricing-two__amount {
  color: #fff;
}

.pricing-two .switch-toggler-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px;
}

.pricing-two .switch-toggler-list li {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.pricing-two .switch-toggler-list li:nth-child(2) {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.pricing-two .switch-toggler-list li:last-child {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  margin-left: 10px;
  margin-right: 10px;
}

.pricing-two .switch-toggler-list li a {
  color: var(--thm-text);
  font-size: 16px;
}

.pricing-two .switch-toggler-list li.active a {
  color: var(--thm-black);
}

.pricing-two .switch-toggler-list li .switch {
  width: 79px;
  height: 36px;
  background-color: #f0eae4;
  border-radius: 18px;
  position: relative;
  display: block;
  cursor: pointer;
}

.pricing-two .switch-toggler-list li .switch span {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-image: -webkit-gradient(linear,
      left top,
      right top,
      from(#ff9a61),
      color-stop(51%, #ff4b82),
      to(#ff9a61));
  background-image: linear-gradient(to right,
      #ff9a61 0%,
      #ff4b82 51%,
      #ff9a61 100%);
  display: block;
  position: absolute;
  left: 5px;
  top: 5px;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
}

.pricing-two .switch-toggler-list li .switch.off span {
  -webkit-transform: translateX(43px);
  transform: translateX(43px);
}

/* project seven */
.project-seven {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (min-width: 1200px) {
  .project-seven {
    padding-top: 120px;
    padding-bottom: 0;
    margin-bottom: -120px;
    position: relative;
    z-index: 10;
  }
}

.project-seven .auto-container {
  max-width: 100%;
}

@media (min-width: 992px) {
  .project-seven .auto-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 1200px) {
  .project-seven .auto-container {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 1400px) {
  .project-seven .auto-container {
    padding-left: 160px;
    padding-right: 160px;
  }
}

.project-seven__link {
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  top: 30px;
  right: 30px;
  font-size: 18px;
  color: var(--thm-black);
  -webkit-transition: color 500ms ease, background 500ms ease,
    opacity 500ms ease, -webkit-transform 500ms ease;
  transition: color 500ms ease, background 500ms ease, opacity 500ms ease,
    -webkit-transform 500ms ease;
  transition: color 500ms ease, background 500ms ease, transform 500ms ease,
    opacity 500ms ease;
  transition: color 500ms ease, background 500ms ease, transform 500ms ease,
    opacity 500ms ease, -webkit-transform 500ms ease;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  opacity: 0;
}

.project-seven__link:hover {
  background-color: var(--thm-base);
  color: #fff;
}

.project-seven__item:hover .project-seven__link {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

.project-seven__item {
  overflow: hidden;
  border-radius: 5px;
  position: relative;
}

.project-seven__item img {
  width: 100%;
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  -webkit-transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: opacity 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease,
    -webkit-transform 500ms ease;
}

.project-seven__item:hover img {
  opacity: 1;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.project-seven__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  padding: 50px;
  background-image: -webkit-gradient(linear,
      left bottom,
      left top,
      from(black),
      color-stop(70%, rgba(0, 0, 0, 0)));
  background-image: linear-gradient(to top, black 0%, rgba(0, 0, 0, 0) 70%);
}

.project-seven__category {
  color: #fff;
  font-size: 14px;
  margin: 0;
  line-height: 1;
  margin-bottom: 9px;
}

.project-seven__title {
  margin: 0;
  font-size: 24px;
  line-height: 1;
  color: #fff;
  line-height: 1.2em;
  font-weight: bold;
}

.project-seven__title a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.project-seven__title a:hover {
  color: var(--thm-base);
}

/* about eight */
.about-eight__icon i {
  font-size: 62px;
  color: var(--thm-base);
}

.about-eight__image {
  margin-top: 60px;
}

@media (min-width: 992px) {
  .about-eight__image {
    margin-top: 0;
  }
}

@media (min-width: 992px) {
  .about-eight__image img {
    max-width: none;
    float: left;
    margin-left: 70px;
  }
}

.about-eight__title {
  margin: 0;
  font-size: 40px;
  color: var(--thm-black);
  max-width: 540px;
  margin-top: 20px;
}

@media (min-width: 992px) {
  .about-eight__title {
    font-size: 50px;
  }
}

.about-eight__title span {
  font-weight: bold;
}

.about-eight__summery {
  font-size: 18px;
  line-height: 2;
  color: var(--thm-text);
  margin: 0;
  margin-top: 35px;
  margin-bottom: 25px;
}

.about-eight__progress+.about-eight__progress {
  margin-top: 18px;
}

.about-eight__progress {
  max-width: 560px;
}

.about-eight__progress-top p {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
  color: var(--thm-black);
}

.about-eight__progress-line {
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background-color: #f8f4f0;
  position: relative;
  margin-top: 5px;
}

.about-eight__progress-line b {
  font-weight: normal;
  font-size: 18px;
  color: var(--thm-text);
  position: absolute;
  right: 0;
  bottom: calc(100% + 5px);
}

.about-eight__progress-line b i {
  font-style: normal;
}

.about-eight__progress-line .count-bar {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 4px;
  background-color: #ff9a61;
  width: 0;
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
}

.about-eight__btn {
  margin-top: 60px;
}

/* about nine */
.about-nine {
  padding-bottom: 120px;
}

.about-nine__image {
  margin-bottom: 60px;
}

@media (min-width: 992px) {
  .about-nine__image {
    margin-bottom: 0;
  }
}

@media (min-width: 992px) {
  .about-nine__image img {
    max-width: none;
    float: right;
    margin-right: 70px;
  }
}

.about-nine__icon i {
  font-size: 62px;
  color: var(--thm-base);
}

.about-nine__title {
  margin: 0;
  font-size: 40px;
  color: var(--thm-black);
  max-width: 540px;
  margin-top: 20px;
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .about-nine__title {
    font-size: 50px;
  }
}

.about-nine__title span {
  font-weight: bold;
}

.about-nine__summery {
  font-size: 24px;
  line-height: 1.667;
  color: var(--thm-base);
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 15px;
}

.about-nine__list {
  margin-top: 23px;
  margin-bottom: 60px;
}

.about-nine__list li {
  position: relative;
  padding-left: 35px;
  font-size: 18px;
  color: var(--thm-text);
}

.about-nine__list li+li {
  margin-top: 7px;
}

.about-nine__list li i {
  position: absolute;
  top: 10px;
  left: 0;
  color: #ffaa17;
  font-size: 20px;
}

/* service seven */
.service-seven {
  padding-top: 90px;
  padding-bottom: 70px;
}

@media (min-width: 992px) {
  .service-seven {
    padding-top: 110px;
    padding-bottom: 90px;
  }
}

.service-seven__item {
  text-align: center;
  border-radius: 5px;
  padding: 50px;
  padding-bottom: 40px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-bottom: 30px;
  position: relative;
}

.service-seven__item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #579eee;
  opacity: 0.2;
  border-radius: 5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-seven__item:hover {
  -webkit-box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 20px 20px 0px rgba(0, 0, 0, 0.05);
}

.service-seven__item:hover::before {
  border-width: 2px;
  opacity: 1;
}

.service-seven__title {
  margin: 0;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.7;
  color: var(--thm-black);
  margin-top: 30px;
  margin-bottom: 32px;
  position: relative;
}

.service-seven__title a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-seven__title a:hover {
  color: #579eee;
}

.service-seven__icon {
  width: 108px;
  height: 108px;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
  margin-left: auto;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: relative;
}

.service-seven__icon::before {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #579eee;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.1;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-seven__icon i {
  position: relative;
  font-size: 63px;
  color: #579eee;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-seven__item:hover .service-seven__icon::before {
  opacity: 1;
}

.service-seven__item:hover .service-seven__icon i {
  color: #fff !important;
}

.service-seven__text {
  margin: 0;
  line-height: 2.125;
  font-size: 16px;
  color: var(--thm-text);
  margin-bottom: 27px;
  position: relative;
}

.service-seven__link {
  font-size: 22px;
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  position: relative;
}

.service-seven__item:hover .service-seven__link {
  color: #579eee;
}

.service-seven [class*="col-"]:nth-child(2) .service-seven__item::before {
  border-color: #ff9a61;
}

.service-seven [class*="col-"]:nth-child(2) .service-seven__icon::before {
  background-color: #ff9a61;
}

.service-seven [class*="col-"]:nth-child(2) .service-seven__title a:hover,
.service-seven [class*="col-"]:nth-child(2) .service-seven__icon i,
.service-seven [class*="col-"]:nth-child(2) .service-seven__item:hover .service-seven__link {
  color: #ff9a61;
}

.service-seven [class*="col-"]:nth-child(3) .service-seven__item::before {
  border-color: #54d6ad;
}

.service-seven [class*="col-"]:nth-child(3) .service-seven__icon::before {
  background-color: #54d6ad;
}

.service-seven [class*="col-"]:nth-child(3) .service-seven__icon i,
.service-seven [class*="col-"]:nth-child(3) .service-seven__title a:hover,
.service-seven [class*="col-"]:nth-child(3) .service-seven__item:hover .service-seven__link {
  color: #54d6ad;
}

.service-seven [class*="col-"]:nth-child(4) .service-seven__item::before {
  border-color: #f15d8a;
}

.service-seven [class*="col-"]:nth-child(4) .service-seven__icon::before {
  background-color: #f15d8a;
}

.service-seven [class*="col-"]:nth-child(4) .service-seven__title a:hover,
.service-seven [class*="col-"]:nth-child(4) .service-seven__icon i,
.service-seven [class*="col-"]:nth-child(4) .service-seven__item:hover .service-seven__link {
  color: #f15d8a;
}

/* slider seven */
.slider-seven .swiper-slide {
  position: relative;
  padding-top: 150px;
}

@media (min-width: 1200px) {
  .slider-seven .swiper-slide {
    padding-bottom: 140px;
  }
}

@media (min-width: 1400px) {
  .slider-seven .swiper-slide {
    padding-top: 260px;
    padding-bottom: 110px;
  }
}

.slider-seven__round__shape-1 {
  position: absolute;
  color: #ff9a61;
  top: 0;
  right: 0;
  display: none;
  -webkit-transform: translateX(300px);
  transform: translateX(300px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 750ms ease, visibility 750ms ease,
    -webkit-transform 750ms ease;
  transition: opacity 750ms ease, visibility 750ms ease,
    -webkit-transform 750ms ease;
  transition: transform 750ms ease, opacity 750ms ease, visibility 750ms ease;
  transition: transform 750ms ease, opacity 750ms ease, visibility 750ms ease,
    -webkit-transform 750ms ease;
}

.slider-seven__round__shape-1 svg {
  width: 400px;
  height: auto;
}

@media (min-width: 1200px) {
  .slider-seven__round__shape-1 svg {
    width: 600px;
  }
}

@media (min-width: 1400px) {
  .slider-seven__round__shape-1 svg {
    width: 750px;
  }
}

@media (min-width: 768px) {
  .slider-seven__round__shape-1 {
    display: block;
  }
}

@media (min-width: 1400px) {
  .slider-seven__round__shape-1 {
    top: -70px;
  }
}

.slider-seven__round__shape-2 {
  color: var(--thm-base);
  position: absolute;
  top: 0;
  right: 50px;
  display: none;
  -webkit-transform: translateX(300px);
  transform: translateX(300px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 1000ms ease, visibility 1000ms ease,
    -webkit-transform 1000ms ease;
  transition: opacity 1000ms ease, visibility 1000ms ease,
    -webkit-transform 1000ms ease;
  transition: transform 1000ms ease, opacity 1000ms ease, visibility 1000ms ease;
  transition: transform 1000ms ease, opacity 1000ms ease, visibility 1000ms ease,
    -webkit-transform 1000ms ease;
}

.slider-seven__round__shape-2 svg {
  width: 400px;
  height: auto;
}

@media (min-width: 1200px) {
  .slider-seven__round__shape-2 svg {
    width: 600px;
  }
}

@media (min-width: 1400px) {
  .slider-seven__round__shape-2 svg {
    width: 750px;
  }
}

@media (min-width: 768px) {
  .slider-seven__round__shape-2 {
    display: block;
  }
}

@media (min-width: 1200px) {
  .slider-seven__round__shape-2 {
    right: 80px;
  }
}

@media (min-width: 1400px) {
  .slider-seven__round__shape-2 {
    top: 0;
    right: 180px;
  }
}

.slider-seven__round__image {
  width: 500px;
  height: 500px;
  position: absolute;
  top: -50px;
  right: -50px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  border-bottom-right-radius: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  -webkit-transform: translateX(300px);
  transform: translateX(300px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 1800ms ease, visibility 1800ms ease,
    -webkit-transform 1800ms ease;
  transition: opacity 1800ms ease, visibility 1800ms ease,
    -webkit-transform 1800ms ease;
  transition: transform 1800ms ease, opacity 1800ms ease, visibility 1800ms ease;
  transition: transform 1800ms ease, opacity 1800ms ease, visibility 1800ms ease,
    -webkit-transform 1800ms ease;
  -webkit-box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 20px 60px 0px rgba(0, 0, 0, 0.1);
  display: none;
}

@media (min-width: 768px) {
  .slider-seven__round__image {
    display: block;
  }
}

@media (min-width: 1200px) {
  .slider-seven__round__image {
    width: 700px;
    height: 700px;
    top: 0;
    right: 0;
  }
}

@media (min-width: 1400px) {
  .slider-seven__round__image {
    width: 986px;
    height: 986px;
    top: -170px;
    right: -125px;
  }
}

.swiper-slide-active .slider-seven__round__shape-1,
.swiper-slide-active .slider-seven__round__shape-2,
.swiper-slide-active .slider-seven__round__image {
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
  visibility: visible;
}

.slider-seven__title {
  margin: 0;
  color: var(--thm-black);
  font-weight: 300;
  font-size: 45px;
  line-height: 1;
  letter-spacing: -0.06em;
  -webkit-transform: translateY(-200px);
  transform: translateY(-200px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 1000ms ease, visibility 1000ms ease,
    -webkit-transform 1000ms ease;
  transition: opacity 1000ms ease, visibility 1000ms ease,
    -webkit-transform 1000ms ease;
  transition: transform 1000ms ease, opacity 1000ms ease, visibility 1000ms ease;
  transition: transform 1000ms ease, opacity 1000ms ease, visibility 1000ms ease,
    -webkit-transform 1000ms ease;
  max-width: 516px;
}

.slider-seven__title span {
  font-weight: 500;
}

@media (min-width: 992px) {
  .slider-seven__title {
    font-size: 60px;
  }
}

@media (min-width: 1200px) {
  .slider-seven__title {
    font-size: 90px;
  }
}

.slider-seven__text {
  margin: 0;
  color: var(--thm-text);
  font-size: 18px;
  line-height: 2;
  margin-top: 40px;
  margin-bottom: 40px;
  -webkit-transform: translateY(-100px);
  transform: translateY(-100px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 1500ms ease, visibility 1500ms ease,
    -webkit-transform 1500ms ease;
  transition: opacity 1500ms ease, visibility 1500ms ease,
    -webkit-transform 1500ms ease;
  transition: transform 1500ms ease, opacity 1500ms ease, visibility 1500ms ease;
  transition: transform 1500ms ease, opacity 1500ms ease, visibility 1500ms ease,
    -webkit-transform 1500ms ease;
  max-width: 510px;
}

@media (min-width: 1200px) {
  .slider-seven__text {
    margin-top: 25px;
  }
}

.slider-seven__btn {
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 2000ms ease, visibility 2000ms ease,
    background 500ms ease, -webkit-transform 2000ms ease;
  transition: opacity 2000ms ease, visibility 2000ms ease, background 500ms ease,
    -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, visibility 2000ms ease,
    background 500ms ease;
  transition: transform 2000ms ease, opacity 2000ms ease, visibility 2000ms ease,
    background 500ms ease, -webkit-transform 2000ms ease;
}

.swiper-slide-active .slider-seven__title,
.swiper-slide-active .slider-seven__btn,
.swiper-slide-active .slider-seven__text {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

@-webkit-keyframes globeRotate {
  8% {
    -webkit-transform: perspective(200px) rotateY(0deg);
    transform: perspective(200px) rotateY(0deg);
  }

  100% {
    -webkit-transform: perspective(200px) rotateY(360deg);
    transform: perspective(200px) rotateY(360deg);
  }
}

@keyframes globeRotate {
  8% {
    -webkit-transform: perspective(200px) rotateY(0deg);
    transform: perspective(200px) rotateY(0deg);
  }

  100% {
    -webkit-transform: perspective(200px) rotateY(360deg);
    transform: perspective(200px) rotateY(360deg);
  }
}

@-webkit-keyframes shapeMover {

  0%,
  100% {
    -webkit-transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
    transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
  }

  50% {
    -webkit-transform: perspective(400px) rotate(-15deg) translateZ(20px) translateY(20px) translateX(20px);
    transform: perspective(400px) rotate(-15deg) translateZ(20px) translateY(20px) translateX(20px);
  }
}

@keyframes shapeMover {

  0%,
  100% {
    -webkit-transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
    transform: perspective(400px) translateY(0) rotate(0deg) translateZ(0px) translateX(0);
  }

  50% {
    -webkit-transform: perspective(400px) rotate(-15deg) translateZ(20px) translateY(20px) translateX(20px);
    transform: perspective(400px) rotate(-15deg) translateZ(20px) translateY(20px) translateX(20px);
  }
}

@-webkit-keyframes bubbleMover {
  0% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
  }

  30% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }

  50% {
    -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
    transform: translateY(50px) translateX(100px) rotate(45deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }

  80% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }

  100% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}

@keyframes bubbleMover {
  0% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
  }

  30% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }

  50% {
    -webkit-transform: translateY(50px) translateX(100px) rotate(45deg);
    transform: translateY(50px) translateX(100px) rotate(45deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }

  80% {
    -webkit-transform: translateY(30px) translateX(50px) rotate(15deg);
    transform: translateY(30px) translateX(50px) rotate(15deg);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }

  100% {
    -webkit-transform: translateY(0px) translateX(0) rotate(0);
    transform: translateY(0px) translateX(0) rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}

.slider-seven__shape-1 {
  background-repeat: no-repeat;
  width: 326px;
  height: 419px;
  position: absolute;
  top: 105px;
  left: 0;
}

.slider-seven__shape-2 {
  background-repeat: no-repeat;
  width: 36px;
  height: 36px;
  position: absolute;
  top: 170px;
  left: 307px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: shapeMover;
  animation-name: shapeMover;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.slider-seven__shape-3 {
  background-repeat: no-repeat;
  width: 19px;
  height: 16px;
  position: absolute;
  left: 723px;
  top: 208px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: bubbleMover;
  animation-name: bubbleMover;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.slider-seven__shape-4 {
  background-repeat: no-repeat;
  width: 35px;
  height: 32px;
  position: absolute;
  top: 626px;
  left: 174px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: bubbleMover;
  animation-name: bubbleMover;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.slider-seven__shape-5 {
  background-repeat: no-repeat;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 660px;
  left: 890px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: bubbleMover;
  animation-name: bubbleMover;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

/* mainmenu seven */
.mainmenu-seven {
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

@media (min-width: 1200px) {
  .mainmenu-seven {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 991;
    min-height: 110px;
  }
}

.mainmenu-seven .mainmenu-seven__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  float: none !important;
  width: 100% !important;
  padding-top: 40px !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

@media (min-width: 1200px) {
  .mainmenu-seven .mainmenu-seven__inner {
    padding-left: 50px;
    padding-right: 50px;
    padding-top: 0 !important;
    position: relative;
  }
}

.mainmenu-seven__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mainmenu-seven__left .mobile-nav-toggler {
  margin: 0;
  margin-left: 20px;
}

@media (min-width: 1200px) {
  .mainmenu-seven__left {
    position: absolute;
    top: 40px;
    left: 50px;
    z-index: 9;
  }
}

.mainmenu-seven__search {
  font-size: 22px;
  color: var(--thm-black);
  height: 42px;
  border-left: 1px solid rgba(var(--thm-black-rgb), 0.2);
  padding-left: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 30px;
}

.mainmenu-seven__search button {
  background-color: transparent;
}

.mainmenu-seven__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 1200px) {
  .mainmenu-seven__right {
    position: absolute;
    top: 40px;
    right: 50px;
    z-index: 9;
  }
}

.mainmenu-seven__right .mobile-nav-toggler {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0;
}

.mainmenu-seven__right .mobile-nav-toggler span {
  width: 28px;
  height: 1px;
  background-color: var(--thm-black);
  display: block;
}

.mainmenu-seven__right .mobile-nav-toggler span:nth-child(2) {
  width: 23px;
  margin-top: 7px;
  margin-bottom: 7px;
}

.mainmenu-seven__right .mobile-nav-toggler span:nth-child(3) {
  width: 18px;
}

.mainmenu-seven .main-menu {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 100%;
  padding-left: 200px;
  padding-right: 15px;
}

@media (min-width: 1550px) {
  .mainmenu-seven .main-menu {
    padding-left: 15px;
  }
}

.mainmenu-seven .auto-container {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.mainmenu-seven .main-menu .navigation li>a {
  font-size: 16px;
  font-weight: 400;
  color: var(--thm-black);
  text-transform: capitalize;
}

.mainmenu-seven.fixed-header {
  background-color: #fff;
  -webkit-box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.08);
  box-shadow: 0 10px 30px 0px rgba(0, 0, 0, 0.08);
}

/* blog eight */
.blog-eight {
  padding-top: 96px;
  padding-bottom: 70px;
}

@media (min-width: 1200px) {
  .blog-eight {
    padding-top: 116px;
    padding-bottom: 90px;
  }
}

.blog-eight__card {
  margin-bottom: 30px;
}

.blog-eight__image img {
  width: 100%;
}

.blog-eight__content {
  background-color: #fff;
  width: calc(100% - 40px);
  margin-left: auto;
  margin-right: auto;
  position: relative;
  margin-top: -20px;
  text-align: center;
  padding-top: 33px;
}

.blog-eight__date {
  position: absolute;
  bottom: 100%;
  right: 0;
  background-color: var(--thm-base);
  color: var(--thm-black);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.2em;
  line-height: 1;
  padding-left: 21px;
  padding-right: 21px;
  padding-top: 12.5px;
  padding-bottom: 12.5px;
}

.blog-eight__title {
  margin: 0;
  color: var(--thm-black);
  font-size: 24px;
  line-height: 1.25;
  font-weight: bold;
  margin-bottom: 24px;
}

.blog-eight__title a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-eight__title a:hover {
  color: var(--thm-base);
}

.blog-eight__text {
  margin: 0;
  color: var(--thm-text);
  font-size: 16px;
  line-height: 1.875;
  margin-bottom: 24px;
}

.blog-eight__meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-top: 1px solid #e2e5ed;
  padding-top: 10px;
}

.blog-eight__meta a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  color: var(--thm-text);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.blog-eight__meta a:hover {
  color: var(--thm-base);
}

.blog-eight__meta a:hover i {
  color: var(--thm-black);
}

.blog-eight__meta a i {
  color: var(--thm-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-right: 5px;
}

.blog-eight__meta a+a {
  margin-left: 20px;
}

/* video seven */
.video-seven {
  position: relative;
  text-align: center;
}

.video-seven__parallax {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.video-seven .video-seven__content {
  padding-top: 90px;
  padding-bottom: 100px;
  background-color: #182e65b3;
}

@media (min-width: 1200px) {
  .video-seven .video-seven__content {
    padding-top: 110px;
    padding-bottom: 120px;
  }
}

.video-seven .auto-container {
  position: relative;
}

.video-seven__title {
  margin: 0;
  color: #fff;
  font-size: 40px;
  line-height: 1.2;
  font-weight: bold;
  margin-bottom: 40px;
  max-width: 741px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@media (min-width: 992px) {
  .video-seven__title {
    font-size: 60px;
    margin-bottom: 50px;
  }
}

.video-seven__btn {
  width: 100px;
  height: 100px;
  background-color: var(--thm-base);
  color: var(--thm-black);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  position: relative;
  font-size: 24px;
}

.video-seven__btn:hover {
  background-color: #fff;
  color: var(--thm-base);
}

.video-seven__btn .ripple,
.video-seven__btn .ripple:before,
.video-seven__btn .ripple:after {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
  -webkit-animation: ripple 3s infinite;
  animation: ripple 3s infinite;
}

.video-seven__btn .ripple:before {
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
  content: "";
  position: absolute;
}

.video-seven__btn .ripple:after {
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  content: "";
  position: absolute;
}

/* testimonials eight */
.testimonials-eight {
  background-color: #eef0f6;
  background-repeat: repeat;
  background-position: center;
  padding-top: 96px;
  padding-bottom: 70px;
}

@media (min-width: 992px) {
  .testimonials-eight {
    padding-top: 116px;
    padding-bottom: 90px;
  }
}

.testimonials-eight__card {
  margin-bottom: 30px;
  background-color: #fff;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  padding: 40px;
}

@media (min-width: 992px) {
  .testimonials-eight__card {
    padding: 60px;
  }
}

.testimonials-eight__card:hover {
  -webkit-box-shadow: 0px 25px 40px 0px rgba(24, 46, 101, 0.1);
  box-shadow: 0px 25px 40px 0px rgba(24, 46, 101, 0.1);
}

.testimonials-eight__stars {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 30px;
}

.testimonials-eight__stars i {
  color: var(--thm-base);
  font-size: 16px;
  line-height: 1;
}

.testimonials-eight__stars i+i {
  margin-left: 10px;
}

.testimonials-eight__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.testimonials-eight__image {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 20px;
}

.testimonials-eight__image img {
  width: 63px;
  border-radius: 50%;
}

.testimonials-eight__text {
  margin: 0;
  color: var(--thm-text);
  font-size: 18px;
  line-height: 1.889;
  padding-bottom: 23px;
  border-bottom: 1px solid #e2e5ed;
  margin-bottom: 30px;
}

.testimonials-eight__name {
  font-size: 18px;
  font-weight: bold;
  color: var(--thm-black);
  line-height: 1;
  margin: 0;
  margin-bottom: 7px;
}

.testimonials-eight__designation {
  margin: 0;
  line-height: 1;
  color: var(--thm-text);
  font-size: 14px;
}

/* Project eight */
.project-eight {
  padding-top: 96px;
  padding-bottom: 100px;
}

@media (min-width: 1200px) {
  .project-eight {
    padding-top: 116px;
    padding-bottom: 120px;
  }
}

.project-eight .auto-container {
  max-width: 100%;
}

@media (min-width: 992px) {
  .project-eight .auto-container {
    padding-left: 30px;
    padding-right: 30px;
  }
}

@media (min-width: 1200px) {
  .project-eight .auto-container {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (min-width: 1400px) {
  .project-eight .auto-container {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.project-eight__item {
  position: relative;
}

.project-eight__item img {
  width: 100%;
}

.project-eight__content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
  padding: 20px;
}

.project-eight__content::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: -webkit-gradient(linear,
      left bottom,
      left top,
      from(var(--thm-black)),
      color-stop(70%, rgba(0, 0, 0, 0)));
  background-image: linear-gradient(to top,
      var(--thm-black) 0%,
      rgba(0, 0, 0, 0) 70%);
  -webkit-transition: -webkit-transform 500ms ease;
  transition: -webkit-transform 500ms ease;
  transition: transform 500ms ease;
  transition: transform 500ms ease, -webkit-transform 500ms ease;
  -webkit-transform: translateY(0%);
  transform: translateY(0%);
}

.project-eight__item:hover .project-eight__content::before {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}

.project-eight__title {
  margin: 0;
  background-color: transparent;
  padding: 30px 40px;
  font-size: 30px;
  line-height: 1.33;
  font-weight: bold;
  color: #fff;
  position: relative;
  width: 100%;
}

.project-eight__title::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  background-color: #fff;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 500ms ease, visibility 500ms ease,
    -webkit-transform 500ms ease;
  transition: opacity 500ms ease, visibility 500ms ease,
    -webkit-transform 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease, visibility 500ms ease;
  transition: transform 500ms ease, opacity 500ms ease, visibility 500ms ease,
    -webkit-transform 500ms ease;
}

.project-eight__title a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  position: relative;
  display: block;
  max-width: 163px;
}

.project-eight__title a:hover {
  color: var(--thm-base);
}

.project-eight__item:hover .project-eight__title {
  color: var(--thm-black);
}

.project-eight__item:hover .project-eight__title::before {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.project-eight__link {
  width: 54px;
  height: 54px;
  background-color: var(--thm-base);
  position: absolute;
  bottom: 135px;
  right: 50px;
  color: var(--thm-black);
  font-size: 22px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  z-index: 10;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: background 500ms ease, color 500ms ease,
    -webkit-transform 500ms ease;
  transition: background 500ms ease, color 500ms ease,
    -webkit-transform 500ms ease;
  transition: transform 500ms ease, background 500ms ease, color 500ms ease;
  transition: transform 500ms ease, background 500ms ease, color 500ms ease,
    -webkit-transform 500ms ease;
}

.project-eight__link:hover {
  background-color: var(--thm-black);
  color: #fff;
}

.project-eight__item:hover .project-eight__link {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* about ten */
.about-ten {
  padding-top: 100px;
  counter-reset: boxNumber;
}

@media (min-width: 992px) {
  .about-ten {
    padding-top: 0;
  }
}

.about-ten__image img {
  max-width: 100%;
  margin-bottom: 60px;
}

@media (min-width: 992px) {
  .about-ten__image img {
    margin-bottom: 0;
    max-width: none;
    float: right;
    position: relative;
    left: 30px;
  }
}

@media (min-width: 1200px) {
  .about-ten .my-auto {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .about-ten__content {
    padding-left: 100px;
    padding-top: 116px;
  }
}

.about-ten__text {
  color: var(--thm-base);
  font-size: 22px;
  font-weight: 500;
  line-height: 1.81;
  margin: 0;
  margin-top: -20px;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .about-ten__text {
    margin-top: -10px;
    margin-bottom: 37px;
  }
}

.about-ten__box+.about-ten__box {
  margin-top: 20px;
}

@media (min-width: 1200px) {
  .about-ten__box+.about-ten__box {
    margin-top: 53px;
  }
}

.about-ten__box {
  position: relative;
  counter-increment: boxNumber;
}

@media (min-width: 576px) {
  .about-ten__box {
    padding-left: 70px;
  }
}

.about-ten__box::before {
  content: counters(boxNumber, ".", decimal-leading-zero);
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background-color: var(--thm-base);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-family: "Shadows Into Light";
  color: var(--thm-black);
  font-size: 30px;
  margin-bottom: 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  padding-bottom: 5px;
}

@media (min-width: 576px) {
  .about-ten__box::before {
    position: absolute;
    top: 10px;
    left: 0;
  }
}

.about-ten__box:hover::before {
  background-color: var(--thm-black);
  color: #fff;
}

.about-ten__box__title {
  margin: 0;
  color: var(--thm-black);
  font-size: 20px;
  line-height: 1.7;
  font-weight: bold;
  margin-bottom: 8px;
}

.about-ten__box__text {
  margin: 0;
  font-size: 18px;
  line-height: 1.889;
}

/* service eight */
.service-eight {
  padding-top: 100px;
  padding-bottom: 70px;
  background-color: #eef0f6;
  background-repeat: repeat;
  background-position: center;
  counter-reset: serviceNumber;
}

@media (min-width: 1200px) {
  .service-eight {
    padding-top: 116px;
    padding-bottom: 90px;
  }
}

.service-eight__card {
  background-color: #fff;
  padding: 15px;
  margin-bottom: 30px;
  counter-increment: serviceNumber;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-eight__card__inner {
  border: 1px solid #eef0f6;
  padding: 35px;
  overflow: hidden;
  position: relative;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-eight__card__inner::before {
  line-height: 1;
  font-size: 60px;
  color: #eef0f6;
  font-family: "Shadows Into Light";
  content: counters(serviceNumber, ".", decimal-leading-zero);
  position: absolute;
  right: 35px;
  bottom: 35px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  opacity: 1;
}

.service-eight__card__inner::after {
  content: "";
  width: 68px;
  height: 68px;
  background-color: #eef0f6;
  position: absolute;
  bottom: 0;
  right: 0;
  -webkit-transform: translateY(50%) translateX(50%);
  transform: translateY(50%) translateX(50%);
  border-radius: 50%;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-eight__card:hover {
  background-color: var(--thm-base);
}

.service-eight__card:hover .service-eight__card__inner {
  border-color: var(--thm-black);
}

.service-eight__card:hover .service-eight__card__inner::after {
  background-color: var(--thm-black);
}

.service-eight__card:hover .service-eight__card__inner::before {
  opacity: 0.1;
  color: var(--thm-black);
}

.service-eight__title {
  font-size: 20px;
  line-height: 1.5;
  color: var(--thm-black);
  margin: 0;
  max-width: 101px;
  font-weight: bold;
}

.service-eight__title a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-eight__title a:hover {
  color: #fff;
}

.service-eight__icon {
  margin-top: 70px;
}

.service-eight__icon i {
  font-size: 60px;
  color: var(--thm-base);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-eight__card:hover .service-eight__icon i {
  color: var(--thm-black);
}

/* about eleven */
.about-eleven {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media (min-width: 1200px) {
  .about-eleven {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.about-eleven__images {
  position: relative;
  margin-bottom: 60px;
}

.about-eleven__images::before {
  content: "";
  width: 20px;
  height: 326px;
  background-color: var(--thm-base);
  color: var(--thm-base);
  position: absolute;
  right: 100%;
  top: 40px;
}

@media (min-width: 992px) {
  .about-eleven__images {
    margin-bottom: 0;
  }
}

.about-eleven__images img {
  max-width: 100%;
}

.about-eleven__images img:nth-child(2) {
  position: absolute;
  bottom: 0;
  left: 0;
}

@media (min-width: 1200px) {
  .about-eleven__images img:nth-child(2) {
    left: -120px;
  }
}

.about-eleven .sec-title-eight {
  margin-bottom: 40px;
}

.about-eleven__summery {
  margin: 0;
  font-size: 18px;
  line-height: 1.889;
  color: var(--thm-text);
}

.about-eleven__box {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  margin-top: 40px;
}

@media (min-width: 576px) {
  .about-eleven__box {
    -ms-grid-columns: (1fr) [2];
    grid-template-columns: repeat(2, 1fr);
  }
}

.about-eleven__box__item+.about-eleven__box__item {
  margin-top: 20px;
}

@media (min-width: 576px) {
  .about-eleven__box__item+.about-eleven__box__item {
    margin-top: 0;
  }
}

.about-eleven__box__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.about-eleven__box__item i {
  font-size: 62px;
  color: var(--thm-base);
}

.about-eleven__box__title {
  margin: 0;
  font-size: 20px;
  margin-left: 20px;
  line-height: 1.3;
  color: var(--thm-black);
  font-weight: bold;
  max-width: 118px;
}

.about-eleven__progress {
  margin-top: 40px;
}

.about-eleven__progress p {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
  color: var(--thm-black);
  margin-bottom: 2px;
}

.about-eleven__progress-line {
  width: 100%;
  height: 13px;
  background-color: #eef0f6;
  position: relative;
  border-radius: 7.5px;
}

.about-eleven__progress-line span {
  -webkit-transition: all 1000ms ease;
  transition: all 1000ms ease;
  width: 0;
  height: 100%;
  background-color: var(--thm-base);
  border-radius: 7.5px;
  position: absolute;
  top: 0;
  left: 0;
}

.about-eleven__progress-line b {
  position: absolute;
  bottom: 100%;
  right: 0;
  color: var(--thm-text);
  font-size: 14px;
  font-weight: 400;
}

.about-eleven__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 50px;
}

.about-eleven__info__image {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  margin-right: 20px;
}

.about-eleven__info__image img {
  width: 75px;
  border-radius: 50%;
}

.about-eleven__info__name {
  margin: 0;
  font-size: 20px;
  color: var(--thm-black);
  font-weight: bold;
  margin-bottom: 7px;
}

.about-eleven__info__text {
  margin: 0;
  color: var(--thm-text);
  font-size: 14px;
  line-height: 1;
}

/* call to action four */
.call-to-section-four {
  background-color: var(--thm-base);
}

.call-to-section-four .auto-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 40px;
}

@media (min-width: 992px) {
  .call-to-section-four .auto-container {
    padding-top: 50px;
    padding-bottom: 50px;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
}

.call-to-section-four__title {
  font-size: 30px;
  color: var(--thm-black);
  margin: 0;
  font-weight: 500;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .call-to-section-four__title {
    margin-bottom: 0;
  }
}

.call-to-section-four__btn:hover {
  background-color: #fff;
  color: var(--thm-black);
}

/* slider eight */
.slider-eight .swiper-slide {
  position: relative;
  background-color: var(--thm-black);
  padding-top: 150px;
  padding-bottom: 150px;
}

@media (min-width: 1200px) {
  .slider-eight .swiper-slide {
    padding-top: 285px;
    padding-bottom: 180px;
  }
}

.slider-eight .swiper-slide .image-layer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  opacity: 0.7;
  -webkit-transform: scale(1);
  transform: scale(1);
  visibility: hidden;
  -webkit-transition: visibility 0ms ease, -webkit-transform 7000ms ease;
  transition: visibility 0ms ease, -webkit-transform 7000ms ease;
  transition: visibility 0ms ease, transform 7000ms ease;
  transition: visibility 0ms ease, transform 7000ms ease,
    -webkit-transform 7000ms ease;
}

.slider-eight .swiper-slide.swiper-slide-active .image-layer {
  visibility: visible;
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}

.slider-eight .swiper-slide .auto-container {
  position: relative;
}

.slider-eight__text {
  margin: 0;
  color: #fff;
  font-family: "Shadows Into Light";
  font-size: 20px;
  line-height: 1.2;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  opacity: 0;
  -webkit-transform: translateY(90px);
  transform: translateY(90px);
  -webkit-transition: opacity 1500ms ease, -webkit-transform 1500ms ease;
  transition: opacity 1500ms ease, -webkit-transform 1500ms ease;
  transition: transform 1500ms ease, opacity 1500ms ease;
  transition: transform 1500ms ease, opacity 1500ms ease,
    -webkit-transform 1500ms ease;
}

@media (min-width: 992px) {
  .slider-eight__text {
    font-size: 30px;
  }
}

.slider-eight__text span {
  text-decoration: underline;
}

.slider-eight__title {
  margin: 0;
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  line-height: 1;
  margin-top: 10px;
  margin-bottom: 20px;
  -webkit-transform-origin: bottom center;
  transform-origin: bottom center;
  -webkit-transform: scaleY(0) translateY(-50px);
  transform: scaleY(0) translateY(-50px);
  -webkit-transition: opacity 1500ms ease, -webkit-transform 1500ms ease;
  transition: opacity 1500ms ease, -webkit-transform 1500ms ease;
  transition: transform 1500ms ease, opacity 1500ms ease;
  transition: transform 1500ms ease, opacity 1500ms ease,
    -webkit-transform 1500ms ease;
  opacity: 0;
}

@media (min-width: 768px) {
  .slider-eight__title {
    max-width: 550px;
  }
}

@media (min-width: 992px) {
  .slider-eight__title {
    font-size: 60px;
  }
}

@media (min-width: 1200px) {
  .slider-eight__title {
    font-size: 80px;
    max-width: 647px;
    margin-bottom: 50px;
    margin-top: 25px;
  }
}

.slider-eight__btns {
  overflow: hidden;
}

@media (min-width: 475px) {
  .slider-eight__btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.slider-eight__btn {
  -webkit-transform: scaleY(0) translateY(-200px);
  transform: scaleY(0) translateY(-200px);
  -webkit-transform-origin: top center;
  transform-origin: top center;
  opacity: 0;
  -webkit-transition: background 500ms ease, color 500ms ease,
    opacity 2000ms ease, -webkit-transform 2000ms ease;
  transition: background 500ms ease, color 500ms ease, opacity 2000ms ease,
    -webkit-transform 2000ms ease;
  transition: transform 2000ms ease, background 500ms ease, color 500ms ease,
    opacity 2000ms ease;
  transition: transform 2000ms ease, background 500ms ease, color 500ms ease,
    opacity 2000ms ease, -webkit-transform 2000ms ease;
}

.slider-eight__btn--two {
  background-color: var(--thm-base);
  color: var(--thm-black);
  margin-top: 10px;
}

@media (min-width: 475px) {
  .slider-eight__btn--two {
    margin-top: 0;
    margin-left: 10px;
  }
}

.slider-eight__btn--two:hover {
  background-color: #fff;
  color: var(--thm-black);
}

.swiper-slide-active .slider-eight__title,
.swiper-slide-active .slider-eight__btn {
  -webkit-transform: scaleY(1) translateY(0);
  transform: scaleY(1) translateY(0);
  opacity: 1;
}

.swiper-slide-active .slider-eight__text {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

/* header eight */
.header-eight__topbar {
  display: none;
  background-color: var(--thm-black);
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (min-width: 992px) {
  .header-eight__topbar {
    display: block;
  }
}

.header-eight__topbar .auto-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 100%;
  padding-left: 30px;
  padding-right: 30px;
}

@media (min-width: 1200px) {
  .header-eight__topbar .auto-container {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.header-eight__topbar__right {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-eight__topbar__right p {
  font-size: 14px;
  color: #acb5cb;
  margin: 0;
  margin-right: 30px;
}

.header-eight__topbar__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-eight__topbar__info li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #acb5cb;
  font-size: 14px;
  font-weight: 400;
}

.header-eight__topbar__info li i {
  color: var(--thm-base);
  font-size: 16px;
  margin-right: 11px;
}

.header-eight__topbar__info li+li {
  margin-left: 20px;
}

.header-eight__topbar__info li a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.header-eight__topbar__info li a:hover {
  color: #fff;
}

.header-eight__topbar__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.header-eight__topbar__social a {
  color: #acb5cb;
  font-size: 14px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.header-eight__topbar__social a:hover {
  color: var(--thm-base);
}

.header-eight__topbar__social a+a {
  margin-left: 20px;
}

.mainmenu-eight {
  position: relative;
  top: auto;
  left: auto;
  width: 100%;
  z-index: 91;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  background-color: var(--thm-black);
  padding-top: 20px;
  padding-bottom: 20px;
}

@media (min-width: 1200px) {
  .mainmenu-eight {
    position: fixed;
    top: 44px;
    left: 0;
    background-color: rgba(255, 255, 255, 0.1);
    padding-top: 0;
    padding-bottom: 0;
  }
}

.mainmenu-eight.fixed-header {
  background-color: var(--thm-black);
  top: 0;
}

.mainmenu-eight .nav-outer {
  position: relative;
  float: none !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100% !important;
}

.mainmenu-eight .auto-container {
  width: 100%;
  max-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@media (min-width: 1200px) {
  .mainmenu-eight .auto-container {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.mainmenu-eight .nav-outer .mobile-nav-toggler {
  float: none;
  margin: 0;
  margin-left: 20px;
}

.mainmenu-eight__right {
  margin-left: auto;
}

@media (min-width: 1350px) {
  .mainmenu-eight__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 0;
  }
}

.mainmenu-eight__call {
  display: none;
  text-align: left;
  color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  padding-left: 40px;
  margin-left: 40px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mainmenu-eight__call:hover {
  color: #fff;
}

@media (min-width: 1350px) {
  .mainmenu-eight__call {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.mainmenu-eight__call__icon {
  margin-right: 20px;
}

.mainmenu-eight__call__icon i {
  font-size: 34px;
  color: #fff;
  position: relative;
  top: 4px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.mainmenu-eight__call:hover .mainmenu-eight__call__icon i {
  color: #fff;
}

.mainmenu-eight__call__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.mainmenu-eight__call__text strong {
  font-weight: bold;
  font-size: 16px;
}

.mainmenu-eight__left {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.mainmenu-eight__search button {
  color: #fff;
  font-size: 22px;
  background-color: transparent;
}

.mainmenu-eight .main-menu {
  margin-left: auto;
  margin-right: auto;
}

.mainmenu-eight .main-menu .navigation li>a {
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}

.mainmenu-eight .main-menu .navigation>li>a {
  color: #fff;
}

.mainmenu-eight .main-menu .navigation>li::before {
  content: "";
  width: 15px;
  height: 8px;
  background-repeat: no-repeat;
  background-position: top center;
  position: absolute;
  bottom: 0;
  left: calc(50% - 7.5px);
  opacity: 0;
  -webkit-transition: opacity 500ms ease;
  transition: opacity 500ms ease;
}

.mainmenu-eight .main-menu .navigation>li>a::before {
  width: calc(50.5% - 7.5px);
  height: 2px;
  border: none;
  background-color: var(--thm-base);
  -webkit-transform: scale(1);
  transform: scale(1);
  bottom: -30px;
  opacity: 0;
  -webkit-transition: opacity 500ms ease;
  transition: opacity 500ms ease;
}

.mainmenu-eight .main-menu .navigation>li>a::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 7px;
  height: 2px;
  background-color: var(--thm-base);
  width: calc(50% - 7.5px);
  bottom: -30px;
  opacity: 0;
  -webkit-transition: opacity 500ms ease;
  transition: opacity 500ms ease;
}

.mainmenu-eight .main-menu .navigation>li.current::before,
.mainmenu-eight .main-menu .navigation>li:hover::before {
  opacity: 1;
}

.mainmenu-eight .main-menu .navigation>li.current>a,
.mainmenu-eight .main-menu .navigation>li:hover>a {
  color: var(--thm-base);
}

.mainmenu-eight .main-menu .navigation>li.current>a::before,
.mainmenu-eight .main-menu .navigation>li.current>a::after,
.mainmenu-eight .main-menu .navigation>li:hover>a::before,
.mainmenu-eight .main-menu .navigation>li:hover>a::after {
  opacity: 1;
}

/* Service nine */
.service-nine {
  padding-top: 100px;
  padding-bottom: 70px;
}

@media (min-width: 992px) {
  .service-nine {
    padding-top: 120px;
    padding-bottom: 90px;
  }
}

.service-nine__card {
  border-radius: 7px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  margin-bottom: 30px;
}

.service-nine__card:hover {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
}

.service-nine__card__inner {
  overflow: hidden;
  border-radius: 7px;
}

.service-nine__image {
  background-color: var(--thm-black);
}

.service-nine__image img {
  mix-blend-mode: luminosity;
}

.service-nine__content {
  padding-left: 50px;
  padding-right: 50px;
  border: 1px solid #e9ebee;
  border-top: 0;
  padding-top: 35px;
  padding-bottom: 50px;
}

.service-nine__icon {
  font-size: 64px;
  color: var(--thm-base);
}

.service-nine__title {
  margin: 0;
  font-size: 34px;
  color: var(--thm-black);
  line-height: 1.05;
  text-transform: uppercase;
  margin-top: 20px;
  margin-bottom: 10px;
}

.service-nine__title a {
  color: inherit;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-nine__title a:hover {
  color: var(--thm-base);
}

.service-nine__text {
  margin: 0;
  font-size: 16px;
  line-height: 2.125;
  color: var(--thm-text);
  margin-bottom: 20px;
}

.service-nine__link {
  width: 50px;
  height: 50px;
  background-color: #222429;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  border-radius: 50%;
  opacity: 0.2;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.service-nine__link:hover {
  opacity: 1;
  background-color: var(--thm-base);
  color: var(--thm-black);
}

/* team four */
.team-four {
  padding-top: 100px;
  padding-bottom: 70px;
}

@media (min-width: 992px) {
  .team-four {
    padding-top: 120px;
    padding-bottom: 90px;
  }
}

.team-four__card {
  margin-bottom: 30px;
  border-radius: 8px;
  border: 1px solid #e9ebee;
  padding-top: 40px;
  padding-bottom: 30px;
  text-align: center;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  padding-left: 30px;
  padding-right: 30px;
}

.team-four__card:hover {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
}

.team-four__image {
  width: 192px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  margin-bottom: 23px;
  background-color: var(--thm-black);
}

.team-four__image img {
  width: 100%;
  mix-blend-mode: luminosity;
}

.team-four__name {
  margin: 0;
  font-size: 24px;
  color: var(--thm-black);
  text-transform: uppercase;
  line-height: 1;
  letter-spacing: 0.1em;
  margin-bottom: 9px;
}

.team-four__designation {
  color: #686a6f;
  font-size: 16px;
  text-transform: uppercase;
  font-family: var(--thm-font);
  letter-spacing: 0.1em;
  line-height: 1;
  margin: 0;
  margin-bottom: 15px;
}

.team-four__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #f4f5f8;
  border-radius: 8px;
  padding: 11.5px 0;
}

.team-four__social a {
  color: #686a6f;
  font-size: 14px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.team-four__social a:hover {
  color: var(--thm-base);
}

.team-four__social a+a {
  margin-left: 20px;
}

/* Partner one */
.partner-one {
  padding-bottom: 70px;
  padding-top: 100px;
}

@media (min-width: 992px) {
  .partner-one {
    padding-top: 120px;
    padding-bottom: 90px;
  }
}

.partner-one__card {
  padding: 20px;
  margin-bottom: 30px;
  border: 1px solid #e9ebee;
  border-radius: 8px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  background-color: #fff;
  text-align: center;
}

.partner-one__card:hover {
  -webkit-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
  box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.04);
}

.partner-one__image {
  overflow: hidden;
  border-radius: 8px;
  background-color: #f4f5f8;
}

.partner-one__image img {
  max-width: 100%;
}

.partner-one__content {
  padding-bottom: 12px;
}

.partner-one__title {
  font-size: 34px;
  margin: 0;
  text-transform: uppercase;
  line-height: 36px;
  margin-top: 35px;
  margin-bottom: 10px;
}

.partner-one__text {
  margin: 0;
  color: var(--thm-text);
  font-size: 16px;
  line-height: 34px;
  max-width: 270px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.partner-one__link {
  text-transform: uppercase;
  font-family: var(--thm-font);
  font-size: 16px;
  color: var(--thm-black);
  letter-spacing: 0.1em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  background: -webkit-gradient(linear,
      left top,
      right top,
      from(var(--thm-base)),
      to(var(--thm-base))) 0px 77%/0px 2px no-repeat;
  background: linear-gradient(to right,
      var(--thm-base) 0%,
      var(--thm-base) 100%) 0px 77%/0px 2px no-repeat;
  background-size: 100% 2px;
  padding-bottom: 5px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.partner-one__link:hover {
  color: var(--thm-base);
  background-size: 0 2px;
}

/* contact two */
.contact-two .form-box {
  text-align: left;
}

.contact-two .sec-title {
  margin-bottom: 34px;
}

.contact-two__text {
  max-width: 351px;
  margin: 0;
  font-size: 16px;
  line-height: 34px;
}

.contact-two__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 50px;
}

.contact-two__social a {
  color: #686a6f;
  font-size: 20px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.contact-two__social a:hover {
  color: var(--thm-base);
}

.contact-two__social a+a {
  margin-left: 24px;
}

/* contact info two */
.contact-info-two {
  padding-bottom: 80px;
}

@media (min-width: 1200px) {
  .contact-info-two {
    padding-bottom: 0;
  }
}

.contact-info-two__card {
  background-color: #fff;
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 37px 30px;
  text-align: center;
  border: 1px solid #f4f5f8;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .contact-info-two__card {
    position: relative;
    margin-bottom: -37px;
    z-index: 91;
  }
}

.contact-info-two__card i {
  font-size: 20px;
  color: var(--thm-base);
  margin-right: 20px;
}

.contact-info-two__card a {
  color: var(--thm-black);
  font-size: 20px;
}

/* under construction */
.under-construction {
  position: relative;
  background-color: #f4f5f8;
  min-height: 100vh;
  padding-top: 50px;
  padding-bottom: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.under-construction__bg {
  background-repeat: no-repeat;
  background-position: bottom center;
  background-color: #f4f5f8;
  background-blend-mode: luminosity;
  opacity: 0.1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.under-construction .auto-container {
  position: relative;
  z-index: 10;
  width: 100%;
}

.under-construction__title {
  margin: 0;
  text-transform: uppercase;
  font-size: 60px;
  line-height: 1;
  font-weight: 300;
  color: var(--thm-base);
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .under-construction__title {
    margin-bottom: 0;
    font-size: 100px;
  }
}

.under-construction__tagline {
  margin: 0;
  line-height: 1.2em;
  color: var(--thm-black);
  letter-spacing: 0.4em;
  font-size: 26px;
  text-transform: uppercase;
  font-weight: 300;
  margin-top: 30px;
  margin-bottom: 20px;
}

@media (min-width: 992px) {
  .under-construction__tagline {
    margin-top: 100px;
    margin-bottom: 7px;
  }
}

.under-construction__countdown {
  margin: 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}

@media (min-width: 576px) {
  .under-construction__countdown {
    -ms-grid-columns: (1fr) [3];
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 992px) {
  .under-construction__countdown {
    margin-bottom: 86px;
    margin-top: 54px;
  }
}

.under-construction__countdown li {
  color: var(--thm-black);
  font-size: 50px;
  line-height: 1;
  text-transform: uppercase;
  font-family: var(--thm-font);
}

@media (min-width: 992px) {
  .under-construction__countdown li {
    font-size: 70px;
  }
}

.under-construction__countdown li+li {
  border-top: 2px solid #fff;
  margin-top: 30px;
  padding-top: 30px;
}

@media (min-width: 576px) {
  .under-construction__countdown li+li {
    border-top: 0;
    border-left: 2px solid #fff;
    margin-top: 0;
    padding-top: 0;
  }
}

.under-construction__countdown li i {
  font-style: normal;
  font-size: 20px;
  color: var(--thm-text);
  letter-spacing: 0.3em;
  display: block;
  line-height: 1;
  margin-top: 7px;
}

.under-construction__text {
  margin: 0;
  color: var(--thm-text);
  font-size: 20px;
  font-weight: 300;
  margin-bottom: 10px;
}

@media (min-width: 992px) {
  .under-construction__text {
    margin-bottom: 0;
  }
}

.under-construction__social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 25px;
}

.under-construction__social a {
  font-size: 16px;
  text-align: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 44px;
  height: 44px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  border-radius: 50%;
  color: var(--thm-text);
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.under-construction__social a:hover {
  color: #fff;
  background-color: var(--thm-base);
}

.under-construction__social a+a {
  margin-left: 10px;
}

/* process one */
.process-one {
  counter-reset: processCount;
  padding-top: 90px;
}

@media (min-width: 992px) {
  .process-one {
    padding-top: 120px;
  }
}

.process-one .row {
  margin-bottom: 90px;
}

@media (min-width: 992px) {
  .process-one .row:nth-child(even) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .process-one .row:nth-child(even) .process-one__image__column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  .process-one .row:nth-child(even) .process-one__image::before,
  .process-one .row:nth-child(even) .process-one__image::after {
    left: auto;
    right: 0;
    -webkit-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
  }
}

@media (min-width: 992px) {
  .process-one .row {
    margin-bottom: 120px;
  }
}

.process-one__image {
  counter-increment: processCount;
  position: relative;
  display: inline-block;
  margin-bottom: 50px;
  border-radius: 8px;
  background-color: var(--thm-black);
}

@media (min-width: 992px) {
  .process-one__image {
    margin-bottom: 0;
  }
}

.process-one__image::before {
  content: "";
  border-radius: 50%;
  background-color: #f4f5f8;
  position: absolute;
  width: 200px;
  height: 200px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (min-width: 992px) {
  .process-one__image::before {
    width: 387px;
    height: 387px;
    top: 50%;
    left: 0%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.process-one__image::after {
  content: counters(processCount, ".", decimal-leading-zero);
  background-color: var(--thm-base);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  position: absolute;
  border-radius: 50%;
  color: var(--thm-black);
  font-family: var(--thm-font);
  font-size: 30px;
  padding-top: 5px;
  width: 60px;
  height: 60px;
  top: 20%;
  left: 20%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (min-width: 992px) {
  .process-one__image::after {
    width: 128px;
    height: 128px;
    top: 50%;
    left: 0;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 50px;
    padding-top: 12px;
  }
}

.process-one__image img {
  max-width: 100%;
  border-radius: 8px;
  position: relative;
  mix-blend-mode: luminosity;
}

.process-one .sec-title {
  margin-bottom: 0;
}

.process-one__summery {
  margin: 0;
  font-size: 16px;
  color: var(--thm-text);
  line-height: 2.125em;
  font-weight: 400;
  margin-top: 34px;
  margin-bottom: 36px;
}

.process-one__list li {
  position: relative;
  padding-left: 30px;
  font-size: 16px;
  color: var(--thm-text);
  font-weight: 400;
}

.process-one__list li>i {
  position: absolute;
  top: 8px;
  left: 0;
  color: var(--thm-base);
  font-size: 20px;
}

/* pricing three */
.pricing-three {
  padding-top: 100px;
  padding-bottom: 70px;
}

.pricing-three__card {
  margin-bottom: 30px;
  border-radius: 8px;
  border: 1px solid #e9ebee;
  padding: 50px 49px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  padding-bottom: 40px;
}

.pricing-three__card:hover {
  background-color: var(--thm-black);
  border-color: var(--thm-black);
}

.pricing-three__name {
  text-transform: uppercase;
  color: var(--thm-base);
  font-size: 20px;
  letter-spacing: 0.1em;
  margin: 0;
  line-height: 1;
  margin-bottom: 10px;
}

.pricing-three__icon {
  font-size: 64px;
  color: var(--thm-base);
  margin-bottom: 28px;
}

.pricing-three__amount {
  display: inline-block;
  position: relative;
  font-family: var(--thm-font);
  font-size: 60px;
  text-transform: uppercase;
  line-height: 1;
  color: var(--thm-black);
  margin: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.pricing-three__card:hover .pricing-three__amount {
  color: #fff;
}

.pricing-three__currency {
  position: absolute;
  top: 0;
  right: 100%;
  -webkit-transform: translateY(-20%);
  transform: translateY(-20%);
  font-style: normal;
  font-size: 30px;
}

.pricing-three__list {
  margin-bottom: 20px;
}

.pricing-three__list li {
  color: var(--thm-text);
  font-size: 16px;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
}

.pricing-three__card:hover .pricing-three__list li {
  color: #999b9f;
}

.pricing-three__tagline {
  border-top: 1px solid #e9ebee;
  font-size: 16px;
  line-height: 2.125;
  color: var(--thm-text);
  margin: 0;
  -webkit-transition: all 500ms ease;
  transition: all 500ms ease;
  padding-top: 20px;
  padding-bottom: 22px;
}

.pricing-three__card:hover .pricing-three__tagline {
  color: #999b9f;
  border-color: rgba(255, 255, 255, 0.1);
}

.pricing-three .btn-style-two .btn-title {
  font-size: 16px;
  padding: 13px 39px 10px;
}

.pricing-three__card:hover .btn-style-two {
  color: var(--thm-black);
}

.pricing-three__card:hover .btn-style-two .btn-curve {
  background-color: var(--thm-base);
}

.pricing-three__card:hover .btn-style-two .btn-curve {
  opacity: 1;
  right: 0;
  width: 100%;
  -webkit-transform: skewX(0deg);
  transform: skewX(0deg);
}

.pricing-three .switch-toggler-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 30px;
}

.pricing-three .switch-toggler-list li {
  -webkit-box-ordinal-group: 1;
  -ms-flex-order: 0;
  order: 0;
}

.pricing-three .switch-toggler-list li:nth-child(2) {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.pricing-three .switch-toggler-list li:last-child {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
  margin-left: 10px;
  margin-right: 10px;
}

.pricing-three .switch-toggler-list li a {
  color: var(--thm-text);
  font-size: 18px;
}

.pricing-three .switch-toggler-list li.active a {
  color: var(--thm-black);
}

.pricing-three .switch-toggler-list li .switch {
  width: 79px;
  height: 36px;
  background-color: var(--thm-base);
  border-radius: 18px;
  position: relative;
  display: block;
  cursor: pointer;
}

.pricing-three .switch-toggler-list li .switch span {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background-color: #fff;
  display: block;
  position: absolute;
  left: 5px;
  top: 5px;
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: background 500ms ease, -webkit-transform 500ms ease;
  transition: background 500ms ease, -webkit-transform 500ms ease;
  transition: transform 500ms ease, background 500ms ease;
  transition: transform 500ms ease, background 500ms ease,
    -webkit-transform 500ms ease;
}

.pricing-three .switch-toggler-list li .switch.off span {
  background-color: var(--thm-black);
  -webkit-transform: translateX(43px);
  transform: translateX(43px);
}

.pricing-three .animated {
  -webkit-animation-duration: 1500ms;
  animation-duration: 1500ms;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.icon-service {
  filter: brightness(0) saturate(100%) invert(77%) sepia(30%) saturate(577%) hue-rotate(358deg) brightness(93%) contrast(90%);
  transition: filter 0.3s ease;
}

.service-block-two:hover .icon {
  filter: brightness(0) saturate(100%) invert(0%);
}


/* LanguagePicker */

.language-picker {
  position: relative;
  display: inline-block;
}

.language-picker__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  background-color: #222429;
  color: #f1f1f1;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9rem;
  text-align: center;
  text-decoration: none;
}

.language-picker__button:hover .fa {
  color: #ffaa17;
}

.language-picker__button .fa {
  color: #959ca6;
}

.language-picker__flag {
  font-size: 0.7rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.language-picker__dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #222429;
  color: #f1f1f1;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-top: 0.5rem;
  z-index: 10;
  transition: transform 0.3s ease;
}

.language-picker__item {
  padding: 0.4rem 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: background-color 0.3s ease;
}

.language-picker__item:hover {
  background-color: #444;
}

.language-picker__item.selected {
  background-color: #555;
  color: #fff;
}

.topbar-four .right-content {
  display: flex;
  align-items: center;
}

.topbar-four .phone {
  margin-right: 20px;
}

.topbar-four .social-links {
  display: flex;
  align-items: center;
  gap: 15px;
}

.topbar-four .social-links li,
.language-picker-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.language-picker-wrapper {
  margin-left: 25px;
}

.sponsors.sec-title {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}